import { useState } from 'react'
import { Box } from '@material-ui/core'
import { ArrowBack, ArrowForward, ExpandMore, FilterList } from '@material-ui/icons'
import styled from 'styled-components'
import _ from 'lodash'

import { doseTypes } from 'src/entry'
import Check from 'src/assets/images/checkmark.png'

const FilterBar = ({ pushed, setPushed, check, set_check, activity_date, set_activity_date, activity_date1, set_activity_date1 }) => {
    const [opens, setOpens] = useState([false, false])

    return (
        <>
            {pushed ?
                <StyledContainer>
                    <Box padding='10px' fontWeight='700' fontSize='20px' display='flex' alignItems='center' color='black' justifyContent='space-between'>
                        <Box display='flex'>
                            <FilterList />
                            <Box ml={1}>Filter</Box>
                        </Box>
                        <OmitButton onClick={() => setPushed(false)}>
                            <ArrowBack fill='black' />
                        </OmitButton>
                    </Box>
                    <FilterFactor active={opens[0] ? 1 : 0}>
                        <Box padding='10px' borderTop='1px solid lightgrey' borderBottom='1px solid lightgrey' >
                            <Box display='flex' justifyContent='space-between' alignItems='center' onClick={() => {
                                let tmp = [...opens]
                                tmp[0] = !tmp[0]
                                setOpens(tmp)
                            }}>
                                <Box fontSize='18px' color='black' fontWeight='500'>Pellet Type</Box>
                                <ExpandMoreIcon />
                            </Box>
                        </Box>
                        {opens[0] &&
                            <Box fontSize='16px' color='#555' overflow='auto' maxHeight='200px'>
                                {_.map(doseTypes[0].sizes, (each, i) =>
                                    <FilterItem key={i} pt={1} pb={1} onClick={() => {
                                        let temp = [...check]
                                        temp[i] = !temp[i]
                                        set_check(temp)
                                    }}>E{each}{check[i] && <img src={Check} width='20px' height='20px' alt='' />}</FilterItem>

                                )}
                                {_.map(doseTypes[1].sizes, (each, i) =>
                                    <FilterItem key={i} pt={1} pb={1} onClick={() => {
                                        let temp = [...check]
                                        temp[4 + i] = !temp[4 + i]
                                        set_check(temp)
                                    }}>T{each}{check[4 + i] && <img src={Check} width='20px' height='20px' alt='' />}</FilterItem>
                                )}
                            </Box>
                        }
                    </FilterFactor>
                    <FilterFactor active={opens[1] ? 1 : 0}>
                        <Box padding='10px' borderTop='1px solid lightgrey' borderBottom='1px solid lightgrey' >
                            <Box display='flex' justifyContent='space-between' alignItems='center' onClick={() => {
                                let tmp = [...opens]
                                tmp[1] = !tmp[1]
                                setOpens(tmp)
                            }}>
                                <Box fontSize='18px' color='black' fontWeight='500'>Activity Date</Box>
                                <ExpandMoreIcon />
                            </Box>
                        </Box>
                        {opens[1] &&
                            <Box fontSize='16px' color='#555' overflow='auto' maxHeight='200px'>
                                <Box display="flex" alignItems="center" marginLeft="10px">Date:<FilterItem pt={1} pb={1}><Box component='input' {...{ type: 'date' }} style={{ border: 'solid 1px' }} width="150px" value={activity_date.date} onChange={(e) => {
                                    let temp = { ...activity_date }
                                    temp.date = e.target.value
                                    set_activity_date(temp)
                                }}
                                max="9999-12-31"
                                /></FilterItem></Box>
                                {/* <Box display="flex" alignItems="center" marginLeft="32px">To:<Box><FilterItem pt={1} pb={1}><Box component='input' {...{ type: 'date' }} style={{border:'solid 1px'}} width="150px"  value={activity_date1.date} onChange={(e)=>{
                                let temp = {...activity_date1}
                                temp.date = e.target.value
                                set_activity_date1(temp)
                            }}/></FilterItem></Box>
                            </Box> */}
                            </Box>
                        }
                    </FilterFactor>
                </StyledContainer>
                :
                <OmittedContainer padding='14px'>
                    <Box onClick={() => setPushed(true)}>
                        <ArrowForward />
                    </Box>
                </OmittedContainer>
            }
        </>
    )
}

const OmittedContainer = styled(Box)`
    min-height: 300px;
    width: 50px;
    box-shadow: 0px -4px 4px 1px lightgrey;
    display: none;
    @media (min-width: 700px) {
        display: initial;
    }
    >div {
        cursor: pointer;
    }
`

const OmitButton = styled(Box)`
    cursor: pointer;
`

const ExpandMoreIcon = styled(ExpandMore)``

const FilterItem = styled(Box)`
    padding-left: 15px;
    transition: .5s;
    background: white;
    display: flex;
    justify-content: space-between;
    &:hover {
        background: #eee;
    }
`

const FilterFactor = styled(Box)`
    cursor: pointer;
    ${ExpandMoreIcon} {
        transition: .3s;
        transform: rotate(${({ active }) => active ? '-180deg' : '0deg'});
    }
`

const StyledContainer = styled(Box)`
    min-height: 300px;
    width: 250px;
    box-shadow: 0px -4px 4px 1px lightgrey;
    display: none;
    @media (min-width: 700px) {
        display: initial;
    }
    overflow: hidden;
    input {
        border: 1px solid lightgrey;
        border-radius: 5px;
        outline: none;
    }
`

export default FilterBar