import React from 'react'
import { Box, Button, Modal, Fade, Backdrop } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import styled from 'styled-components'

const ConfirmModal = ({ open, setOpen, confirmDelete }) => {
    return (
        <>
            {open &&
                <Modal open={open} onClose={() => setOpen(false)}
                    BackdropComponent={Overlay}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <StyledContainer bgcolor='white' fontFamily='Poppins' color='white'>
                            <ModalHeader component='header'>
                                <Box component='h4' width='100%' lineHeight='110%' margin='0px' fontWeight='600' fontSize='20px' color='rgb(4,17,29)'>
                                    Would you like to delete?
                                </Box>
                            </ModalHeader>
                            <ModalFoot>
                                <Action>
                                    <MyButton variant='contained' onClick={() => setOpen(false)}>Cancel</MyButton>
                                    <MyButton variant='contained' onClick={() => {
                                        confirmDelete()
                                        setOpen(false)
                                    }}
                                    >
                                        OK
                                    </MyButton>
                                </Action>
                            </ModalFoot>
                            <Box display='flex' position='absolute' right='24px' top='24px'>
                                <CloseButton component='button' onClick={() => setOpen(false)}>
                                    <CloseOutlined style={{ fontSize: 24 }} />
                                </CloseButton>
                            </Box>
                        </StyledContainer>
                    </Fade>
                </Modal >
            }
        </>
    )
}

const Action = styled(Box)`
    >button+button {
        margin-left: 30px;
    }
`

const MyButton = styled(Button)`
    text-transform: none !important;
`

const Overlay = styled(Backdrop)`
    background-color: rgba(0, 0, 0, 0.8) !important;
`

const CloseButton = styled(Box)`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 100%;
    font-family: inherit;
    border: 0px;
    padding: 0px;
    background: inherit;
    >svg { color: rgb(138, 147, 155); }
    &:hover {
        >svg { color: rgb(112, 122, 131); }
    }
`

const ModalHeader = styled(Box)`
    background: white;
    padding: 24px;
    border-bottom: 1px solid rgb(229, 232, 235);
    >h4 { word-break: break-word; }
`

const ModalFoot = styled(Box)`
    background: white;
    padding: 24px;
    text-align: right;
`

const StyledContainer = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: transparent;
    width: 500px;
    max-height: calc(100% - 50px);
    overflow-y: auto;
    max-width: calc(100% - 32px);
`

export default ConfirmModal