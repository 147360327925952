import { INV_CRUD, TOAST,INV_DEL_CRUD } from './types'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import * as PAGES from '../../constants/pages'

export function doGetWholeInv() {
    return (dispatch) => {
        axios.get('/api/inventoryController/doGetWholeInventories')
            .then((res) => {
                if(res.data.success)
                {
                    dispatch({
                        type: INV_CRUD.READ,
                        inventories: res.data.inventories
                    })
                }
                else alert('error occur')

            })
    }
}

export function doAddNewInv(formData, history) {

    return (dispatch) => {
        axios.post('/api/inventoryController/doAddNewInventory', formData)
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: INV_CRUD.CREATE,
                        inventories: res.data.inventories
                    })
                    let logData = JSON.parse(localStorage.getItem("logData") || "[]")
                    logData.push({
                        'userName': localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken')).userName,
                        'actionShort': 'Created',
                        'action': 'created an inventory',
                        'actionDate': new Date()
                    })
                    localStorage.setItem('logData', JSON.stringify(logData))
                    history.push(`/${PAGES.INVADJUST}`)
                }
                else alert('error occur')
            })
    }
}

export function doUpdateInv(formData) {
    return (dispatch) => {
        axios.post('/api/inventoryController/doUpdateInventory', formData)
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: INV_CRUD.UPDATE,
                        updatedData: formData,
                    })
                    dispatch({ type: TOAST, flag: true, toastContent: 'Update success' })

                    let logData = JSON.parse(localStorage.getItem("logData") || "[]")
                    logData.push({
                        'userName': localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken')).userName,
                        'actionShort': 'Updated',
                        'action': 'updated an inventory',
                        'actionDate': new Date()
                    })
                    localStorage.setItem('logData', JSON.stringify(logData))
                }
                else alert('error occur')
            })
    }
}


export function doDeleteInv(id) {
    let user = JSON.parse(localStorage.getItem('uid'))
    return (dispatch) => {
        axios.post('/api/inventoryController/doDeleteInventory', { id: id,deleted_by:user.id })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: INV_CRUD.DELETE,
                        id: id,
                    })
                    dispatch({ type: TOAST, flag: true, toastContent: 'Delete success' })
                    window.location.reload();
                    let logData = JSON.parse(localStorage.getItem("logData") || "[]")
                    logData.push({
                        'userName': localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken')).userName,
                        'actionShort': 'Deleted',
                        'action': 'deleted an inventory',
                        'actionDate': new Date()
                    })
                    localStorage.setItem('logData', JSON.stringify(logData))
                }
                else alert('error occur')
            })
    }
}

export function doGetWholeInvDel() {
    return (dispatch) => {
        axios.get('/api/inventoryController/doGetWholeInventoriesDeleted')
            .then((res) => {
                if(res.data.success)
                {
                    dispatch({
                        type: INV_DEL_CRUD.READ,
                        inventories_deleted: res.data.inventories_deleted
                    })
                }
                else alert('error occur')

            })
    }
}