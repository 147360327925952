import * as PAGES from 'src/constants/pages'

export const pelletMenu = [
    {
        title   : 'Pellet Receipt',//'New Pellet',
        path    : PAGES.RECEIPTADD,
    },
    {
        title   : 'Manage Pellets',
        path    : PAGES.RECEIPTEDIT,
    },
    {
        title   : 'Deleted Pellets Log',
        path    : PAGES.RECEIPTDELETE,
    }
]

export const patientMenu = [
    {
        title   : 'Patient Dispense',//'New Patient',
        path    : PAGES.DISPENSEADD,
    },
    {
        title   : 'Manage Patients',
        path    : PAGES.DISPENSEEDIT,
    },
    {
        title   : 'Deleted Patients Log',
        path    : PAGES.DISPENSEDELETE,
    }
]

export const reportMenu1 = [
    {
        title   : 'MTD Reports',
        path    : PAGES.MTDREPORT,
    },
    {
        title   : 'Daily Report',
        path    : PAGES.DDREPORT,
    },
    {
        title   : 'Lookup Detail',
        path    : PAGES.LOOKUPDETAIL,
    },
    {
        title   : 'Procedure Tracker',
        path    : PAGES.PROCTRACKER,
    },
    {
        title   : 'Lookup Detail 2',
        path    : PAGES.LOOKUPDETAIL2,
    },
    {
        title   : 'Reschedule Tracker',
        path    : PAGES.RESCHEDULE,
    },
    {
        title   : 'Lot # Review',
        path    : PAGES.LOTREVIEW,
    }
]

export const invMenu = [
    {
        title   : 'Manage Inventory Adj',
        path    : PAGES.INVADJUST,
    },
    {
        title   : 'Deleted IA Log',
        path    : PAGES.INVADJUSTDEL,
    }
]