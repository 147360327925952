import { Box, Fade } from '@material-ui/core'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { roleIcons } from './entry'

const RoleDrops = ({ open, setOpen, handleRegUser }) => {
    const userTypes = useSelector(state => state.AuthReducer.userTypes)

    return (
        <Fade in={open} timeout={300}>
            <StyledContainer>
                {_.map(userTypes, (each, i) =>
                    <Box key={i} display='flex' onClick={() => {
                        handleRegUser(each.user_type_name)
                        setOpen(false)
                    }}>
                        <Box mr={3}>
                            <img src={roleIcons[each.user_type_id]} alt='' width='20px' height='20px' />
                        </Box>
                        <Box>{each.user_type_name}</Box>
                    </Box>
                )}
            </StyledContainer>
        </Fade>
    );
}

const StyledContainer = styled(Box)`
    z-index: 2;
    position: absolute;
    left: 0px;
    bottom: 100%;
    width: 100%;
    min-width: 200px;
    background: white;
    color: black;
    border-radius: 5px;
    box-shadow: .5px .5px 3px 1px grey;
    >div {
        padding: 10px 15px;
        text-transform: none;
        cursor: pointer;
        transition: .3s;
        &:hover {
            background-color: #8aeb79;
            color: white;
        }
    }
`

export default RoleDrops;