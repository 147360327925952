import { useEffect } from 'react'
import _ from 'lodash'

export const useOutsideDetector = (refs, handleAction) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            let res
            _.forEach(refs, (each, i) => {
                if (i === 0) res = each.current && !each.current.contains(event.target)
                if (i === refs.length - 1 && res) handleAction(false)
                else res = res && (each.current && !each.current.contains(event.target))
            })
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [refs, handleAction])
}

export const useOutsideDetectorForLot = (ref1, ref2, handleAction) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if ((ref2.current && !ref2.current.contains(event.target)))
                handleAction(-1)
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [ref1, ref2 , handleAction])
}