import { Box } from '@material-ui/core'
import { Update } from '@material-ui/icons'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'

import LogCard from 'src/components/Cards/LogCard'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doGetWholeUsers } from 'src/redux/actions/AuthAction'


const Dashboard = () => {
    const user = localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken'))
    const userTypes = JSON.parse(localStorage.getItem('uid'))

    return (
        <StyledContainer textAlign='center'>
            {user !== null ?


                <Box margin={5}>
                    {/* ///////////////////////////////////////////////////////////
                                        ////////////////////// PRACTICE 4 fields/////////////////
                                        ///////////////////////////////////////////////////////////*/}
                    {user.userType == "Practice" ?

                        (

                            <div>
                                <div width='50%' style={{ justifyContent: 'center', textAlign: 'left', width: '100%' }}>

                                    <h4 >Welcome to BioSanaiD Management System (BiDMS)</h4>


                                    <p>We are happy to offer our practices this tool to better manage your inventory as required by the DEA, create reports, manage your patient’s schedule and allow BioSanaiD to extract billing information without the need to interfere with your office staff’s busy schedule.</p>

                                    <p>We ask that for the information to be as accurate and timely as possible that you keep in mind the following deadlines that MUST BE MET:</p>

                                    <p><span style={{ textDecoration: 'underline' }}>Bill Cycle 1:</span>   <span style={{ fontStyle: 'italic', color: 'red' }}>Day 1 to Day 15 of the month</span>…all inventory and procedures must be logged into this system
                                        <span style={{ fontWeight: 'bold' }}> no later than the 20th Day</span>.  (If the 20th lands on a weekend or Holiday, it is the business day before)</p>

                                    <p><span style={{ textDecoration: 'underline' }}>Bill Cycle 2:</span>   <span style={{ fontStyle: 'italic', color: 'red' }}>Day 16 to Last day of the month</span>…all inventory and procedures must be logged into this system
                                        <span style={{ fontWeight: 'bold' }}> no later than the 5th day of the following month.</span>.  (If the 5th lands on a weekend or Holiday, it is the business day before)</p>


                                    <p>If you follow these cycles and deadlines, it will ensure that the system will be as accurate not just to BioSanaiD but to your requirements to the DEA.  Any questions or help needed, please email
                                        <a href="#"> accounting@biosanaid.com </a></p>

                                </div>


                                <br />

                                <div style={{ textAlign: 'left' }}>

                                    <Box fontSize='16px' fontWeight='600'>  Biosana ID#:  <span style={{ color: '#666666', fontStyle: 'italic' }}> {userTypes.biosana_id} </span> </Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practice Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practice_name} </span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practioner Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practioner_name}</span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  DEA#:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.dea_no} </span> </Box>

                                </div>

                            </div>

                        )
                        : null
                    }

                    <Box display='flex' justifyContent='flex-end' mb={1}>Last sign in</Box>
                    <Box display='flex' justifyContent='flex-end' alignItems='center' color='#aaa' fontSize='13px'>
                        <Update />
                        <Box ml={1}>{user.lastSigninDate && user.lastSigninDate.slice(0, 10)}</Box>
                    </Box>
                    <LogCard owner={user.userName} />
                </Box>
                :
                <BackBox></BackBox>
            }
        </StyledContainer >
    )
}

const BackBox = styled(Box)`
    display: flex;
    width: 100%;
    height: 100vh;
    background-image: url(${require("../../assets/images/back/13.jpg").default});
    background-size: 100% 100%;
    background-repeat: no-repeat;
`

const StyledContainer = styled(Box)`

`

export default Dashboard