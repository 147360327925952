import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Card, CircularProgress, Fade } from '@material-ui/core'
import { ArrowRightAlt, Email, Lock, Error, Visibility, VisibilityOff } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'

import { doForgotPassword } from 'src/redux/actions/AuthAction'
import * as PAGES from 'src/constants/pages'

const Forgot = () => {
    const [email, setEmail] = useState(' ')
    const [password, setPassword] = useState(' ')
    const [focusIndex, setFocusIndex] = useState(-1)
    const [showPassword, setShowPassword] = useState(false)
    const [waitingForSignup, setWaitingForReset] = useState(false)

    const history = useHistory()
    const dispatch = useDispatch()
    const openToast = useSelector(state => state.AuthReducer.openToast)
    const toastContent = useSelector(state => state.AuthReducer.toastContent)

    useEffect(() => {
        if (openToast) {
            const timer = setTimeout(() => {
                dispatch({ type: 'TOAST', flag: false, toastContent: toastContent })
                clearTimeout(timer)
            }, 5000)
        }
    }, [openToast, dispatch, toastContent])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (email.trim() && password.trim()) {
            var formData = {
                'user_type': 'Client',
                'user_name': email,
                'password': password,
            }
            setWaitingForReset(true)
            const signUpTimer = setTimeout(() => {
                setWaitingForReset(false)
                clearInterval(signUpTimer)
                dispatch(doForgotPassword(formData))
                dispatch({ type: 'CRASH', payload: true })
            }, 3000)
        }
        else dispatch({ type: 'CRASH', payload: false })
    }

    return (
        <StyledContainer display='flex' justifyContent='center' alignItems='center' minHeight='100%' mt='auto' mb='auto'>
            <Fade in={openToast} timeout={500}>
                <CredentialAlert severity='error'>{toastContent}</CredentialAlert>
            </Fade>
            <LoginCard>
                <form onSubmit={handleSubmit}>
                    <Box component='span' fontSize='24px' fontWeight='700' color='#333' lineHeight='1.2' textAlign='center' width='100%' display='block' pb='54px'>Forgot your password?</Box>
                    <InputContainer active={focusIndex === 0 ? 1 : 0} onFocus={() => setFocusIndex(0)} onBlur={() => setFocusIndex(-1)}>
                        <input type='email' placeholder='Your Email'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Box component='span'>
                            <Email />
                        </Box>
                        <ErrowClaimer valid={email ? 1 : 0}>
                            <Error />
                        </ErrowClaimer>
                    </InputContainer>
                    <InputContainer active={focusIndex === 1 ? 1 : 0} onFocus={() => setFocusIndex(1)} onBlur={() => setFocusIndex(-1)}>
                        <input type={!showPassword ? 'password' : 'text'} placeholder='New Password'
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Box component='span'>
                            <Lock />
                        </Box>
                        <ErrowClaimer valid={password ? 1 : 0}>
                            <Error />
                        </ErrowClaimer>
                        <TrueEye valid={password.trim() === '' ? 1 : 0} onClick={() => password.trim() && setShowPassword(!showPassword)}>
                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                        </TrueEye>
                    </InputContainer>
                    <Box width='100%' display='flex' flexWrap='wrap' justifyContent='center' pt='20px' position='relative'>
                        <SignupButton component='button' type='submit' disabled={(!(email.trim() && password.trim())) || waitingForSignup}>
                            {waitingForSignup && <CircularProgress size='15px' />}
                            <Box ml={waitingForSignup ? 1 : 0}>Send email</Box>
                        </SignupButton>
                    </Box>
                    <Box pt='68px' textAlign='center' >
                        <ForgetLink component='a' position='relative' onClick={() => history.push(`${PAGES.LOGIN}`)}>
                            {'\u00a0'}Back to Login
                            <ArrowRight />
                        </ForgetLink>
                    </Box>
                </form>
            </LoginCard>
        </StyledContainer >
    )
}

const CredentialAlert = styled(Alert)`
    position: fixed;
    top: 25px;
    right: 25px;
    z-index: 1;
`

const ArrowRight = styled(ArrowRightAlt)`
    transform: rotate(180deg);
`

const ErrowClaimer = styled(Box)`
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-13px, -50%);
    transition: .3s;
    color: ${({ valid }) => !valid ? '#c80000' : 'transparent'};
    >svg {
        width: 20px;
        height: 20px;
    }
`

const TrueEye = styled(Box)`
    cursor: ${({ valid }) => !valid ? 'pointer' : 'default'};
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-13px, -50%);
    transition: .3s;
    color: ${({ valid }) => !valid ? '#57b846' : 'transparent'};
    >svg {
        width: 20px;
        height: 20px;
    }
`

const ForgetLink = styled(Box)`
    cursor: pointer;
    font-size: 13px;
    line-height: 1.5;
    color: #666;
    margin: 0;
    transition: all .4s;
    text-decoration: none;
    &:hover {
        color: #57b846;
    }
    >svg {
        margin-left: 5px;
    }
`

const SignupButton = styled(Box)`
    font-family: Montserrat;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #57b846;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    transition: all .4s;
    outline: none;
    border: none;
    &:hover {
        background: #333;
        cursor: pointer;
    }
    &:disabled {
        cursor: not-allowed;
        background: lightgrey;
    }
`

const InputContainer = styled(Box)`
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
    >input {
        font-size: 15px;
        line-height: 1.5;
        color: #666;
        display: block;
        width: 100%;
        background: #e6e6e6;
        height: 50px;
        border-radius: 25px;
        padding: 0 30px 0 68px;
        outline: none;
        border: none;
        &::placeholder {
            color: #aaa;
            font-weight: 600;
        }
    }
    >span {
        font-size: 15px;
        display: flex;
        align-items: center;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 35px;
        pointer-events: none;
        color: #666;
        transition: all .4s;
        >svg {
            width: 18px; height: 18px;
            transition: .3s;
            color: ${({ active }) => active ? '#57b846' : 'inherit'};
            transform: translate(${({ active }) => active ? '-5px' : '0px'}, 0px);
        }
    }
`

const LoginCard = styled(Card)`
    margin-top: 0px;
    position: relative;
    padding: 33px 100px 33px 100px;
    width: 500px;
    border-radius: 10px !important;
    &::before {
        content: '';
        width: 12px;
        height: 150px;
        background: #233266;
        display: block;
        position: absolute;
        left: 0px;
        z-index: 0;
        border-radius: 0px 18px 0px 0px;
        bottom: 0;
        left: 0;
        box-shadow: 12px 36px 0 #3d57b2, 24px 72px 0 #8194d4;
    }
`

const StyledContainer = styled(Box)`
    background-image: url(${require("../../../assets/images/back/08.jpg").default});
    background-size: 100% 100%;
    background-repeat: no-repeat;
`

export default Forgot