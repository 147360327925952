import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Modal, Fade, Backdrop } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CloseOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import _ from 'lodash'

import { doUpdateReceipt } from 'src/redux/actions/PelletAction'
import { doseTypes } from 'src/entry'
import jwtDecode from 'jwt-decode'

const ReceiptEditModal = ({ open, setOpen, where, receipt }) => {

    let uniqueIndex = -1
    let doseTotalLength = 0
    const user = localStorage.getItem('jwtToken')
    doseTypes.forEach(each => {
        doseTotalLength += each.sizes.length
    })

    const dispatch = useDispatch()

    const [info, setInfo] = useState({
        'pellet_date': '',
        'pellet_receipt_invoice': '',
        'pellet_receipt_verified_by': '',
        'created_by': jwtDecode(user).userName
    })

    useEffect(() => {
        if (open) {
            let cnt = 0
            let tmp = {}

            let quantity_tmp = Array(doseTotalLength).fill('')
            let lotSharp_tmp = Array(doseTotalLength).fill('')
            let expDates_tmp = Array(doseTotalLength).fill('')
            let original_tmp = Array(doseTotalLength).fill('')

            if(typeof receipt !='undefined')
            {

                tmp.pellet_date = receipt.pellet_date.slice(0, 10)
                tmp.pellet_receipt_invoice = receipt.pellet_receipt_invoice
                tmp.pellet_receipt_verified_by = receipt.pellet_receipt_verified_by
                tmp.created_by = receipt.created_by
    
                doseTypes.forEach((type, i) => {
                    type.sizes.forEach((size, j) => {
                        
                        if (receipt.doseInfo.some(item => item.dose_size === size.toString())) {
    
                            // check that current pellet is what is loaded in edit
                            // if (receipt.pellet_receipt_id == receipt.doseInfo[j]?.pellet_receipt_id) {
    
                                quantity_tmp[cnt] = receipt.doseInfo[receipt.doseInfo.findIndex(item => item.dose_size === size.toString())].dose_qty
                                original_tmp[cnt] = receipt.doseInfo[receipt.doseInfo.findIndex(item => item.dose_size === size.toString())].original_qty
                                lotSharp_tmp[cnt] = receipt.doseInfo[receipt.doseInfo.findIndex(item => item.dose_size === size.toString())].dose_lot_number
                                expDates_tmp[cnt] = receipt.doseInfo[receipt.doseInfo.findIndex(item => item.dose_size === size.toString())].pellet_exp_date
    
                                setOriginal(original_tmp)
                                setQuantity(quantity_tmp)
                                setLotSharp(lotSharp_tmp)
                                setExpDates(expDates_tmp)
                                setOldOriginal(original_tmp)
                            // }
                        }
                        cnt++
                    })
                })
            }
            setInfo(tmp)
        }
    }, [open, doseTotalLength])

    const [quantity, setQuantity] = useState(Array(doseTotalLength).fill(''))
    const [original, setOriginal] = useState(Array(doseTotalLength).fill(''))
    const [lotSharp, setLotSharp] = useState(Array(doseTotalLength).fill(''))
    const [expDates, setExpDates] = useState(Array(doseTotalLength).fill(''))
    const [pricePer, setPricePer] = useState(Array(doseTotalLength).fill(''))
    const [inputValid, setInputValid] = useState(Array(doseTotalLength).fill())
    const [oldoriginal, setOldOriginal] = useState(Array(doseTotalLength).fill(''))
    const [validFlags, setValidFlags] = useState({
        verify: [true, true, true],
    })

    const handleQtyInput = (e) => {
        const i = parseInt(e.target.name)
        setOriginal(prev => { const tmp = [...prev]; tmp[i] = e.target.value; return tmp })
        setInputValid(prev => {
            const tmp = [...prev]; tmp[i] =
                (e.target.value === '' && lotSharp[i] === '' && expDates[i] === '') ||
                (e.target.value !== '' && lotSharp[i] !== '' && expDates[i] !== '')
            return tmp
        })
    }

    const handleLotInput = (e) => {
        const i = parseInt(e.target.name)
        setLotSharp(prev => { const tmp = [...prev]; tmp[i] = e.target.value; return tmp })
        setInputValid(prev => {
            const tmp = [...prev]; tmp[i] =
                (quantity === '' && e.target.value === '' && expDates[i] === '') ||
                (quantity !== '' && e.target.value !== '' && expDates[i] !== '')
            return tmp
        })
    }

    const handleExpDates = (e) => {
        const i = parseInt(e.target.name)
        setExpDates(prev => { const tmp = [...prev]; tmp[i] = e.target.value; return tmp })

        setInputValid(prev => {
            const tmp = [...prev]; tmp[i] =
                (quantity[i] === '' && lotSharp[i] === '' && e.target.value === '') ||
                (quantity[i] !== '' && lotSharp[i] !== '' && e.target.value !== '')
            return tmp
        })
    }

    const handlePricePer = (e) => {
        const i = parseInt(e.target.name)
        setPricePer(prev => { const tmp = [...prev]; tmp[i] = e.target.value; return tmp })
    }

    const handleUpdatePelletReceipt = () => {
        let formData = { receiptData: info, doseData: [] }
        let cnt = 0
        doseTypes.forEach(type => {
            type.sizes.forEach(size => {
                if (quantity[cnt] !== '' && lotSharp[cnt] !== '' && expDates[cnt] !== '')
                    formData.doseData.push({
                        'dose_name_type': type.name,
                        'dose_size': size,
                        'original_qty': original[cnt],
                        'dose_qty': quantity[cnt],
                        'dose_lot_number': lotSharp[cnt],
                        'pellet_exp_date': expDates[cnt],
                    })
                cnt++
            })
        })
        formData.where = where
        dispatch(doUpdateReceipt(formData))
        setOpen(false)
    }

    return (
        <>
            {open &&
                <Modal open={open} onClose={() => setOpen(false)}
                    BackdropComponent={Overlay}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <StyledContainer bgcolor='white' fontFamily='Poppins' color='white'>
                            <ModalHeader component='header'>
                                <Box component='h4' width='100%' lineHeight='110%' margin='0px' fontWeight='600' fontSize='20px' color='rgb(4,17,29)'>
                                    Edit Pellet
                                </Box>
                            </ModalHeader>
                            <ModalBody component='section'>
                                <Box>
                                    <VerifyHeader>
                                        <Box display='flex' alignItems='center'>
                                            <Box width='230px'>Date:</Box>
                                            <Box component='input' fontSize='14px' width='300px' type='date' disabled
                                                value={info.pellet_date} onChange={(e) => {
                                                    setInfo(_.merge({ ...info }, info, info.pellet_date = e.target.value))
                                                    setValidFlags(_.merge({ ...validFlags }, validFlags, e.target.value !== '' ? validFlags.verify[0] = true : validFlags.verify[0] = false))
                                                }}
                                                max="9999-12-31"
                                            />
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>Receipt Inv #:</Box>
                                            <Box component='input' fontSize='14px' width='300px' disabled
                                                value={info.pellet_receipt_invoice} onChange={(e) => {
                                                    setInfo(_.merge({ ...info }, info, info.pellet_receipt_invoice = e.target.value))
                                                    setValidFlags(_.merge({ ...validFlags }, validFlags, e.target.value !== '' ? validFlags.verify[1] = true : validFlags.verify[1] = false))
                                                }}
                                            />
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>Receipt Verified By:</Box>
                                            <Box component='input' fontSize='14px' width='300px' disabled
                                                value={info.pellet_receipt_verified_by} onChange={(e) => {
                                                    setInfo(_.merge({ ...info }, info, info.pellet_receipt_verified_by = e.target.value))
                                                    setValidFlags(_.merge({ ...validFlags }, validFlags, e.target.value !== '' ? validFlags.verify[2] = true : validFlags.verify[2] = false))
                                                }}
                                            />
                                        </Box>
                                    </VerifyHeader>
                                </Box>
                                {_.map(doseTypes, (each, parentIndex) =>
                                    <Box key={parentIndex} mt='30px'>
                                        <FormHead display='flex' flexWrap='wrap'>
                                            <Box width='280px' fontSize='20px' fontWeight='600'>{each.name} / Dose </Box>
                                            <Box width='200px' ml='50px' fontSize='20px' fontWeight='600'>Lot # </Box>
                                            <Box width='200px' ml='50px' fontSize='20px' fontWeight='600'>Exp Date </Box>
                                            <Box width='200px' ml='50px' fontSize='20px' fontWeight='600' display="none">Price Per Dose </Box>
                                        </FormHead>
                                        <FormBody>
                                            {_.map(each.sizes, eac => {
                                                uniqueIndex++
                                                return <Box key={uniqueIndex} display='flex' flexWrap='wrap'>
                                                    <Box display='flex' justifyContent='flex-start' alignItems='center'>
                                                        <Box width='100px' alignSelf='center'>{eac} mg</Box>
                                                        <Box width='130px' component='input' type='text' name={`${uniqueIndex}`} value={original[uniqueIndex]} onChange={handleQtyInput} disabled /> 
                                                        {/* disabled={oldoriginal[uniqueIndex]} */}
                                                        <Box ml='20px'>Qty</Box>
                                                    </Box>
                                                    <Box width='200px' ml='50px' component='input' name={`${uniqueIndex}`} value={lotSharp[uniqueIndex]} onChange={handleLotInput} disabled  />
                                                    <Box width='200px' ml='50px' component='input' {...{ type: 'date' }} name={`${uniqueIndex}`} value={expDates[uniqueIndex]} onChange={handleExpDates} max="9999-12-31" disabled />
                                                    <Box width='200px' ml='50px' mr='50px' component='input' name={`${uniqueIndex}`} value={pricePer[uniqueIndex]} onChange={handlePricePer} display="none" />
                                                    {inputValid[uniqueIndex] === false && <Alert severity='error'>Error</Alert>}
                                                </Box>
                                            }
                                            )}
                                        </FormBody>
                                    </Box>
                                )}
                            </ModalBody>
                            <ModalFoot>
                                <Action mt='30px'>
                                    {/* old */}
                                    {/* <MyButton variant='contained' color='primary' disabled={
                                        validFlags.verify.some(e => e === false) ||
                                        inputValid.some(e => e === false)
                                    }
                                        onClick={handleUpdatePelletReceipt}
                                    >
                                        Save
                                    </MyButton> */}
                                    <MyButton variant='contained' color='secondary' onClick={() => setOpen(false)}>Cancel</MyButton>
                                </Action>
                            </ModalFoot>
                            <Box display='flex' position='absolute' right='24px' top='24px'>
                                <CloseButton component='button' onClick={() => setOpen(false)}>
                                    <CloseOutlined style={{ fontSize: 24 }} />
                                </CloseButton>
                            </Box>
                        </StyledContainer>
                    </Fade>
                </Modal >
            }
        </>
    )
}

const VerifyHeader = styled(Box)`
    >div {
        @media (max-width: 1000px) {
            align-items: initial;
            flex-direction: column;
            >input {
                width: 100%;
            }
        }
    }
`

const Action = styled(Box)`
    >button+button {
        margin-left: 30px;
    }
`

const MyButton = styled(Button)`
    text-transform: none !important;
`

const FormHead = styled(Box)`
    @media (max-width: 1000px) {
        >div {
            flex: 1;
        }
        >div:not(:first-of-type) {
            display: none;
            margin-left: unset;
        }
    }
`

const FormBody = styled(Box)`
    >div+div {
        margin-top: 15px;
    }
    >div >div {
        @media (max-width: 1000px) {
            width: 100%;
            >input {
                flex: 1;
            }
        }
    }
    >div >input, >div >div:last-of-type {
        @media (max-width: 1000px) {
            margin-left: unset;
            margin-right: unset;
            margin-top: 15px;
            width: 100%;
        }
    }
`

const Overlay = styled(Backdrop)`
    background-color: rgba(0, 0, 0, 0.8) !important;
`

const CloseButton = styled(Box)`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 100%;
    font-family: inherit;
    border: 0px;
    padding: 0px;
    background: inherit;
    >svg { color: rgb(138, 147, 155); }
    &:hover {
        >svg { color: rgb(112, 122, 131); }
    }
`

const ModalHeader = styled(Box)`
    padding: 24px;
    border-bottom: 1px solid rgb(229, 232, 235);
    >h4 { word-break: break-word; }
`

const ModalBody = styled(Box)`
    padding: 24px;
    height: 100%;
    overflow-y: auto;
    font-weight: 400;
    color: rgb(53, 56, 64);
`

const ModalFoot = styled(Box)`
    padding: 24px;
    text-align: right;
`

const StyledContainer = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: transparent;
    max-height: calc(100% - 50px);
    overflow-y: auto;
    width: calc(100% - 32px);
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-corner {
        background: inherit;
    }
    &::-webkit-scrollbar-thumb {
        width: 30px;
        border-radius: 15px;
        background: rgb(33,37,41);
    }
    input {
        outline: none;
        border: 1.5px solid lightgrey;
        border-radius: 5px;
        transition: .3s;
        padding: 5px;
        height: fit-content;
        align-self: center;
        &:focus {
            border-color: #c850c0;
        }
    }
    select {
        border: none;
        outline: none;
        padding: 4px;
        border-radius: 5px;
    }
`

export default ReceiptEditModal