import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Fade,Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import _ from 'lodash'

import { DispenseRow } from 'src/components/Rows'
import { DispenseEditModal, ConfirmModal } from 'src/components/Modals'
import Spinner from 'src/components/Spinners'
import Pagination from 'src/components/Paginations'
import ColumnDrops from 'src/components/Dropdowns/ColumnDrops'
import SearchInput from 'src/components/Inputs/SearchInput'

import { doDeleteDispense } from 'src/redux/actions/PatientAction'

import BackImg from 'src/assets/images/backs/user_management.jpg'
import jwtDecode from 'jwt-decode'

const DispenseEdit = () => {
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [openMsg, setOpenMsg] = useState(false)
    const [index, setIndex] = useState(-1)
    const [jIndex, setJIndex] = useState('')
    const [page, setPage] = useState(1)
    const [flags, setFlags] = useState([true, false, false, false, false])
    const [keyword, setKeyword] = useState('')
    const openToast = useSelector(state => state.AuthReducer.openToast)
    const toastContent = useSelector(state => state.AuthReducer.toastContent)
    const dispense = useSelector(state => state.PatientReducer.dispense)
    const procedures = useSelector(state => state.PatientReducer.procedures)
    const [current_dispense,setCurrentdDispense] = useState('')
    const doses = useSelector(state => state.PatientReducer.doses)
    let totalrows=dispense.length;
    const user = localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken'))
     //console.log(doses);
    if(keyword !='')
    {
        totalrows=0;

        for(let x=0;x<dispense.length;x++)
        {
            if((flags[0] && dispense[x].patient_first_name.search(keyword) !== -1) || (flags[1] && dispense[x].patient_last_name.search(keyword) !== -1) ||(flags[2] && dispense[x].dispense_date.search(keyword) !== -1) ||(flags[3] && dispense[x].patient_phone_number.toString().search(keyword) !== -1) || (flags[4] && dispense[x].patient_dob.search(keyword) !== -1) ||(flags[5] && dispense[x].practice_name_new.search(keyword) !== -1))
            {
                totalrows++;
            }
        }
    }
    useEffect(() => {
        if (openToast) {
            const timer = setTimeout(() => {
                dispatch({ type: 'TOAST', flag: false, toastContent: toastContent })
                clearTimeout(timer)
            }, 5000)
        }
    }, [openToast, dispatch, toastContent])

    const handleDelete = () => {
        dispatch(doDeleteDispense(jIndex,doses))
    }

    return (
        <StyledContainer>
            <Background />
            <Box padding='20px'>
                <Box fontSize='20px' fontWeight='600'>Patients</Box>
                <Fade in={openToast} timeout={500}>
                    <CredentialAlert severity='info'>{toastContent}</CredentialAlert>
                </Fade>
                <Box display='flex' justifyContent='flex-end'>
                    {/* <ColumnDrops flags={flags} setFlags={setFlags} filterValues={['FName', 'LName', 'Date', 'Phone Num', 'DOB', 'Created By']} /> */}
                    <ColumnDrops flags={flags} setFlags={setFlags} filterValues={['FName', 'LName', 'Date', 'Phone Num', 'DOB', 'Practice Name']} />
                    <SearchInput keyword={keyword} setKeyword={setKeyword} />
                    {user.userType == "Administrator" ?
                    (<Box ml={2}>
                        <Button variant='contained' color='primary' onClick={() => window.location='dispense_add'}>New</Button>
                    </Box>):''
                    }
                </Box>
                <Box width='100%' boxShadow='2px 2px 5px 1px #ccc' mt={3} overflow='auto'>
                    <Table width='100%' component='table'>
                        <THead component='thead'>
                            <tr>
                                <th>No</th>
                                <th>Procedure</th>
                                <th>FName</th>
                                <th>LName</th>
                                <th>Date</th>
                                <th>Phone Number</th>
                                <th>DOB</th>
                                {/* <th>Created By</th> */}
                                <th>Practice Name</th>
                                {/* <th></th> */}
                                <th></th>
                            </tr>
                        </THead>
                        <TBody component='tbody' pb='20px'>
                            {flags.some(e => e === true) ? _.map(_.filter(_.filter(dispense, e => (flags[0] && e.patient_first_name.search(keyword) !== -1) ||
                                (flags[1] && e.patient_last_name.search(keyword) !== -1) ||
                                (flags[2] && e.dispense_date.search(keyword) !== -1) ||
                                (flags[3] && e.patient_phone_number.toString().search(keyword) !== -1) ||
                                (flags[4] && e.patient_dob.search(keyword) !== -1) ||(flags[5] && e.practice_name_new.search(keyword) !== -1)
                            ), (e, uIndex) =>  uIndex >= 20 * (page - 1) && uIndex <= 20 * (page - 1) + 19), (each, i) =>
                            
                                <DispenseRow
                                    id={i + 20 * (page - 1)} key={i}
                                    eachInfo={each} procedureName={procedures[each.procedure_id].procedure_name}
                                    setOpen={setOpen} setOpenMsg={setOpenMsg}
                                    setIndex={setIndex} setJIndex={setJIndex} setCurrentdDispense={setCurrentdDispense}  
                                />
                            ) :
                                _.map(_.filter(_.filter(dispense, e => (e.patient_first_name.search(keyword) !== -1) ||
                                    (e.patient_last_name.search(keyword) !== -1) ||
                                    (e.dispense_date.search(keyword) !== -1) ||
                                    (e.patient_phone_number.toString().search(keyword) !== -1) ||
                                    (e.patient_dob.search(keyword) !== -1) ||(e.practice_name_new.search(keyword) !== -1)
                                ), (e, uIndex) => uIndex >= 20 * (page - 1) && uIndex <= 20 * (page - 1) + 19), (each, i) =>
                                    <DispenseRow
                                        id={i + 20 * (page - 1)} key={i}
                                        eachInfo={each} procedureName={procedures[each.procedure_id].procedure_name}
                                        setOpen={setOpen} setOpenMsg={setOpenMsg}
                                        setIndex={setIndex} setJIndex={setJIndex} setCurrentdDispense={setCurrentdDispense} 
                                    />
                                )}
                        </TBody>
                    </Table>
                    {(dispense && dispense.length === 0) && <Box width='100%' display='flex' justifyContent='center'>
                        <Spinner />
                    </Box>
                    }
                </Box>
                {/* old */}
                {/* {(dispense && dispense.length > 0) && <Pagination step={20} totalCount={dispense.length} page={page} setPage={setPage} />} */}
                {(dispense && dispense.length > 0) && <Pagination step={20} totalCount={totalrows} page={page} setPage={setPage} keyword={keyword} />}
            </Box>
            {/* old */}
            {/* <DispenseEditModal open={open} setOpen={setOpen} where={jIndex} dispense={dispense[index]} /> */}
            <DispenseEditModal open={open} setOpen={setOpen} where={jIndex} dispense={current_dispense} />
            <ConfirmModal open={openMsg} setOpen={setOpenMsg} confirmDelete={handleDelete} />
        </StyledContainer >
    )
}

const CredentialAlert = styled(Alert)`
    position: fixed;
    top: 70px;
    right: 25px;
    z-index: 1;
`

// const MyButton = styled(Button)`
//     text-transform: none !important;
// `

const THead = styled(Box)``

const TBody = styled(Box)``

// const TFoot = styled(Box)``

const Table = styled(Box)`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: white;
        font-weight: 600;
    }
    tbody tr {
        transition: .3s;
        background-color: white;
        border-top: 1px solid lightgrey;
    }
    tr:hover {background-color: #eee;}
    th, td {
        padding: 8px;
    }
`

const Background = styled(Box)`
    position: absolute;
    width: 100%; height: 100%;
    background: url(${BackImg});
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position-y: center;
    opacity: .15;
    user-select: none;
    pointer-events: none;
`

const StyledContainer = styled(Box)`
    min-height: 500px;
    position: relative;
`

export default DispenseEdit