import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { ArrowBack, ArrowForward, ExpandMore, FilterList } from '@material-ui/icons'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { doseTypes } from 'src/entry'
import Check from 'src/assets/images/checkmark.png'

const FilterBar = ({ pushed, setPushed , check, set_check, fdate, set_fdate, first, set_first, last, set_last ,check_practice, set_practice}) => {
    const [opens, setOpens] = useState([false, false])

    const daysInMonth = (month, year) =>{
        return new Date(year, month, 0).getDate();
    }

    const dispense = useSelector(state => state.PatientReducer.dispense)
    const procedure = useSelector(state => state.PatientReducer.procedures)
    const user = JSON.parse(localStorage.getItem('uid'))




    return (
        <>
            {pushed ?
                <StyledContainer>
                    <Box padding='10px' fontWeight='700' fontSize='20px' display='flex' alignItems='center' color='black' justifyContent='space-between'>
                        <Box display='flex'>
                            <FilterList />
                            <Box ml={1}>Filter</Box>
                        </Box>
                        <OmitButton onClick={() => setPushed(false)}>
                            <ArrowBack fill='black' />
                        </OmitButton>
                    </Box>
                    <FilterFactor active={opens[0] ? 1 : 0}>
                        <Box padding='10px' borderTop='1px solid lightgrey' borderBottom='1px solid lightgrey' >
                            <Box display='flex' justifyContent='space-between' alignItems='center' onClick={() => {
                                let tmp = [...opens]
                                tmp[0] = !tmp[0]
                                setOpens(tmp)
                            }}>
                                <Box fontSize='18px' color='black' fontWeight='500'>Pellet Type</Box>
                                <ExpandMoreIcon />
                            </Box>
                        </Box>
                        {opens[0] &&
                            <Box fontSize='16px' color='#555' overflow='auto' maxHeight='200px'>
                                {_.map(doseTypes[0].sizes, (each,i) =>
                                    <FilterItem key={i} pt={1} pb={1} onClick={()=>{
                                        let temp = [...check]
                                        temp[i] = !temp[i]
                                        set_check(temp)
                                    }}>E{each}{check[i]&&<img src={Check} width='20px' height='20px' alt='' />}</FilterItem>
                                    
                                )}
                                {_.map(doseTypes[1].sizes, (each,i) =>
                                    <FilterItem key={i} pt={1} pb={1}  onClick={()=>{
                                        let temp = [...check]
                                        temp[4+i] = !temp[4+i]
                                        set_check(temp)
                                    }}>T{each}{check[4+i]&&<img src={Check} width='20px' height='20px' alt='' />}</FilterItem>
                                )}
                            </Box>
                        }
                    </FilterFactor>




                     
                   



                    <FilterFactor active={opens[1] ? 1 : 0}>
                        <Box padding='10px' borderTop='1px solid lightgrey' borderBottom='1px solid lightgrey' >
                            <Box display='flex' justifyContent='space-between' alignItems='center' onClick={() => {
                                let tmp = [...opens]
                                tmp[1] = !tmp[1]
                                setOpens(tmp)
                            }}>
                                {/* <Box fontSize='18px' color='black' fontWeight='500'>Mth End Date</Box> */}
                                <Box fontSize='18px' color='black' fontWeight='500'>Select Month</Box>
                                <ExpandMoreIcon />
                            </Box>
                        </Box>
                        {opens[1] &&
                            <Box fontSize='16px' color='#555' overflow='auto' maxHeight='200px'>
                                <FilterItem pt={1} pb={1}><Box component='input' {...{ type: 'month' }} style={{border:'solid 1px'}} value={fdate} onChange={(e)=>{

                                    set_fdate(e.target.value)
                                    let str_year = (e.target.value).substring(0,4)
                                    let str_month = (e.target.value).substring(5,7)
                                    
                                    let temp1 = {...first}
                                    temp1.date = e.target.value+'-01'
                                    set_first(temp1)
                                    let temp2 = {...last}
                                    temp2.date = e.target.value  + '-' +daysInMonth(str_month,str_year)
                                    set_last(temp2)

                                }}/></FilterItem>
                                {/* <FilterItem pt={1} pb={1}>1/31/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>2/29/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>3/31/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>4/30/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>5/31/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>6/30/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>7/31/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>8/31/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>9/30/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>10/31/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>11/30/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>12/31/2020</FilterItem>
                                <FilterItem pt={1} pb={1}>1/31/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>2/28/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>3/31/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>4/30/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>5/31/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>6/30/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>7/31/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>8/31/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>9/30/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>10/31/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>11/30/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>12/31/2021</FilterItem>
                                <FilterItem pt={1} pb={1}>1/31/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>2/28/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>3/31/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>4/30/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>5/31/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>6/30/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>7/31/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>8/31/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>9/30/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>10/31/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>11/30/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>12/31/2022</FilterItem>
                                <FilterItem pt={1} pb={1}>1/31/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>2/28/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>3/31/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>4/30/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>5/31/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>6/30/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>7/31/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>8/31/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>9/30/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>10/31/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>11/30/2023</FilterItem>
                                <FilterItem pt={1} pb={1}>12/31/2023</FilterItem> */}
                            </Box>
                        }
                    </FilterFactor>
                </StyledContainer>
                :
                <OmittedContainer padding='14px'>
                    <Box onClick={() => setPushed(true)}>
                        <ArrowForward />
                    </Box>
                </OmittedContainer>
            }
        </>
    )
}

const OmittedContainer = styled(Box)`
    min-height: 300px;
    width: 50px;
    box-shadow: 0px -4px 4px 1px lightgrey;
    display: none;
    @media (min-width: 700px) {
        display: initial;
    }
    >div {
        cursor: pointer;
    }
`

const OmitButton = styled(Box)`
    cursor: pointer;
`

const ExpandMoreIcon = styled(ExpandMore)``

const FilterItem = styled(Box)`
    padding-left: 24px;
    transition: .5s;
    background: white;
    display: flex;
    justify-content: space-between;
    &:hover {
        background: #eee;
    }
`

const FilterFactor = styled(Box)`
    cursor: pointer;
    ${ExpandMoreIcon} {
        transition: .3s;
        transform: rotate(${({ active }) => active ? '-180deg' : '0deg'});
    }
`

const StyledContainer = styled(Box)`
    min-height: 300px;
    width: 250px;
    box-shadow: 0px -4px 4px 1px lightgrey;
    display: none;
    @media (min-width: 700px) {
        display: initial;
    }
    input {
        border: 1px solid lightgrey;
        border-radius: 5px;
        outline: none;
    }
`

export default FilterBar