import { PELLET_DEL_CRUD } from 'src/redux/actions/types'
import _ from 'lodash'

const initState = {
    receipts_deleted: [],
}

export default function todo(state = initState, action) {
    let tmp
    switch (action.type) {
        case PELLET_DEL_CRUD.CREATE:
            return {
                ...state,
                receipts_deleted: action.receipts_deleted,
            }
        case PELLET_DEL_CRUD.READ:
            return {
                ...state,
                receipts_deleted: action.receipts_deleted
            }
        case PELLET_DEL_CRUD.UPDATE:
            const receiptData = action.updatedData.receiptData
            tmp = [...state.receipts_deleted]
            receiptData._id = action.updatedData.where
            tmp[_.findIndex(tmp, item => item._id === action.updatedData.where)] = action.updatedData.receiptData
            return {
                ...state,
                receipts_deleted: tmp,
            }
        case PELLET_DEL_CRUD.DELETE:
            tmp = [...state.receipts_deleted]
            tmp.splice(_.findIndex(e => e._id === action.id), 1)
            return {
                ...state,
                receipts_deleted: tmp
            }
        default:
            return state
    }
}