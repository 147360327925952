
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import styled from 'styled-components'

import FilterBar from './FilterBar'
import Exporter from 'src/components/Exporter'

import BackImg from 'src/assets/images/backs/procedure_bg.jpg'
import { doGetMTD } from 'src/redux/actions/ReportAction'
import { doGetWholeInv } from 'src/redux/actions/InvAction'
import jwtDecode from 'jwt-decode'


const MTDR = () => {
    // Start practice dropdown
    const practiceList = useSelector(state => state.AuthReducer.users).filter(u => u.user_type == "Practice")
    const [practice, setpractice] = useState("All");

    // End practice dropdown

    const user = localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken'))

    const dispatch = useDispatch();

    // need to get pellet_receipt_records with array of doses
    let mtd_all_dose = useSelector(state => state.ReportReducer.report_MTD)
    let filtered_dose = [];
    const [cstep, setCStep] = useState(15)
    const [ostep, setOStep] = useState(15)
    const [title, setTitle] = useState('Month To Date Report');
    const [ptitle, setPTitle] = useState('Practice: All');
    // mtd_dose = mtd_dose != undefined ? (practice != "All" ? (mtd_dose.filter(d => d.PelletReceipt[0].created_by == practice)) : mtd_dose) : mtd_dose

    // filter mtd_patient by  // todo
    let patient_dispence = useSelector(state => state.PatientReducer.dispense)
    let filtered_patient_dispence = [];

    let mtd_inv = useSelector(state => state.InvReducer.inventories)
    let filtered_inv = [];

    // filter doses by current user 
    if (user.userType == "Practice") {
   
        var user_name_all=user.userFName+' '+user.userLName+' ('+user.practice_name+' '+user.practioner_name+')';
        for (let i = 0; i < mtd_all_dose?.length; i++) {
            //new
            if (mtd_all_dose[i].PelletReceipt[0]?.created_by) {
           // if (mtd_all_dose[i].PelletReceipt[0].created_by == user.userName) {
            if (mtd_all_dose[i].PelletReceipt[0].created_by == user.userName || mtd_all_dose[i].PelletReceipt[0].created_by == user_name_all){
                filtered_dose.push(mtd_all_dose[i]);
            }
         }
        }

        // inv
        for (let i = 0; i < mtd_inv?.length; i++) {
            //if (mtd_inv[i].practice_name == user.userName) {
            if (mtd_inv[i].practice_name == user.userName || mtd_inv[i].practice_name == user_name_all){
                filtered_inv.push(mtd_inv[i]);
            }
        }

        // patient dspense
        for (let i = 0; i < patient_dispence?.length; i++) {
            //if (patient_dispence[i].practice_name == user.userName) {
            if (patient_dispence[i].practice_name == user.userName || patient_dispence[i].practice_name == user_name_all) {
                filtered_patient_dispence.push(patient_dispence[i]);
            }
        }
    }
    else if (user.userType == "Administrator") {
        if (practice != "All") {
            let  prac_sp = practice.split("/");
            for (let i = 0; i < mtd_all_dose?.length; i++) {
                //new
                // if (mtd_all_dose[i].PelletReceipt[0]?.created_by == practice) {
                    if (mtd_all_dose[i].PelletReceipt[0]?.created_by == prac_sp[0] || mtd_all_dose[i].PelletReceipt[0]?.created_by == prac_sp[1]) {
                    filtered_dose.push(mtd_all_dose[i]);
                }
            }
            // inv
            for (let i = 0; i < mtd_inv?.length; i++) {
                // if (mtd_inv[i].practice_name == practice) {
                if (mtd_inv[i].practice_name == prac_sp[0] || mtd_inv[i].practice_name == prac_sp[1]) {
                    filtered_inv.push(mtd_inv[i]);
                }
            }

            // patient dspense
            for (let i = 0; i < patient_dispence?.length; i++) {
                // if (patient_dispence[i].practice_name == practice) {
                if (patient_dispence[i].practice_name == prac_sp[0] || patient_dispence[i].practice_name == prac_sp[1]) {
                    filtered_patient_dispence.push(patient_dispence[i]);
                }
            }
        }
        else {
            filtered_dose = mtd_all_dose;
            filtered_inv = mtd_inv;
            filtered_patient_dispence = patient_dispence;
        }
    }



    const userTypes = JSON.parse(localStorage.getItem('uid'))

    const daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    }

    const [check, set_check] = useState([true, true, true, true, true, true, true, true, true, true])
    const [fdate, set_fdate] = useState('')

    const tBodyRef = useRef()
    const [pushed, setPushed] = useState(true)
    const [first, set_first] = useState({
        // date: `${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '0' + '01' : '01'}`,
        date: `${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '01' : '01'}`,

    })
    const [last, set_last] = useState({
        date: `${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${daysInMonth(new Date().getMonth() + 1, new Date().getFullYear())}`,

    })
    const [count, setCount] = useState({
        cnt: 0,
    })

    const set_prevday = () => {
        let temp_cnt = { ...count }
        let cnt = temp_cnt.cnt
        cnt++;
        const today = new Date()
        today.setMonth(today.getMonth() - cnt)
        let prev_first = `${today.getFullYear()}-${today.getMonth() + 1 <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}-${today.getDate() <= 9 ? '0' + '1' : '01'}`
        let prev_last = `${today.getFullYear()}-${today.getMonth() + 1 <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}-${daysInMonth(today.getMonth() + 1, today.getFullYear())}`
        let temp_date = { ...first }
        temp_date.date = prev_first
        let temp_date1 = { ...last }
        temp_date1.date = prev_last
        temp_cnt.cnt = cnt
        setCount(temp_cnt)
        set_first(temp_date)
        set_last(temp_date1)
    }

    const set_nextday = () => {
        let temp_cnt = { ...count }
        let cnt = temp_cnt.cnt
        cnt--;
        const today = new Date()
        today.setMonth(today.getMonth() - cnt)
        let prev_first = `${today.getFullYear()}-${today.getMonth() + 1 <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}-${today.getDate() <= 9 ? '0' + '1' : '01'}`
        let prev_last = `${today.getFullYear()}-${today.getMonth() + 1 <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}-${daysInMonth(today.getMonth() + 1, today.getFullYear())}`
        let temp_date = { ...first }
        temp_date.date = prev_first
        let temp_date1 = { ...last }
        temp_date1.date = prev_last
        temp_cnt.cnt = cnt
        setCount(temp_cnt)
        set_first(temp_date)
        set_last(temp_date1)
    }

    const set_today = () => {
        let prev_first = `${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '0' + '01' : '01'}`
        let prev_last = `${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${daysInMonth(new Date().getMonth() + 1, new Date().getFullYear())}`
        let temp_date = { ...first }
        temp_date.date = prev_first
        let temp_date1 = { ...last }
        temp_date1.date = prev_last
        let temp_cnt = { ...count }
        temp_cnt.cnt = 0
        setCount(temp_cnt)
        set_first(temp_date)
        set_last(temp_date1)
    }

    const [e6_total, set_e6_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })
    const [e10_total, set_e10_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })
    const [e12_total, set_e12_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })
    const [e15_total, set_e15_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })
    const [t25_total, set_t25_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })
    const [t37_total, set_t37_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })
    const [t50_total, set_t50_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })
    const [t87_total, set_t87_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })
    const [t100_total, set_t100_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })
    const [t200_total, set_t200_total] = useState({
        startinv: 0,
        receipt: 0,
        returned: 0,
        dispensed: 0,
        endinv: 0,
    })

    useEffect(() => {
        dispatch(doGetMTD());
        dispatch(doGetWholeInv());
    }, [dispatch]);

    useEffect(() => {
        let temp_e6_receipt, temp_e10_receipt, temp_e12_receipt, temp_e15_receipt, temp_t25_receipt, temp_t37_receipt, temp_t50_receipt, temp_t87_receipt, temp_t100_receipt, temp_t200_receipt;
        temp_e6_receipt = temp_e10_receipt = temp_e12_receipt = temp_e15_receipt = temp_t25_receipt = temp_t37_receipt = temp_t50_receipt = temp_t87_receipt = temp_t100_receipt = temp_t200_receipt = 0;

        let temp_e6_inventory, temp_e10_inventory, temp_e12_inventory, temp_e15_inventory, temp_t25_inventory, temp_t37_inventory, temp_t50_inventory, temp_t87_inventory, temp_t100_inventory, temp_t200_inventory;
        temp_e6_inventory = temp_e10_inventory = temp_e12_inventory = temp_e15_inventory = temp_t25_inventory = temp_t37_inventory = temp_t50_inventory = temp_t87_inventory = temp_t100_inventory = temp_t200_inventory = 0;

        let temp_e6_returned, temp_e10_returned, temp_e12_returned, temp_e15_returned, temp_t25_returned, temp_t37_returned, temp_t50_returned, temp_t87_returned, temp_t100_returned, temp_t200_returned;
        temp_e6_returned = temp_e10_returned = temp_e12_returned = temp_e15_returned = temp_t25_returned = temp_t37_returned = temp_t50_returned = temp_t87_returned = temp_t100_returned = temp_t200_returned = 0;

        let temp_e6_dispense, temp_e10_dispense, temp_e12_dispense, temp_e15_dispense, temp_t25_dispense, temp_t37_dispense, temp_t50_dispense, temp_t87_dispense, temp_t100_dispense, temp_t200_dispense;
        temp_e6_dispense = temp_e10_dispense = temp_e12_dispense = temp_e15_dispense = temp_t25_dispense = temp_t37_dispense = temp_t50_dispense = temp_t87_dispense = temp_t100_dispense = temp_t200_dispense = 0;


        let temp_e6_receipt_today, temp_e10_receipt_today, temp_e12_receipt_today, temp_e15_receipt_today, temp_t25_receipt_today, temp_t37_receipt_today, temp_t50_receipt_today, temp_t87_receipt_today, temp_t100_receipt_today, temp_t200_receipt_today;
        temp_e6_receipt_today = temp_e10_receipt_today = temp_e12_receipt_today = temp_e15_receipt_today = temp_t25_receipt_today = temp_t37_receipt_today = temp_t50_receipt_today = temp_t87_receipt_today = temp_t100_receipt_today = temp_t200_receipt_today = 0;
   
        let temp_e6_dispensed_today, temp_e10_dispensed_today, temp_e12_dispensed_today, temp_e15_dispensed_today, temp_t25_dispensed_today, temp_t37_dispensed_today, temp_t50_dispensed_today, temp_t87_dispensed_today, temp_t100_dispensed_today, temp_t200_dispensed_today;
        temp_e6_dispensed_today = temp_e10_dispensed_today = temp_e12_dispensed_today = temp_e15_dispensed_today = temp_t25_dispensed_today = temp_t37_dispensed_today = temp_t50_dispensed_today = temp_t87_dispensed_today = temp_t100_dispensed_today = temp_t200_dispensed_today = 0;

        let temp_e6_returned_today, temp_e10_returned_today, temp_e12_returned_today, temp_e15_returned_today, temp_t25_returned_today, temp_t37_returned_today, temp_t50_returned_today, temp_t87_returned_today, temp_t100_returned_today, temp_t200_returned_today;
        temp_e6_returned_today = temp_e10_returned_today = temp_e12_returned_today = temp_e15_returned_today = temp_t25_returned_today = temp_t37_returned_today = temp_t50_returned_today = temp_t87_returned_today = temp_t100_returned_today = temp_t200_returned_today = 0;


        let temp_e6_dispensed_total, temp_e10_dispensed_total, temp_e12_dispensed_total, temp_e15_dispensed_total, temp_t25_dispensed_total, temp_t37_dispensed_total, temp_t50_dispensed_total, temp_t87_dispensed_total, temp_t100_dispensed_total, temp_t200_dispensed_total;
        temp_e6_dispensed_total = temp_e10_dispensed_total = temp_e12_dispensed_total = temp_e15_dispensed_total = temp_t25_dispensed_total = temp_t37_dispensed_total = temp_t50_dispensed_total = temp_t87_dispensed_total = temp_t100_dispensed_total = temp_t200_dispensed_total = 0;


        var i, j, k;


 










       // if ((filtered_dose[i].dose_size === '6') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))

       // if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
    
       // if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) 
       


            ///////////-----------------------------Begin Estradol 6 =======================================-------------------------------


            for (i = 0; i < filtered_inv?.length; i++) {
                if ((filtered_inv[i].inv_date).substring(0, 10) <= last.date) {
                    let inputedDoses = filtered_inv[i]?.inputedDoses;
    
                    let temp = inputedDoses.length > 0 ? inputedDoses[0] : null
                    for (k = 0; k < temp?.length; k++) {
                        switch (temp[k].dose_size_id) {
                            case '0':
                                temp_e6_returned += parseFloat(temp[k].dose_qty)
                               // if ((filtered_inv[i].inv_date).substring(0, 10) === last.date)
                           
        if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
        temp_e6_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '1':
                                temp_e10_returned += parseFloat(temp[k].dose_qty)
                               if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
                                    temp_e10_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '2':
                                temp_e12_returned += parseFloat(temp[k].dose_qty)
                               if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
                                    temp_e12_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '3':
                                temp_e15_returned += parseFloat(temp[k].dose_qty)
                               if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
                                    temp_e15_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                        }
                    }
    
    
                    temp = inputedDoses.length > 1 ? inputedDoses[1] : null
                    for (k = 0; k < temp?.length; k++) {
                        switch (temp[k].dose_size_id) {
                            case '0':
                                temp_t25_returned += parseFloat(temp[k].dose_qty)
                               if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
                                    temp_t25_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '1':
                                temp_t37_returned += parseFloat(temp[k].dose_qty)
                               if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
                                    temp_t37_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '2':
                                temp_t50_returned += parseFloat(temp[k].dose_qty)
                               if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
                                    temp_t50_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '3':
                                temp_t87_returned += parseFloat(temp[k].dose_qty)
                               if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
                                    temp_t87_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '4':
                                temp_t100_returned += parseFloat(temp[k].dose_qty)
                               if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
                                    temp_t100_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '5':
                                temp_t200_returned += parseFloat(temp[k].dose_qty)
                               if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) 
                                    temp_t200_returned_today += parseFloat(temp[k].dose_qty)
                                break;
                        }
                    }
    
    
    
    
    
    
    
    
    
    
                }
            }
    
    
                //-------------------------------------  recipt
    
            for (i = 0; i < filtered_dose?.length; i++) {
                //new
                if(filtered_dose[i].PelletReceipt[0]?.pellet_date)
                {
                    if ((filtered_dose[i].PelletReceipt[0].pellet_date.toString().replace("T00:00:00.000Z", "") <= last.date)) {
                        switch (filtered_dose[i].dose_size) {
                            case '6':
                                temp_e6_receipt += parseFloat(filtered_dose[i].original_qty);
                              //   if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                     temp_e6_receipt_today += parseFloat(filtered_dose[i].original_qty);
    
    
                              console.log("------------------------------------"
                              , filtered_dose[i].dose_size , filtered_dose[i].pellet_date , filtered_dose[i].original_qty,temp_e6_receipt_today
                              
                              , temp_e6_receipt
                              
                              )
                                break;
                            case '10':
                                temp_e10_receipt += parseFloat(filtered_dose[i].original_qty);
                                 if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                    temp_e10_receipt_today += parseFloat(filtered_dose[i].original_qty);
                                break;
                            case '12.5':
                                temp_e12_receipt += parseFloat(filtered_dose[i].original_qty);
                                 if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                    temp_e12_receipt_today += parseFloat(filtered_dose[i].original_qty);
                                break;
                            case '15':
                                temp_e15_receipt += parseFloat(filtered_dose[i].original_qty);
                                 if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                    temp_e15_receipt_today += parseFloat(filtered_dose[i].original_qty);
                                break;
                            case '25':
                                temp_t25_receipt += parseFloat(filtered_dose[i].original_qty);
                                 if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                    temp_t25_receipt_today += parseFloat(filtered_dose[i].original_qty);
                                break;
                            case '37.5':
                                temp_t37_receipt += parseFloat(filtered_dose[i].original_qty);
                                 if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                    temp_t37_receipt_today += parseFloat(filtered_dose[i].original_qty);
                                break;
                            case '50':
                                temp_t50_receipt += parseFloat(filtered_dose[i].original_qty);
                                 if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                    temp_t50_receipt_today += parseFloat(filtered_dose[i].original_qty);
                                break;
                            case '87.5':
                                temp_t87_receipt += parseFloat(filtered_dose[i].original_qty);
                                 if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                    temp_t87_receipt_today += parseFloat(filtered_dose[i].original_qty);
                                break;
                            case '100':
                                temp_t100_receipt += parseFloat(filtered_dose[i].original_qty);
                                 if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                    temp_t100_receipt_today += parseFloat(filtered_dose[i].original_qty);
                                break;
                            case '200':
                                temp_t200_receipt += parseFloat(filtered_dose[i].original_qty);
                                 if ((filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date))  
                                    temp_t200_receipt_today += parseFloat(filtered_dose[i].original_qty);
                                break;
                        }
                    }

                }
            }
    
            //-------------------------------------  dispences
    
            for (i = 0; i < filtered_patient_dispence?.length; i++) {
                if ((filtered_patient_dispence[i].dispense_date).substring(0, 10) <= last.date) {
                    let inputedDoses = filtered_patient_dispence[i]?.inputedDoses;
                    let temp = inputedDoses.length > 0 ? inputedDoses[0] : null
                    for (k = 0; k < temp?.length; k++) {
                        switch (temp[k].dose_size_id) {
                            case '0':
                                temp_e6_dispensed_total += parseFloat(temp[k].dose_qty)
                              //  if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date))   
                              if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                              temp_e6_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '1':
                                temp_e10_dispensed_total += parseFloat(temp[k].dose_qty)
                                if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                                    temp_e10_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '2':
                                temp_e12_dispensed_total += parseFloat(temp[k].dose_qty)
                                if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                                    temp_e12_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '3':
                                temp_e15_dispensed_total += parseFloat(temp[k].dose_qty)
                                if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                                    temp_e15_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                        }
                    }
                    temp = inputedDoses.length > 1 ? inputedDoses[1] : null
                    for (k = 0; k < temp?.length; k++) {
                        switch (temp[k].dose_size_id) {
                            case '0':
                                temp_t25_dispensed_total += parseFloat(temp[k].dose_qty)
                                if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                                    temp_t25_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '1':
                                temp_t37_dispensed_total += parseFloat(temp[k].dose_qty)
                                if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                                    temp_t37_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '2':
                                temp_t50_dispensed_total += parseFloat(temp[k].dose_qty)
                                if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                                    temp_t50_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '3':
                                temp_t87_dispensed_total += parseFloat(temp[k].dose_qty)
                                if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                                    temp_t87_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '4':
                                temp_t100_dispensed_total += parseFloat(temp[k].dose_qty)
                                if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                                    temp_t100_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                            case '5':
                                temp_t200_dispensed_total += parseFloat(temp[k].dose_qty)
                                if ((filtered_patient_dispence[i].dispense_date.substring(0, 10) <= last.date) && (filtered_patient_dispence[i].dispense_date.substring(0, 10) >= first.date))   
                                    temp_t200_dispensed_today += parseFloat(temp[k].dose_qty)
                                break;
                        }
                    }
    
                }
            }
    
    
            //////--------------------------------------------setting vars









           



      /*  for (i = 0; i < filtered_dose?.length; i++) {

            if ((filtered_dose[i].dose_size === '6') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_e6_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_e6_inventory += parseFloat(filtered_dose[i].dose_qty);
                // console.log(mtd_dose[i].pellet_date)
            }
        }

        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                let temp = inputedDoses[0]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '0') {
                        temp_e6_returned += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }

        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[0]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '0') {
                        temp_e6_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }*/

     /*   let temp_e6_total = { ...e6_total }
        temp_e6_total.returned = temp_e6_returned;
        temp_e6_total.receipt = temp_e6_receipt;
        temp_e6_total.dispensed = temp_e6_dispense;
        temp_e6_total.inventory = temp_e6_receipt - temp_e6_dispense - temp_e6_returned;

        set_e6_total(temp_e6_total)*/

        //////--------------------------------------------setting vars
        let temp_e6_total = { ...e6_total }
        temp_e6_total.returned = temp_e6_returned_today

        temp_e6_total.startinv = temp_e6_receipt - temp_e6_receipt_today - temp_e6_dispensed_total + temp_e6_dispensed_today - temp_e6_returned + temp_e6_returned_today;
        temp_e6_total.receipt = temp_e6_receipt_today;
        temp_e6_total.dispensed = temp_e6_dispensed_today;
        temp_e6_total.endinv = temp_e6_receipt - temp_e6_dispensed_total - temp_e6_returned;

        set_e6_total(temp_e6_total)


        let temp_e10_total = { ...e10_total }
        temp_e10_total.returned = temp_e10_returned_today

        temp_e10_total.startinv = temp_e10_receipt - temp_e10_receipt_today - temp_e10_dispensed_total + temp_e10_dispensed_today - temp_e10_returned + temp_e10_returned_today;
        temp_e10_total.receipt = temp_e10_receipt_today;
        temp_e10_total.dispensed = temp_e10_dispensed_today;
        temp_e10_total.endinv = temp_e10_receipt - temp_e10_dispensed_total - temp_e10_returned;

        set_e10_total(temp_e10_total)


        let temp_e12_total = { ...e12_total }
        temp_e12_total.returned = temp_e12_returned_today

        temp_e12_total.startinv = temp_e12_receipt - temp_e12_receipt_today - temp_e12_dispensed_total + temp_e12_dispensed_today - temp_e12_returned + temp_e12_returned_today;
        temp_e12_total.receipt = temp_e12_receipt_today;
        temp_e12_total.dispensed = temp_e12_dispensed_today;
        temp_e12_total.endinv = temp_e12_receipt - temp_e12_dispensed_total - temp_e12_returned;

        set_e12_total(temp_e12_total)





        let temp_e15_total = { ...e15_total }
        temp_e15_total.returned = temp_e15_returned_today

        temp_e15_total.startinv = temp_e15_receipt - temp_e15_receipt_today - temp_e15_dispensed_total + temp_e15_dispensed_today - temp_e15_returned + temp_e15_returned_today
        temp_e15_total.receipt = temp_e15_receipt_today;
        temp_e15_total.dispensed = temp_e15_dispensed_today;
        temp_e15_total.endinv = temp_e15_receipt - temp_e15_dispensed_total - temp_e15_returned;

        set_e15_total(temp_e15_total)



        let temp_t25_total = { ...t25_total }
        temp_t25_total.returned = temp_t25_returned_today

        temp_t25_total.startinv = temp_t25_receipt - temp_t25_receipt_today - temp_t25_dispensed_total + temp_t25_dispensed_today - temp_t25_returned + temp_t25_returned_today
        temp_t25_total.receipt = temp_t25_receipt_today;
        temp_t25_total.dispensed = temp_t25_dispensed_today;
        temp_t25_total.endinv = temp_t25_receipt - temp_t25_dispensed_total - temp_t25_returned;

        set_t25_total(temp_t25_total)



        let temp_t37_total = { ...t37_total }
        temp_t37_total.returned = temp_t37_returned_today

        temp_t37_total.startinv = temp_t37_receipt - temp_t37_receipt_today - temp_t37_dispensed_total + temp_t37_dispensed_today - temp_t37_returned + temp_t37_returned_today;
        temp_t37_total.receipt = temp_t37_receipt_today;
        temp_t37_total.dispensed = temp_t37_dispensed_today;
        temp_t37_total.endinv = temp_t37_receipt - temp_t37_dispensed_total - temp_t37_returned

        set_t37_total(temp_t37_total)



        let temp_t50_total = { ...t50_total }
        temp_t50_total.returned = temp_t50_returned_today

        temp_t50_total.startinv = temp_t50_receipt - temp_t50_receipt_today - temp_t50_dispensed_total + temp_t50_dispensed_today - temp_t50_returned + temp_t50_returned_today;
        temp_t50_total.receipt = temp_t50_receipt_today;
        temp_t50_total.dispensed = temp_t50_dispensed_today;
        temp_t50_total.endinv = temp_t50_receipt - temp_t50_dispensed_total - temp_t50_returned

        set_t50_total(temp_t50_total)



        let temp_t87_total = { ...t87_total }
        temp_t87_total.returned = temp_t87_returned_today

        temp_t87_total.startinv = temp_t87_receipt - temp_t87_receipt_today - temp_t87_dispensed_total + temp_t87_dispensed_today - temp_t87_returned + temp_t87_returned_today;
        temp_t87_total.receipt = temp_t87_receipt_today;
        temp_t87_total.dispensed = temp_t87_dispensed_today;
        temp_t87_total.endinv = temp_t87_receipt - temp_t87_dispensed_total - temp_t87_returned

        set_t87_total(temp_t87_total)



        let temp_t100_total = { ...t100_total }
        temp_t100_total.returned = temp_t100_returned_today

        temp_t100_total.startinv = temp_t100_receipt - temp_t100_receipt_today - temp_t100_dispensed_total + temp_t100_dispensed_today - temp_t100_returned + temp_t100_returned_today;
        temp_t100_total.receipt = temp_t100_receipt_today;
        temp_t100_total.dispensed = temp_t100_dispensed_today;
        temp_t100_total.endinv = temp_t100_receipt - temp_t100_dispensed_total - temp_t100_returned

        set_t100_total(temp_t100_total)



        let temp_t200_total = { ...t200_total }
        temp_t200_total.returned = temp_t200_returned_today

        temp_t200_total.startinv = temp_t200_receipt - temp_t200_receipt_today - temp_t200_dispensed_total + temp_t200_dispensed_today - temp_t200_returned + temp_t200_returned_today
        temp_t200_total.receipt = temp_t200_receipt_today;
        temp_t200_total.dispensed = temp_t200_dispensed_today;
        temp_t200_total.endinv = temp_t200_receipt - temp_t200_dispensed_total - temp_t200_returned//temp_t200_receipt - temp_t200_dispensed_total - temp_t100_returned

        set_t200_total(temp_t200_total)





/*
        for (i = 0; i < filtered_dose?.length; i++) {
            if ((filtered_dose[i].dose_size === '10') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_e10_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_e10_inventory += parseFloat(filtered_dose[i].dose_qty);
            }
        }
        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                for (j = 0; j < inputedDoses?.length; j++) {
                    let temp = inputedDoses[j]
                    for (k = 0; k < temp?.length; k++) {
                        if (temp[k].dose_size_id === '1') {
                            temp_e10_returned += parseFloat(temp[k].dose_qty)
                        }
                    }
                }
            }
        }

        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[0]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '1') {
                        temp_e10_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        let temp_e10_total = { ...e10_total }
        temp_e10_total.returned = temp_e10_returned;
        temp_e10_total.receipt = temp_e10_receipt;
        temp_e10_total.dispensed = temp_e10_dispense;
        temp_e10_total.inventory = temp_e10_receipt - temp_e10_dispense - temp_e10_returned;
        set_e10_total(temp_e10_total)

        for (i = 0; i < filtered_dose?.length; i++) {
            if ((filtered_dose[i].dose_size === '12.5') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_e12_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_e12_inventory += parseFloat(filtered_dose[i].dose_qty);
            }
        }
        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                let temp = inputedDoses[0]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '2') {
                        temp_e12_returned += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[0]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '2') {
                        temp_e12_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        let temp_e12_total = { ...e12_total }
        temp_e12_total.returned = temp_e12_returned;
        temp_e12_total.receipt = temp_e12_receipt;
        temp_e12_total.dispensed = temp_e12_dispense;
        temp_e12_total.inventory = temp_e12_receipt - temp_e12_dispense - temp_e12_returned;
        set_e12_total(temp_e12_total)

        for (i = 0; i < filtered_dose?.length; i++) {
            if ((filtered_dose[i].dose_size === '15') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_e15_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_e15_inventory += parseFloat(filtered_dose[i].dose_qty);
            }
        }
        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                let temp = inputedDoses[0]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '3') {
                        temp_e15_returned += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[0]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '3') {
                        temp_e15_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        let temp_e15_total = { ...e15_total }
        temp_e15_total.returned = temp_e15_returned;
        temp_e15_total.receipt = temp_e15_receipt;
        temp_e15_total.dispensed = temp_e15_dispense;
        temp_e15_total.inventory = temp_e15_receipt - temp_e15_dispense - temp_e15_returned;
        set_e15_total(temp_e15_total)

        for (i = 0; i < filtered_dose?.length; i++) {
            if ((filtered_dose[i].dose_size === '25') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_t25_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_t25_inventory += parseFloat(filtered_dose[i].dose_qty);
            }
        }
        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '0') {
                        temp_t25_returned += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '0') {
                        temp_t25_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        let temp_t25_total = { ...t25_total }
        temp_t25_total.returned = temp_t25_returned;
        temp_t25_total.receipt = temp_t25_receipt;
        temp_t25_total.dispensed = temp_t25_dispense;
        temp_t25_total.inventory = temp_t25_receipt - temp_t25_dispense - temp_t25_returned;
        set_t25_total(temp_t25_total)

        for (i = 0; i < filtered_dose?.length; i++) {
            if ((filtered_dose[i].dose_size === '37.5') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_t37_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_t37_inventory += parseFloat(filtered_dose[i].dose_qty);
            }
        }
        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '1') {
                        temp_t37_returned += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '1') {
                        temp_t37_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        let temp_t37_total = { ...t37_total }
        temp_t37_total.returned = temp_t37_returned;
        temp_t37_total.receipt = temp_t37_receipt;
        temp_t37_total.dispensed = temp_t37_dispense;
        temp_t37_total.inventory = temp_t37_receipt - temp_t37_dispense - temp_t37_returned;
        set_t37_total(temp_t37_total)

        for (i = 0; i < filtered_dose?.length; i++) {
            if ((filtered_dose[i].dose_size === '50') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_t50_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_t50_inventory += parseFloat(filtered_dose[i].dose_qty);
            }
        }
        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '2') {
                        temp_t50_returned += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '2') {
                        temp_t50_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        let temp_t50_total = { ...t50_total }
        temp_t50_total.returned = temp_t50_returned;
        temp_t50_total.receipt = temp_t50_receipt;
        temp_t50_total.dispensed = temp_t50_dispense;
        temp_t50_total.inventory = temp_t50_receipt - temp_t50_dispense - temp_t50_returned;
        set_t50_total(temp_t50_total)

        for (i = 0; i < filtered_dose?.length; i++) {
            if ((filtered_dose[i].dose_size === '87.5') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_t87_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_t87_inventory += parseFloat(filtered_dose[i].dose_qty);
            }
        }
        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '3') {
                        temp_t87_returned += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '3') {
                        temp_t87_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        let temp_t87_total = { ...t87_total }
        temp_t87_total.returned = temp_t87_returned;
        temp_t87_total.receipt = temp_t87_receipt;
        temp_t87_total.dispensed = temp_t87_dispense;
        temp_t87_total.inventory = temp_t87_receipt - temp_t87_dispense - temp_t87_returned;
        set_t87_total(temp_t87_total)

        for (i = 0; i < filtered_dose?.length; i++) {
            if ((filtered_dose[i].dose_size === '100') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_t100_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_t100_inventory += parseFloat(filtered_dose[i].dose_qty);
            }
        }
        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '4') {
                        temp_t200_returned += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '4') {
                        temp_t100_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        let temp_t100_total = { ...t100_total }
        temp_t100_total.returned = temp_t100_returned;
        temp_t100_total.receipt = temp_t100_receipt;
        temp_t100_total.dispensed = temp_t100_dispense;
        temp_t100_total.inventory = temp_t100_receipt - temp_t100_dispense - temp_t100_returned;
        set_t100_total(temp_t100_total)

        for (i = 0; i < filtered_dose?.length; i++) {
            if ((filtered_dose[i].dose_size === '200') && (filtered_dose[i].pellet_date <= last.date) && (filtered_dose[i].pellet_date >= first.date)) {
                temp_t200_receipt += parseFloat(filtered_dose[i].dose_total);
                temp_t200_inventory += parseFloat(filtered_dose[i].dose_qty);
            }
        }
        for (i = 0; i < filtered_inv?.length; i++) {
            if ((filtered_inv[i].inv_date <= last.date) && (filtered_inv[i].inv_date >= first.date)) {
                let inputedDoses = filtered_inv[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '5') {
                        temp_t200_returned += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        for (i = 0; i < filtered_patient_dispence?.length; i++) {
            if ((filtered_patient_dispence[i].dispense_date <= last.date) && (filtered_patient_dispence[i].dispense_date >= first.date)) {
                let inputedDoses = filtered_patient_dispence[i].inputedDoses;
                let temp = inputedDoses[1]

                for (k = 0; k < temp?.length; k++) {
                    if (temp[k].dose_size_id === '5') {
                        temp_t200_dispense += parseFloat(temp[k].dose_qty)
                    }
                }

            }
        }
        let temp_t200_total = { ...t200_total }
        temp_t200_total.returned = temp_t200_returned;
        temp_t200_total.receipt = temp_t200_receipt;
        temp_t200_total.dispensed = temp_t200_dispense;
        temp_t200_total.inventory = temp_t200_receipt - temp_t200_dispense - temp_t200_returned;
        set_t200_total(temp_t200_total)

        */

    }, [first, last, filtered_inv, filtered_dose, filtered_patient_dispence, set_e6_total, set_e10_total, set_e12_total, set_e15_total, set_t25_total, set_t37_total, set_t50_total, set_t87_total, set_t100_total, set_t200_total])


    return (
        <>
            <Exporter tBodyRef={tBodyRef} setPushed={setPushed} setCStep={setCStep}  currentTable={'tablert'} emptyBox={'emptyBox'} title={title} ptitle={ptitle} currentuser={user.userType} practiceHere={'practiceHere'} currentpractice={practice}/>
            <StyledContainer>
                <FilterBar pushed={pushed} setPushed={setPushed} check={check} set_check={set_check} fdate={fdate} set_fdate={set_fdate}
                    first={first} set_first={set_first} last={last} set_last={set_last}
                />
                <Wrapper flex='1' position='relative' overflow='auto'>
                    <Background />
                    <Box {...{ ref: tBodyRef }} padding='20px'>
                        <Box fontSize='20px' fontWeight='600'>Month To Date Report</Box>

                        <br /> <br />
                        {user.userType == "Practice" ?
                            (
                               
                                <Box id="practiceHere">
                                    <Box fontSize='16px' fontWeight='600' >  Biosana ID#:  <span style={{ color: '#666666', fontStyle: 'italic' }}> {userTypes.biosana_id} </span> </Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practice Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practice_name} </span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practioner Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practioner_name}</span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  DEA#:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.dea_no} </span> </Box>
                                </Box>
                            )
                            : null}
                        <br />

                        {user.userType == "Administrator" ?
                            (
                                // show practice dropdown
                                <Box display='flex' alignItems='center' mt='15px' style={{
                                    display: "inline-block", float: "right", "margin-top": "-100px"
                                }}>
                                    <Box width='100px' float="right">Practice:</Box>
                                    <select onChange={(e) => {
                                        // handle practice change
                                        var sval=e.target.value.split("/")
                                        setpractice(e.target.value);
                                        var new_title='Practice: '+sval[0];
                                        setPTitle(new_title);
                                    }}>
                                        <option selected value="All">All</option>
                                        {
                                            //practiceList.map((u) => <option value={u.user_name} key={u.UsernameKey}>{u.user_name}</option>)
                                            //new
                                            // practiceList.map((u) => <option value={u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'+'/'+u.user_name} key={u.UsernameKey}>{u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                            practiceList.map((u) => <option value={u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'+'/'+u.user_name+'/'+u.biosana_id} key={u.UsernameKey}>{u.biosana_id+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                        }
                                    </select>
                                </Box>
                            )
                            : null}
                        <br />


                        <Box width='100%' boxShadow='2px 2px 5px 1px #ccc' mt={3} overflow='auto'>
                        <Box id='emptyBox'></Box>
                            <Table width='100%' component='table' id='tablert'>
                                <THead component='thead'>
                                    <tr>
                                        <th>Pellet Type</th>
                                        <th>First Day of Mth</th>
                                        <th>Last Day of Mth</th>
                                         
                                        <th>Starting Inventory</th>
                                        <th>Total Receipts</th>
                                        <th>Total Pharmacy Returned</th>
                                        <th>Total Dispensed</th>
                                        <th>Ending Inventory</th>
                                    </tr>
                                </THead>
                                <TBody component='tbody'>
                                    {check[0] && <tr>
                                        <td>E6</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                       
                                        <td>{e6_total.startinv}</td>
                                        <td>{e6_total.receipt}</td>
                                        <td>{e6_total.returned}</td>
                                        <td>{e6_total.dispensed}</td>
                                        <td>{e6_total.endinv}</td>
                                    </tr>}

                                    {check[1] && <tr>
                                        <td>E10</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                        <td>{e10_total.startinv}</td>
                                        <td>{e10_total.receipt}</td>
                                        <td>{e10_total.returned}</td>
                                        <td>{e10_total.dispensed}</td>
                                        <td>{e10_total.endinv}</td>
                                    </tr>}
                                    {check[2] && <tr>
                                        <td>E12.5</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                        <td>{e12_total.startinv}</td>
                                        <td>{e12_total.receipt}</td>
                                        <td>{e12_total.returned}</td>
                                        <td>{e12_total.dispensed}</td>
                                        <td>{e12_total.endinv}</td>
                                    </tr>}
                                    {check[3] && <tr>
                                        <td>E15</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                        <td>{e15_total.startinv}</td>
                                        <td>{e15_total.receipt}</td>
                                        <td>{e15_total.returned}</td>
                                        <td>{e15_total.dispensed}</td>
                                        <td>{e15_total.endinv}</td>
                                    </tr>}
                                    {check[4] && <tr>
                                        <td>T25</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                        <td>{t25_total.startinv}</td>
                                        <td>{t25_total.receipt}</td>
                                        <td>{t25_total.returned}</td>
                                        <td>{t25_total.dispensed}</td>
                                        <td>{t25_total.endinv}</td>
                                    </tr>}
                                    {check[5] && <tr>
                                        <td>T37.5</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                        <td>{t37_total.startinv}</td>
                                        <td>{t37_total.receipt}</td>
                                        <td>{t37_total.returned}</td>
                                        <td>{t37_total.dispensed}</td>
                                        <td>{t37_total.endinv}</td>
                                    </tr>}
                                    {check[6] && <tr>
                                        <td>T50</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                        <td>{t50_total.startinv}</td>
                                        <td>{t50_total.receipt}</td>
                                        <td>{t50_total.returned}</td>
                                        <td>{t50_total.dispensed}</td>
                                        <td>{t50_total.endinv}</td>
                                    </tr>}
                                    {check[7] && <tr>
                                        <td>T87.5</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                        <td>{t87_total.startinv}</td>
                                        <td>{t87_total.receipt}</td>
                                        <td>{t87_total.returned}</td>
                                        <td>{t87_total.dispensed}</td>
                                        <td>{t87_total.endinv}</td>
                                    </tr>}
                                    {check[8] && <tr>
                                        <td>T100</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                        <td>{t100_total.startinv}</td>
                                        <td>{t100_total.receipt}</td>
                                        <td>{t100_total.returned}</td>
                                        <td>{t100_total.dispensed}</td>
                                        <td>{t100_total.endinv}</td>
                                    </tr>}
                                    {check[9] && <tr>
                                        <td>T200</td>
                                        <td>{first.date}</td>
                                        <td>{last.date}</td>
                                        <td>{t200_total.startinv}</td>
                                        <td>{t200_total.receipt}</td>
                                        <td>{t200_total.returned}</td>
                                        <td>{t200_total.dispensed}</td>
                                        <td>{t200_total.endinv}</td>
                                    </tr>}
                                </TBody>
                            </Table>
                        </Box>

                    </Box>
                    <TFoot mt='20px' textAlign='right' justifyContent="center" display="flex" mb='20px'>
                        <PrevButton component='button' mr={2} onClick={() => set_prevday()}>{'< Prev Mth'}</PrevButton>
                        <PrevButton component='button' mr={2} onClick={() => set_today()}>{'Present Mth'}</PrevButton>
                        <NextButton component='button' mr={2} onClick={() => set_nextday()}>{'Next Mth >'}</NextButton>
                    </TFoot>
                </Wrapper>
            </StyledContainer>
        </>)
}

const Background = styled(Box)`
    position: absolute;
    width: 100%; height: 100%;
    background: url(${BackImg});
    background-size: 100% auto;
    background-position-y: bottom;
    background-repeat: no-repeat;
    opacity: .15;
    user-select: none;
    pointer-events: none;
`

const THead = styled(Box)``

const TBody = styled(Box)``

const Table = styled(Box)`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: le;
        background-color: white;
        font-weight: 600;
    }
    tbody tr {
        transition: .3s;
        background-color: white;
        border-top: 1px solid lightgrey;
    }
    tr:hover {background-color: #eee;}
    th, td {
        padding: 8px;
    }
`
const Wrapper = styled(Box)`
    // background: url(${BackImg});
    // background-size: 100% 100%;
`

const StyledContainer = styled(Box)`
    display: flex;
    flex: 1;
`
const TFoot = styled(Box)``

const PrevButton = styled(Box)`
    &:hover{
        background: #002751;
    }
    border: none; outline: none;
    background: #0e60ad;
    color: white;
    width: 130px; height: 50px;
    border-radius:5px;

`

const NextButton = styled(Box)`
&:hover{
    background: #002751;
}
    border: none; outline: none;
    background: #0e60ad;
    color: white;
    width: 130px; height: 50px;
    border-radius:5px;
`


export default MTDR