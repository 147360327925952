exports.LOGIN = 'login'
// exports.SIGNUP = 'signup'
exports.FORGOT = 'forgot'
exports.RESET = 'reset'
exports.VERIFY = 'verify'
exports.DASHBOARD = ''
exports.USERS = 'user_management'
exports.INVADJUST = 'inv_adjustment'
exports.RECEIPTADD = 'receipt_add'
exports.RECEIPTEDIT = 'receipt_edit'
exports.DISPENSEADD = 'dispense_add'
exports.DISPENSEEDIT = 'dispense_edit'
exports.MTDREPORT = 'mtd_report'
exports.DDREPORT = 'dd_report'
exports.LOOKUPDETAIL = 'lookup_detail'
exports.PROCTRACKER = 'proc_tracker'
exports.RESCHEDULE = 'reschedule'
exports.LOTREVIEW = 'lot_review'
exports.INVADJUSTDEL = 'inv_adjustment_deleted'
exports.RECEIPTDELETE = 'receipt_delete'
exports.DISPENSEDELETE = 'dispense_delete'
exports.LOOKUPDETAIL2 = 'lookup_detail2'