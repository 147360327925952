import { Box } from '@material-ui/core'
import styled from 'styled-components'
import _ from 'lodash'

const LotDrops = ({ doseIndex, contentRef, data, handleCopyLots,practice }) => {
    if(practice !='')
    {
        data=data.filter(u => u.PelletReceiptInfo[0]?.practice_id == practice)
    }
    return (
        <StyledContainer position='absolute' width='100%' top='100%' left='0' overflow='auto' bgcolor='white' boxShadow='1px 1px 1px 1px grey'
            ref={contentRef}
        >
            {_.map(data, (each, i) =>
                <FilterItem key={i} display='flex' justifyContent='space-between' alignItems='center' padding='10px'
                    onClick={() => handleCopyLots(doseIndex, each.dose_qty, each.dose_lot_number, each.pellet_exp_date,each._id)}
                >
                    <Box>{each.dose_lot_number}</Box>
                </FilterItem>
            )}
        </StyledContainer>
    )
}

const FilterItem = styled(Box)`
    cursor: pointer;
    transition: .3s;
    &:hover {
        background-color: aliceblue;
    }
`

const StyledContainer = styled(Box)`
    z-index: 99;
`

export default LotDrops