import { PELLET_CRUD, TOAST,PELLET_DEL_CRUD } from './types'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import * as PAGES from '../../constants/pages'

export function doGetWholeReceipts(usName) {
    return (dispatch) => {
        let user = JSON.parse(localStorage.getItem('uid'))

        axios.get(`/api/pelletController/doGetWholeReceipts/${user?.userType}/${user?.userName}`)
            .then((res) => {
                dispatch({
                    type: PELLET_CRUD.READ,
                    receipts: res.data
                })
            })
    }
}

export function doAddNewReceipt(formData, history, isModal,allPellets) {
    //console.log(formData, history, isModal,allPellets);
    formData['allPellets']=allPellets;
    return (dispatch) => {
        axios.post('/api/pelletController/doAddNewReceipt', formData)
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: PELLET_CRUD.CREATE,
                        receipts: res.data.receipts
                    })
                    let logData = JSON.parse(localStorage.getItem("logData") || "[]")
                    logData.push({
                        'userName': localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken')).userName,
                        'actionShort': 'Created',
                        'action': 'created an pellet',
                        'actionDate': new Date()
                    })
                    localStorage.setItem('logData', JSON.stringify(logData))
                    history.push(`/${PAGES.RECEIPTEDIT}`)
                }
                //else alert('error occur')
                //new
                else alert(res.data.error)
            })
    }
}

export function doDeleteReceipts(id) {
    let user = JSON.parse(localStorage.getItem('uid'))
    return (dispatch) => {
        axios.post('/api/pelletController/doDeleteReceipt', { id: id,deleted_by:user.id })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: PELLET_CRUD.DELETE,
                        id: id,
                    })
                    dispatch({ type: TOAST, flag: true, toastContent: 'Delete success' })
                    window.location.reload();
                    let logData = JSON.parse(localStorage.getItem("logData") || "[]")
                    logData.push({
                        'userName': localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken')).userName,
                        'actionShort': 'Deleted',
                        'action': 'deleted an pellet',
                        'actionDate': new Date()
                    })
                    localStorage.setItem('logData', JSON.stringify(logData))
                }
                // else alert('error occur')
                //new
                else alert(res.data.error)
            })
    }
}

export function doUpdateReceipt(formData) {
    return (dispatch) => {
        axios.post('/api/pelletController/doUpdateReceipt', formData)
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: PELLET_CRUD.UPDATE,
                        updatedData: formData,
                    })
                    dispatch({ type: TOAST, flag: true, toastContent: 'Update success' })

                    let logData = JSON.parse(localStorage.getItem("logData") || "[]")
                    logData.push({
                        'userName': localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken')).userName,
                        'actionShort': 'Updated',
                        'action': 'updated an pellet',
                        'actionDate': new Date()
                    })
                    localStorage.setItem('logData', JSON.stringify(logData))
                }
                //else alert('error occur')
                //new
                else alert(res.data.error)
            })
    }
}

export function doSetPharmacy(formData) {
    return (dispatch) => {
        axios.post('/api/pelletController/doSetPharmacy', formData)
            .then((res) => {
            })
    }
}

export function doGetWholeReceiptsDel(usName) {
    return (dispatch) => {
        let user = JSON.parse(localStorage.getItem('uid'))

        axios.get(`/api/pelletController/doGetWholeReceiptsDeleted/${user?.userType}/${user?.userName}`)
            .then((res) => {
                dispatch({
                    type: PELLET_DEL_CRUD.READ,
                    receipts_deleted: res.data.receipts_deleted
                })
            })
    }
}