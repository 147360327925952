import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    html, body, #root {
        height: 100%;
    }
    * {
        font-family: Poppins;
    }
    body {
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-corner {
            background: inherit;
        }
        &::-webkit-scrollbar-thumb {
            width: 30px;
            border-radius: 15px;
            background-clip: padding-box;
            border: 0px solid transparent;
            box-shadow: inset 0 0 0 10px;
        }
    }
`;

export default GlobalStyle;