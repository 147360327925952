import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import _ from 'lodash'

import { LotDrops } from 'src/components/Dropdowns'

import { doseTypes } from 'src/entry'
import { useOutsideDetectorForLot } from 'src/hooks/useOutsideDetector'

const FormBodyInv = ({
    i, j, each,
    showError,
    quantity, setQuantity,
    lotSharp, setLotSharp,
    expDates, setExpDates,
    overflowQty, setOverflowQty,
    sizeID, setSizeID,
    inputValid, setInputValid,
    doseID,setDoseID,
    practice,
    lotSharpQuantity,setlotSharpQuantity
}) => {
   // const filteredDoses = useSelector(state => state.PatientReducer.filteredDoses).filter(d => d.PelletReceiptInfo[0].created_by == practice)
   //new
   console.log(practice);
    const filteredDoses = useSelector(state => state.PatientReducer.filteredDoses);//.filter(d => d.PelletReceiptInfo[0]?.created_by == practice)
    const [lotFilterOpen, setLotFilterOpen] = useState(-1)
    const lotTitleRef = useRef()
    const lotContentRef = useRef()

    useOutsideDetectorForLot(lotTitleRef, lotContentRef, setLotFilterOpen)
    useEffect(() => {
    })

    const handleSizeID = (e) => {
        setSizeID(prev => { const tmp = [...prev]; tmp[i][j] = e.target.value; return tmp })
        setLotSharp(prev => { const tmp = [...prev]; tmp[i][j] = ''; return tmp })
        setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = 0; return tmp })
        setInputValid(prev => { const tmp = [...prev]; tmp[i][j] = true; return tmp })//setInputValid(prev => { const tmp = [...prev]; tmp[i][j] = false; return tmp })
        setlotSharpQuantity(prev => { const tmp = [...prev]; tmp[i][j] = ''; return tmp })
        setDoseID(prev => { const tmp = [...prev]; tmp[i][j] = ''; return tmp })
    }
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    function containsAny(str, substrings) {
        for (var i = 0; i != substrings.length; i++) {
           var substring = substrings[i];
           if (str.indexOf(substring) != - 1) {
             return substring;
           }
        }
        return null; 
    }
    const handleQtyInput = (e) => {
        setQuantity(prev => { const tmp = [...prev]; tmp[i][j] = e.target.value; return tmp })
        let if_qty_correct=containsAny(e.target.value, exceptThisSymbols)
        setInputValid(prev => {
            const tmp = [...prev]
            if (e.target.value === '' || lotSharp === '' || parseFloat(e.target.value) > parseFloat(overflowQty) || if_qty_correct !==null)
                tmp[i][j] = false
            else
                tmp[i][j] = true
            return tmp
        })
    }

    const handleCopyLots = (doseIndex, qty, lot, exp,cdoseID) => {
        setLotSharp(prev => { const tmp = [...prev]; tmp[i][j] = lot; return tmp })
        setExpDates(prev => { const tmp = [...prev]; tmp[i][j] = exp; return tmp })
        //setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot)[0].dose_qty}`; return tmp })
        setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot && e.dose_qty === qty)[0].dose_qty}`; return tmp })
        setlotSharpQuantity(prev => { const tmp = [...prev]; tmp[i][j] = qty; return tmp })
        setDoseID(prev => { const tmp = [...prev]; tmp[i][j] = cdoseID; return tmp })
        let if_qty_correct=containsAny(quantity, exceptThisSymbols)
        setInputValid(prev => {
            const tmp = [...prev]
            if (quantity === '' || lot === '' || parseFloat(quantity) > parseFloat(_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot)[0].dose_qty) || if_qty_correct !==null)
                tmp[i][j] = false
            else
                tmp[i][j] = true
            return tmp
        })
        setLotFilterOpen(false)
    }

    return (
        <StyledContainer>
            <Box display='flex' flexWrap='wrap' alignItems='center'>
                <ComboBox width='120px'>
                    <Box component='select' width='100%'
                        value={sizeID} onChange={handleSizeID}
                    >
                        {_.map(each.sizes, (each, mgIndex) =>
                            <option key={mgIndex} value={mgIndex}>{each} mg</option>
                        )}
                    </Box>
                </ComboBox>
                <Box width='200px' ml='30px'>
                    <Box component='input' type='number' name={i} value={quantity} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={handleQtyInput} />
                </Box>
                <Box ml='50px' position='relative' {...{ ref: lotTitleRef }}>
                    <LotComboTrigger width='180px' border='1.5px solid lightgrey' padding='5px' borderRadius='5px' onClick={() => _.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString()).length > 0 && setLotFilterOpen(i)}>
                        {lotSharp === '' ? 'None' : lotSharp}
                    </LotComboTrigger>
                    {(lotFilterOpen === i && _.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString()).length > 0) &&
                        <LotDrops
                            doseIndex={i}
                            contentRef={lotContentRef}
                            data={_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString())}
                            practice={practice}
                            handleCopyLots={handleCopyLots}
                        />
                    }
                </Box>
                <Box width='200px' ml='50px' mr='50px' component='input' disabled {...{ type: 'date' }} name={i} value={expDates} max="9999-12-31" />
                <Alert severity='warning'>{overflowQty ? `${overflowQty} left` : 'None'}</Alert>
                <Box ml='10px'>
                    {(showError && inputValid === false) && <Alert severity='error'>Error</Alert>}
                </Box>
            </Box>
        </StyledContainer>
    )
}

const LotComboTrigger = styled(Box)`
    cursor: pointer;
`

const ComboBox = styled(Box)`
    padding-right: 8px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    @media (max-width: 1040px) {
        width: 100% !important;
    }
    &:focus {
        border-color: red;
    }
`

const StyledContainer = styled(Box)`
    >div {
        @media (max-width: 1040px) {
            >div+div {
                margin-top: 15px;
            }
        }
        >div {
            @media (max-width: 1040px) {
                margin-left: unset;
                width: 100%;
                >input {
                    width: 100%;
                }
            }
        }
    }
    >div >input, >div >div:last-of-type {
        @media (max-width: 1040px) {
            margin-left: unset;
            margin-right: unset;
            margin-top: 15px;
            width: 100%;
        }
    }
    /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }
`

export default FormBodyInv