import { INV_DEL_CRUD } from 'src/redux/actions/types'
import _ from 'lodash'

const initState = {
    inventories_deleted: [],
}

export default function todo(state = initState, action) {
    let tmp
    switch (action.type) {
        case INV_DEL_CRUD.CREATE:
            return {
                ...state,
                inventories_deleted: action.inventories_deleted,
            }
        case INV_DEL_CRUD.READ:
            return {
                ...state,
                inventories_deleted: action.inventories_deleted
            }
        case INV_DEL_CRUD.UPDATE:
            const inventoryData = action.updatedData.invData
            tmp = [...state.inventories_deleted]
            inventoryData._id = action.updatedData.where
            tmp[_.findIndex(tmp, item => item._id === action.updatedData.where)] = action.updatedData.invData
            return {
                ...state,
                inventories_deleted: tmp,
            }
        case INV_DEL_CRUD.DELETE:
            let invData = [...state.inventories_deleted]
            invData.splice(_.findIndex(e => e._id === action.id), 1)
            return {
                ...state,
                inventories_deleted: invData
            }
        default:
            return state
    }
}