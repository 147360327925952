import { Box } from '@material-ui/core'
import styled from 'styled-components'

const SearchInput = ({ keyword, setKeyword }) => {
    return (
        <StyledContainer>
            <input type='text' placeholder='Seach...' onChange={(e) => setKeyword(e.target.value)}/>
        </StyledContainer>
    )
}

const StyledContainer = styled(Box)`
    min-width: 150px;
    width: fit-content;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    >input {
        width: 100%;
        border: none;
        outline: none;
    }
`

export default SearchInput