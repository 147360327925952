import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { EditOutlined, DeleteOutlined } from '@material-ui/icons'

import NewIcon from 'src/assets/images/dispense/new-badge.png'

const DispenseRow = ({ id, eachInfo, procedureName, setOpen, setOpenMsg, setIndex, setJIndex,setCurrentdDispense }) => {
    return (
        <StyledContainer component='tr'>
        
            <td>{id+1}</td>
            <td>{procedureName}</td>
            <td>{eachInfo.patient_first_name}</td>
            <td>{eachInfo.patient_last_name}</td>
            <td>{eachInfo.dispense_date.slice(0, 10)}</td>
            <td>{eachInfo.patient_phone_number ? eachInfo.patient_phone_number : ''}</td>
            <td>{eachInfo.patient_dob}</td>
            {/* <td>{eachInfo.practice_name}</td> */}
            <td>{eachInfo.practice_name_new}</td>
            <td>{eachInfo.is_new &&
                <img src={NewIcon} alt='' width='40px' />
            }</td>
            <td>
                <EditButton onClick={() => {
                    setCurrentdDispense(eachInfo)
                    setIndex(id)
                    setJIndex(eachInfo._id)
                    setOpen(true)
                }} />
            </td>
            <td>
                <DeleteButton onClick={() => {
                    setJIndex(eachInfo._id)
                    setOpenMsg(true)
                }} />
            </td>
        </StyledContainer>
    )
}

const EditButton = styled(EditOutlined)`
    cursor: pointer;
    opacity: .5;
    &:hover {
        transition: .3s;
        opacity: 1;
    }
`

const DeleteButton = styled(DeleteOutlined)`
    cursor: pointer;
    opacity: .5;
    &:hover {
        transition: .3s;
        opacity: 1;
    }
`

const StyledContainer = styled(Box)`
`

export default DispenseRow