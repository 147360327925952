import { REPORT_CRUD } from 'src/redux/actions/types'

const initState = {
    report_MTD: [],
    patient:[],
    address:[]
}

export default function todo(state = initState, action) {

    switch (action.type) {
        // case INV_CRUD.CREATE:
        //     return {
        //         ...state,
        //         inventories: action.inventories,
        //     }
        case REPORT_CRUD.READ:
            return {
                ...state,
                report_MTD: action.report_MTD,
                patient: action.patient,
                address: action.address
            }
        // case INV_CRUD.DELETE:
        //     let invData = [...state.inventories]
        //     invData.splice(_.findIndex(e => e._id === action.id), 1)
        //     return {
        //         ...state,
        //         inventories: invData
        //     }
        default:
            return state
    }
}