import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import _ from 'lodash'

import { LotDrops } from 'src/components/Dropdowns'

import { doseTypes } from 'src/entry'
import { useOutsideDetectorForLot } from 'src/hooks/useOutsideDetector'
import jwtDecode from 'jwt-decode'

const FormBody = ({
    i, j, each,
    showError,
    quantity, setQuantity,
    lotSharp, setLotSharp,
    expDates, setExpDates,
    overflowQty, setOverflowQty,
    sizeID, setSizeID,
    doseID,setDoseID,
    inputValid, setInputValid,
    oldQuantity,setoldQuantity,
    isEdit,setisEdit,
    lotSharpQuantity,setlotSharpQuantity,
    practice,
}) => {
    //console.log(practice,'practice');
     const filteredDoses = useSelector(state => state.PatientReducer.filteredDoses)//.filter(d=>d.PelletReceiptInfo[0].created_by==practice)
    const [lotFilterOpen, setLotFilterOpen] = useState(-1)
    const lotTitleRef = useRef()
    const lotContentRef = useRef()
 
    const user = localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken'))
    useOutsideDetectorForLot(lotTitleRef, lotContentRef, setLotFilterOpen)
    useEffect(() => {
    })

    const handleSizeID = (e) => {
        setSizeID(prev => { const tmp = [...prev]; tmp[i][j] = e.target.value; return tmp })
        setLotSharp(prev => { const tmp = [...prev]; tmp[i][j] = ''; return tmp })
        setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = 0; return tmp })
        setInputValid(prev => { const tmp = [...prev]; tmp[i][j] = false; return tmp })//setInputValid(prev => { const tmp = [...prev]; tmp[i][j] = false; return tmp })
        setlotSharpQuantity(prev => { const tmp = [...prev]; tmp[i][j] = ''; return tmp })
        setDoseID(prev => { const tmp = [...prev]; tmp[i][j] = ''; return tmp })
    }
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    function containsAny(str, substrings) {
        for (var i = 0; i != substrings.length; i++) {
           var substring = substrings[i];
           if (str.indexOf(substring) != - 1) {
             return substring;
           }
        }
        return null; 
    }
    const handleQtyInput = (e) => {
        setQuantity(prev => { const tmp = [...prev]; tmp[i][j] = e.target.value; return tmp })
        //console.log(lotSharp);
        //console.log(oldQuantity);
        console.log(lotSharpQuantity);
        if(lotSharp !== '')
        {
            //add case
            if (e.target.value === '')
                {
                    // setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty}`;  return tmp })
                    setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp && e.dose_qty === lotSharpQuantity)[0].dose_qty}`;  return tmp })
    
                }else{
                    //setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty}`- parseFloat(e.target.value); /*console.log(_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty);*/ return tmp })
                    setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp && e.dose_qty === lotSharpQuantity)[0].dose_qty}`- parseFloat(e.target.value); return tmp })
                }
            // if(typeof oldQuantity==undefined)
            // {
            //     if (e.target.value === '')
            //     {
            //         setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty}`;  return tmp })
    
            //     }else{
            //         setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty}`- parseFloat(e.target.value); /*console.log(_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty);*/ return tmp })
            //     }

            // }else{
            //     if (e.target.value === '')
            //     {
            //         setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty}`;  return tmp })
    
            //     }else{
            //         //compare two cases
            //         if(oldQuantity==e.target.value)
            //         {
            //             setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty}`;  return tmp }) 
            //         }else{

            //             setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty}`- (parseFloat(e.target.value)-parseFloat(oldQuantity)); /*console.log(_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lotSharp)[0].dose_qty);*/ return tmp })
            //         }
            //     }
            // }

        }
        setInputValid(prev => {
            const tmp = [...prev]
            let if_qty_correct=containsAny(e.target.value, exceptThisSymbols)
           let current_date=new Date().getFullYear()+'-'+(new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1)+'-'+('0' + new Date().getDate()).slice(-2);
            //console.log(expDates,new Date().getFullYear()+'-'+(new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1)+'-'+new Date().getDate());
            // console.log(e.target.value === '',e.target.value);
            // console.log(lotSharp === '',lotSharp);
            // console.log( parseFloat(e.target.value) > parseFloat(overflowQty),overflowQty);
            if (user.userType != "Administrator") {
            if (e.target.value === '' || lotSharp === '' || parseFloat(e.target.value) > parseFloat(lotSharpQuantity) || expDates < current_date || if_qty_correct !==null)//parseFloat(e.target.value) > parseFloat(overflowQty))
                tmp[i][j] = false
            else
                tmp[i][j] = true
            return tmp
            }else{
                if (e.target.value === '' || lotSharp === '' || parseFloat(e.target.value) > parseFloat(lotSharpQuantity) || if_qty_correct !==null)//parseFloat(e.target.value) > parseFloat(overflowQty))
                    tmp[i][j] = false
                else
                    tmp[i][j] = true
                return tmp
            }
        })
    }

    const handleCopyLots = (doseIndex, qty, lot, exp,cdoseID) => {
        //console.log(lotSharpQuantity);
        setLotSharp(prev => { const tmp = [...prev]; tmp[i][j] = lot; return tmp })
        setExpDates(prev => { const tmp = [...prev]; tmp[i][j] = exp; return tmp })
        setlotSharpQuantity(prev => { const tmp = [...prev]; tmp[i][j] = qty; return tmp })
        setDoseID(prev => { const tmp = [...prev]; tmp[i][j] = cdoseID; return tmp })
        //console.log(doseIndex, qty, lot, exp,cdoseID);
        //setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot)[0].dose_qty}`; return tmp })
        //new
        if(quantity === '')
        {
            //setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot)[0].dose_qty}`; return tmp })
            setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot && e.dose_qty === qty)[0].dose_qty}`;  return tmp })

        }else{
            //setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot)[0].dose_qty}`- parseFloat(quantity); return tmp })
            setOverflowQty(prev => { const tmp = [...prev]; tmp[i][j] = `${_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot && e.dose_qty === qty)[0].dose_qty}`- parseFloat(quantity); return tmp })
        }

        let if_qty_correct=containsAny(quantity, exceptThisSymbols)
        setInputValid(prev => {
            //console.log(parseFloat(overflowQty));
            var expdate='';
            const tmp = [...prev]
           let current_date=new Date().getFullYear()+'-'+(new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1)+'-'+('0' + new Date().getDate()).slice(-2);
            console.log(expDates,new Date().getFullYear()+'-'+(new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1)+'-'+new Date().getDate());
            expdate=_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot && e.dose_qty === qty)[0].pellet_exp_date;
            //console.log(expdate,(expdate < current_date));
            // console.log(quantity === '',quantity);
            // console.log(lot === '',lot);
            // console.log(parseFloat(quantity) > parseFloat(_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot)[0].dose_qty),lot);
            // console.log(parseFloat(overflowQty) >= 0,overflowQty);
            if (user.userType != "Administrator") {
                if (quantity === '' || lot === '' || parseFloat(quantity) > parseFloat(_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot && e.dose_qty === qty)[0].dose_qty) || parseFloat(overflowQty) > 0 || expdate < current_date || if_qty_correct !==null)
                    tmp[i][j] = false
                else
                    tmp[i][j] = true
                return tmp
            }else{
                if (quantity === '' || lot === '' || parseFloat(quantity) > parseFloat(_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString() && e.dose_lot_number === lot && e.dose_qty === qty)[0].dose_qty) || parseFloat(overflowQty) > 0 || if_qty_correct !==null)
                    tmp[i][j] = false
                else
                    tmp[i][j] = true
                return tmp
            }
        })
        setLotFilterOpen(false)
    }

    return (
        <StyledContainer>
            <Box display='flex' flexWrap='wrap' alignItems='center'>
                <ComboBox width='120px'>
                    <Box component='select' width='100%'
                        value={sizeID} onChange={handleSizeID}  {...{ disabled: isEdit }}
                    >
                        {_.map(each.sizes, (each, mgIndex) =>
                            <option key={mgIndex} value={mgIndex}>{each} mg</option>
                        )}
                    </Box>
                </ComboBox>
                <Box width='200px' ml='30px'>
                    <Box component='input' type='number' name={i} value={quantity}  {...{ disabled: isEdit }}  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={handleQtyInput} />
                </Box>
                <Box ml='50px' position='relative' {...{ ref: lotTitleRef }}>
                    <LotComboTrigger  width='180px' border='1.5px solid lightgrey' padding='5px' borderRadius='5px' onClick={() => _.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString()).length > 0 && setLotFilterOpen(i)}>
                        {lotSharp === '' ? 'None' : lotSharp}
                    </LotComboTrigger>
                    {(lotFilterOpen === i && _.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString()).length > 0) && !isEdit && 
                        <LotDrops
                            doseIndex={i}
                            contentRef={lotContentRef}
                            data={_.filter(filteredDoses, e => e.dose_size === doseTypes[i].sizes[sizeID].toString())}
                            handleCopyLots={handleCopyLots}
                            practice={practice}
                        />
                    }
                </Box>
                <Box width='200px' ml='50px' mr='50px' component='input' disabled {...{ type: 'date' }} name={i} value={expDates} max="9999-12-31" />
                <Alert severity='warning'>{overflowQty ? `${overflowQty} left ,Please make sure from the quantity and lot numbers before saving. There is no editing after saving.` : 'None'}</Alert>
                <Box ml='10px'>
                    {(showError && inputValid === false) && <Alert severity='error'>Error</Alert>}
                </Box>
            </Box>
        </StyledContainer>
    )
}

const LotComboTrigger = styled(Box)`
    cursor: pointer;
`

const ComboBox = styled(Box)`
    padding-right: 8px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    @media (max-width: 1040px) {
        width: 100% !important;
    }
    &:focus {
        border-color: red;
    }
`

const StyledContainer = styled(Box)`
    >div {
        @media (max-width: 1040px) {
            >div+div {
                margin-top: 15px;
            }
        }
        >div {
            @media (max-width: 1040px) {
                margin-left: unset;
                width: 100%;
                >input {
                    width: 100%;
                }
            }
        }
    }
    >div >input, >div >div:last-of-type {
        @media (max-width: 1040px) {
            margin-left: unset;
            margin-right: unset;
            margin-top: 15px;
            width: 100%;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
`

export default FormBody