import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Modal, Fade, Backdrop } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import _ from 'lodash'

import { InputDrops } from 'src/components/Dropdowns'
import FormBody from 'src/pages/Patient/FormBody'

import { doGetWholeDoses, doUpdateDispense } from 'src/redux/actions/PatientAction'
import { doseTypes, multiplier } from 'src/entry'
import { useOutsideDetector } from 'src/hooks/useOutsideDetector'
// import jwtDecode from 'jwt-decode'

const DispenseEditModal = ({ open, setOpen, where, dispense }) => {
    const dispatch = useDispatch()
    const procedures = useSelector(state => state.PatientReducer.procedures)
    const filteredDispense = useSelector(state => state.PatientReducer.filteredDispense)
    const doses = useSelector(state => state.PatientReducer.doses)
    // const user = localStorage.getItem('jwtToken')

    const [info, setInfo] = useState({
        'dispense_date': '',
        'patient_first_name': '',
        'patient_last_name': '',
        'patient_phone_number': '',
        'practice_name': '',
        'patient_dob': '',
        'is_new': true,
        'address': '',
        'procedure_id': 0,
        'original_qty': '',
    })

    const [showError, setShowError] = useState(false)
    const [quantity, setQuantity] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    // old quantity
    const [oldQuantity, setOldquantity] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))

    const [lotSharp, setLotSharp] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [expDates, setExpDates] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [sizeID, setSizeID] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '0')))
    const [inputValid, setInputValid] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => undefined)))
    const [overflowQty, setOverflowQty] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => 0)))

    const [validFlags, setValidFlags] = useState([true, true, true, true])
    const [nameFilterOpen, setNameFilterOpen] = useState(false)
    const [filterName, setFilterName] = useState('')
    const [isEdit, setisEdit] = useState(true)
    const nameTitleRef = useRef()
    const nameContentRef = useRef()

    const handleCopyNames = (e, each) => {
        let selectedDispense = filteredDispense[_.findIndex(filteredDispense, e => e._id === each._id)]
        setInfo(prev => {
            const tmp = { ...prev };
            tmp.address = selectedDispense.addressInfo.length > 0 ? `${selectedDispense.addressInfo[0].city} ${selectedDispense.addressInfo[0].street} ${selectedDispense.addressInfo[0].state} ${selectedDispense.addressInfo[0].zip}` : ''
            tmp.patient_first_name = selectedDispense.patient_first_name
            tmp.patient_last_name = selectedDispense.patient_last_name
            tmp.practice_name = selectedDispense.practice_name
            
            //tmp.address = selectedDispense.addressInfo.length > 0 ?
            //  `${selectedDispense.addressInfo[0].city ? selectedDispense.addressInfo[0].city : ''}/${selectedDispense.addressInfo[0].street ? selectedDispense.addressInfo[0].street : ''}/${selectedDispense.addressInfo[0].state ? selectedDispense.addressInfo[0].state : ''}/${selectedDispense.addressInfo[0].zip ? selectedDispense.addressInfo[0].zip : ''}` : ''
            tmp.patient_dob = selectedDispense.patient_dob
            tmp.procedure_id = parseInt(selectedDispense.procedure_id)
            return tmp
        })
        setValidFlags(prev => {
            const tmp = [...prev];
            tmp[1] = tmp[2] = tmp[3] = true
            return tmp
        })

        setFilterName(each.patient_first_name + ' ' + each.patient_last_name)
        setNameFilterOpen(false)
    }

    const handleUpdatePatientDispense = () => {
        let selectedDoses = []
        let dosesAll = [...doses]
        let inputedDoses = [[], []]
        setShowError(true)
        _.forEach(quantity, (e1, i) => {
            _.forEach(e1, (e2, j) => {
                if (e2 !== '' && lotSharp[i][j] !== '' && expDates[i][j] !== '')
                    inputedDoses[i].push({
                        'dose_size_id': sizeID[i][j],
                        'dose_qty': quantity[i][j],
                        'dose_lot_number': lotSharp[i][j],
                        'pellet_exp_date': expDates[i][j],
                        'input_valid': inputValid[i][j],
                        'overflow_qty': overflowQty[i][j]
                    })
            })
        })
        if (validFlags.some(e => e === false))
            alert('credential error')
        else {
            if (inputValid.join(',').search('false') === -1) {
                _.forEach(sizeID, (eachI, i) => {
                    _.forEach(eachI, (eachJ, j) => {
                        let eachLot = _.filter(dosesAll, dose => dose.dose_size === doseTypes[i].sizes[eachJ].toString() && dose.dose_lot_number === lotSharp[i][j])[0]
                        if (eachLot) {
                            //Adds new if doesn't exist on type
                            if (_.findIndex(selectedDoses, e => e._id === eachLot._id) === -1) {
                                let tmp = { ..._.filter(dosesAll, dose => dose._id === eachLot._id)[0] }

                                var test = oldQuantity;

                                // add edit value on the quantity
                                tmp.dose_qty = tmp.dose_qty - parseFloat(quantity[i][j]) + parseFloat(oldQuantity[i][j]);
                                selectedDoses.push(tmp)

                                // tmp.dose_qty = parseFloat(_.filter(dosesAll, dose => dose._id === eachLot._id)[0].original_qty) - parseFloat(quantity[i][j])
                                // selectedDoses.push(tmp)
                            }
                            //Subtracts if exists
                            else {
                                selectedDoses[_.findIndex(selectedDoses, e => e._id === eachLot._id)].dose_qty -= parseFloat(quantity[i][j])
                                // console.log(selectedDoses)
                            }
                        }
                    })
                })
                if (_.some(selectedDoses, e => e.dose_qty < 0)) alert("there is minus!!!")
                else {
                    let form = { dispenseData: info, selDoses: selectedDoses, where: where }
                    form.dispenseData.inputedDoses = inputedDoses
                    dispatch(doUpdateDispense(form))
                    setOpen(false)
                }
            }
            else
                alert('validation error')
        }
    }

    useOutsideDetector([nameTitleRef, nameContentRef], setNameFilterOpen)
    useEffect(() => {
        if (open) {
            dispatch(doGetWholeDoses())
            let tmp = {}

            tmp.dispense_date = dispense.dispense_date.slice(0, 10)
            tmp.patient_first_name = dispense.patient_first_name
            tmp.patient_last_name = dispense.patient_last_name
            tmp.patient_phone_number = dispense.patient_phone_number
            tmp.patient_dob = dispense.patient_dob
            tmp.practice_name = dispense.practice_name
            tmp.is_new = dispense.is_new
            tmp.address = dispense.addressInfo?.length > 0 ?
                `${dispense.addressInfo[0].city ? dispense.addressInfo[0].city : ''} ${dispense.addressInfo[0].street ? dispense.addressInfo[0].street : ''} ${dispense.addressInfo[0].state ? dispense.addressInfo[0].state : ''} ${dispense.addressInfo[0].zip ? dispense.addressInfo[0].zip : ''}` : ''
            tmp.procedure_id = parseInt(dispense.procedure_id)
            tmp.original_qty = dispense.original_qty

//new
//to empty before fill it
            let inputedDoses = [[], []]
            _.forEach(quantity, (e1, i) => {
                _.forEach(e1, (e2, j) => {
                    console.log(i);
                        inputedDoses[i].push({
                            'dose_size_id': sizeID[i][j],
                            'dose_qty': quantity[i][j],
                            'dose_lot_number': lotSharp[i][j],
                            'pellet_exp_date': expDates[i][j],
                            'input_valid': inputValid[i][j],
                            'overflow_qty': overflowQty[i][j]
                        })
                })
            })
            _.forEach(inputedDoses, (e1, i) => {
                _.forEach(e1, (e2, j) => {
                   // console.log(i);
                    setSizeID(prev => { const tmp = [...prev]; prev[i][j] = inputedDoses[i]['dose_size_id']; return tmp })
                    setQuantity(prev => { const tmp = [...prev]; prev[i][j] = inputedDoses[i]['dose_qty']; return tmp })
                    setOldquantity(prev => { const tmp = [...prev]; prev[i][j] = inputedDoses[i]['dose_qty']; return tmp })
                    setLotSharp(prev => { const tmp = [...prev]; prev[i][j] = ''; return tmp })
                    setExpDates(prev => { const tmp = [...prev]; prev[i][j] = inputedDoses[i]['pellet_exp_date']; return tmp })
                    setOverflowQty(prev => { const tmp = [...prev]; prev[i][j] = inputedDoses[i]['overflow_qty']; return tmp })
                })
            })


            setInfo(tmp)
            _.forEach(dispense.inputedDoses, (e, i) => {
                _.forEach(e, (e1, j) => {
                    setSizeID(prev => { const tmp = [...prev]; prev[i][j] = e1.dose_size_id; return tmp })
                    setQuantity(prev => { const tmp = [...prev]; prev[i][j] = e1.dose_qty; return tmp })
                    setOldquantity(prev => { const tmp = [...prev]; prev[i][j] = e1.dose_qty; return tmp })
                    setLotSharp(prev => { const tmp = [...prev]; prev[i][j] = e1.dose_lot_number; return tmp })
                    setExpDates(prev => { const tmp = [...prev]; prev[i][j] = e1.pellet_exp_date; return tmp })
                    setOverflowQty(prev => { const tmp = [...prev]; prev[i][j] = e1.overflow_qty; return tmp })
                })
            })

            setShowError(false)
            setValidFlags([true, true, true, true])
        }
    }, [open, dispense, dispatch])

    const returnFormBodies = (i, each) =>
        _.map(Array(multiplier).fill(''), (e, j) =>

            <FormBody
                key={i * 2 + j} i={i} j={j} each={each}
                showError={showError}
                quantity={quantity[i][j]} setQuantity={setQuantity}
                lotSharp={lotSharp[i][j]} setLotSharp={setLotSharp}
                expDates={expDates[i][j]} setExpDates={setExpDates}
                overflowQty={overflowQty[i][j]} setOverflowQty={setOverflowQty}
                sizeID={sizeID[i][j]} setSizeID={setSizeID}
                inputValid={inputValid[i][j]} setInputValid={setInputValid}
                oldQuantity={oldQuantity[i][j]} setOldquantity={setOldquantity}
                isEdit={true}
            />
        )

    return (
        <>
            {open &&
                <Modal open={open} onClose={() => setOpen(false)}
                    BackdropComponent={Overlay}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <StyledContainer bgcolor='white' fontFamily='Poppins' color='white'>
                            <ModalHeader component='header'>
                                <Box component='h4' width='100%' lineHeight='110%' margin='0px' fontWeight='600' fontSize='20px' color='rgb(4,17,29)'>
                                    Edit Patient dispense
                                </Box>
                            </ModalHeader>
                            <ModalBody component='section'>
                                <Box>
                                    <VerifyHeader>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            {/* <Box width='150px'>Date:</Box> */}
                                            <Box width='150px'>Dispense Date:</Box>
                                            <Box component='input' fontSize='14px' width='300px' type='date' disabled
                                                value={info.dispense_date} onChange={(e) => {
                                                    setInfo(prev => { const tmp = { ...prev }; tmp.dispense_date = e.target.value; return tmp })
                                                    setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[0] = true : tmp[0] = false; return tmp })
                                                }}
                                                max="9999-12-31"
                                            />
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='150px'>New User?</Box>
                                            <ComboBox width='300px'>
                                                <Box component='select' width='100%' disabled
                                                    value={info.is_new ? 1 : 0} onChange={(e) =>
                                                        setInfo(prev => { const tmp = { ...prev }; tmp.is_new = Boolean(parseInt(e.target.value)); return tmp })
                                                    }>
                                                    <option value='0'>No</option>
                                                    <option value='1'>Yes</option>
                                                </Box>
                                            </ComboBox>
                                        </Box>
                                        {!info.is_new &&
                                            <Box display='flex' alignItems='center' mt='15px'>
                                                <Box width='150px'>Filter</Box>
                                                <Box position='relative' ref={nameTitleRef}>
                                                    <Box component='input' fontSize='14px' width='300px' position='relative'
                                                        value={filterName}
                                                        placeholder='Please input name'
                                                        onFocus={() => setNameFilterOpen(true)}
                                                        onChange={(e) => {
                                                            setFilterName(e.target.value)
                                                            dispatch({ type: 'PATIENT_SEACH', value: e.target.value })
                                                        }}
                                                    />
                                                    {nameFilterOpen &&
                                                        <InputDrops
                                                            contentRef={nameContentRef}
                                                            data={filteredDispense}
                                                            handleCopyNames={handleCopyNames}
                                                        />
                                                    }
                                                </Box>
                                            </Box>
                                        }
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='150px'>First Name:</Box>
                                            {/* old */}
                                            {/* <Box component='input' fontSize='14px' width='300px'
                                                value={info.patient_first_name} {...{ disabled: !info.is_new }} onChange={(e) => {
                                                    setInfo(prev => { const tmp = { ...prev }; tmp.patient_first_name = e.target.value; return tmp })
                                                    setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[1] = true : tmp[1] = false; return tmp })
                                                }}
                                            /> */}
                                            <Box component='input' fontSize='14px' width='300px'
                                                value={info.patient_first_name} disabled onChange={(e) => {
                                                    setInfo(prev => { const tmp = { ...prev }; tmp.patient_first_name = e.target.value; return tmp })
                                                    setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[1] = true : tmp[1] = false; return tmp })
                                                }}
                                            />
                                            <Box component='span' ml={1} color='red'>*</Box>
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='150px'>Last Name:</Box>
                                            {/* old */}
                                            {/* <Box component='input' fontSize='14px' width='300px'
                                                value={info.patient_last_name} {...{ disabled: !info.is_new }} onChange={(e) => {
                                                    setInfo(prev => { const tmp = { ...prev }; tmp.patient_last_name = e.target.value; return tmp })
                                                    setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[2] = true : tmp[2] = false; return tmp })
                                                }}
                                            /> */}
                                            <Box component='input' fontSize='14px' width='300px'
                                                value={info.patient_last_name} disabled onChange={(e) => {
                                                    setInfo(prev => { const tmp = { ...prev }; tmp.patient_last_name = e.target.value; return tmp })
                                                    setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[2] = true : tmp[2] = false; return tmp })
                                                }}
                                            />
                                            <Box component='span' ml={1} color='red'>*</Box>
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='150px'>Address:</Box>
                                            <Box component='input' fontSize='14px' width='300px' disabled
                                                placeholder='Address'
                                                value={info.address} onChange={(e) =>
                                                    setInfo(prev => { const tmp = { ...prev }; tmp.address = e.target.value; return tmp })
                                                }
                                            />
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='150px'>Phone:</Box>
                                            <Box component='input' fontSize='14px' width='300px' disabled
                                                placeholder='XXXXXXXXXX'
                                                value={info.patient_phone_number} onChange={(e) =>
                                                    setInfo(prev => { const tmp = { ...prev }; tmp.patient_phone_number = e.target.value; return tmp })
                                                }
                                            />
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='150px'>DOB:</Box>
                                            {/* old */}
                                            {/* <Box component='input' fontSize='14px' width='300px' type='date'
                                                value={info.patient_dob} {...{ disabled: !info.is_new }} onChange={(e) => {
                                                    setInfo(prev => { const tmp = { ...prev }; tmp.patient_dob = e.target.value; return tmp })
                                                    setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[3] = true : tmp[3] = false; return tmp })
                                                }}
                                            /> */}
                                            <Box component='input' fontSize='14px' width='300px' type='date'
                                                value={info.patient_dob} disabled onChange={(e) => {
                                                    setInfo(prev => { const tmp = { ...prev }; tmp.patient_dob = e.target.value; return tmp })
                                                    setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[3] = true : tmp[3] = false; return tmp })
                                                }}
                                                max="9999-12-31"
                                            />
                                            <Box component='span' ml={1} color='red'>*</Box>
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='150px'>Procedure:</Box>
                                            <ComboBox width='300px'>
                                                <Box component='select' width='100%' disabled
                                                    value={info.procedure_id} onChange={(e) =>
                                                        setInfo(prev => { const tmp = { ...prev }; tmp.procedure_id = e.target.value; return tmp })
                                                    }
                                                >
                                                    {_.map(procedures, (each, i) =>
                                                        <option key={i} value={each.procedure_id}>{each.procedure_name}</option>
                                                    )}
                                                </Box>
                                            </ComboBox>
                                        </Box>
                                    </VerifyHeader>
                                </Box>
                                {_.map(doseTypes, (each, i) =>
                                    <Box key={i} mt='30px'>
                                        <Box fontSize='20px' fontWeight='600'>{each.name}:</Box>
                                        <FormHead display='flex' flexWrap='wrap'>
                                            <BlankColumn width='150px' fontSize='20px' fontWeight='600' />
                                            <Box width='200px' fontSize='20px' fontWeight='600'>Qty</Box>
                                            <Box width='200px' ml='50px' fontSize='20px' fontWeight='600'>Lot</Box>
                                            <Box width='200px' ml='50px' fontSize='20px' fontWeight='600'>Exp Date</Box>
                                        </FormHead>
                                        <FormBodyWrapper>
                                            {returnFormBodies(i, each)}
                                        </FormBodyWrapper>
                                    </Box>
                                )}
                            </ModalBody>
                            <ModalFoot>
                                <Action mt='30px'>
                                    {/* old */}
                                    {/* <MyButton variant='contained' color='primary' onClick={handleUpdatePatientDispense}>
                                        Save</MyButton> */}
                                    <MyButton variant='contained' color='secondary' onClick={() => setOpen(false)}>Cancel</MyButton>
                                </Action>
                            </ModalFoot>
                            <Box display='flex' position='absolute' right='24px' top='24px'>
                                <CloseButton component='button' onClick={() => setOpen(false)}>
                                    <CloseOutlined style={{ fontSize: 24 }} />
                                </CloseButton>
                            </Box>
                        </StyledContainer>
                    </Fade>
                </Modal >
            }
        </>
    )
}

const FormBodyWrapper = styled(Box)`
    >div+div {
        margin-top: 15px;
    }
`

const ComboBox = styled(Box)`
    padding-right: 8px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    @media (max-width: 1040px) {
        width: 100% !important;
    }
    &:focus {
        border-color: red;
    }
`

const VerifyHeader = styled(Box)`
    >div {
        @media (max-width: 1040px) {
            align-items: initial;
            flex-direction: column;
            >input {
                width: 100%;
            }
        }
    }
`

const Action = styled(Box)`
    >button+button {
        margin-left: 30px;
    }
`

const MyButton = styled(Button)`
    text-transform: none !important;
`

const BlankColumn = styled(Box)`
    @media (max-width: 1040px) {
        display: none;
    }
`

const FormHead = styled(Box)`
    @media (max-width: 1040px) {
        >div {
            flex: 1;
        }
        >div:not(:first-of-type) {
            display: none;
            margin-left: unset;
        }
    }
`

const Overlay = styled(Backdrop)`
    background-color: rgba(0, 0, 0, 0.8) !important;
`

const CloseButton = styled(Box)`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 100%;
    font-family: inherit;
    border: 0px;
    padding: 0px;
    background: inherit;
    >svg { color: rgb(138, 147, 155); }
    &:hover {
        >svg { color: rgb(112, 122, 131); }
    }
`

const ModalHeader = styled(Box)`
    padding: 24px;
    border-bottom: 1px solid rgb(229, 232, 235);
    >h4 { word-break: break-word; }
`

const ModalBody = styled(Box)`
    padding: 24px;
    height: 100%;
    overflow-y: auto;
    font-weight: 400;
    color: rgb(53, 56, 64);
`

const ModalFoot = styled(Box)`
    padding: 24px;
    text-align: right;
`

const StyledContainer = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: transparent;
    max-height: calc(100% - 50px);
    overflow-y: auto;
    width: calc(100% - 50px);
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-corner {
        background: inherit;
    }
    &::-webkit-scrollbar-thumb {
        width: 30px;
        border-radius: 15px;
        background: rgb(33,37,41);
    }
    input {
        outline: none;
        border: 1.5px solid lightgrey;
        border-radius: 5px;
        transition: .3s;
        padding: 5px;
        height: fit-content;
        align-self: center;
        &:focus {
            border-color: #c850c0;
        }
    }
    select {
        border: none;
        outline: none;
        padding: 4px;
        border-radius: 5px;
    }
`

export default DispenseEditModal