import axios from 'axios'
import { REPORT_CRUD } from './types'

export function doGetMTD() {

    return (dispatch) => {
        axios.get('/api/reportController/doGetMTD')
            .then((res) => {
                if (res.data.success)
                {
                    dispatch({
                        type: REPORT_CRUD.READ,
                        report_MTD: res.data.report_MTD,
                        
                    })
                }
                else 
                {
                    alert('Error occured while loading roles')
                }
                    
            })

    }
}

export function doGetPatient() {

    return (dispatch) => {
        axios.get('/api/reportController/doGetPatient')
            .then((res) => {
                if (res.data.success)
                {
                    //console.log(res.data.report_MTD)
                    dispatch({
                        type: REPORT_CRUD.READ,
                        patient: res.data.patient,
                        
                    })
                }
                else 
                {
                    alert('Error occured while loading roles')
                }
                    
            })
    }
}

export function doGetAddress() {

    return (dispatch) => {
        axios.get('/api/reportController/doGetAddress')
            .then((res) => {
                if (res.data.success)
                {
                    //console.log(res.data.report_MTD)
                    dispatch({
                        type: REPORT_CRUD.READ,
                        address: res.data.address,
                        
                    })
                }
                else 
                {
                    alert('Error occured while loading roles')
                }
                    
            })
    }
}