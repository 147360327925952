import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button } from '@material-ui/core'
import styled from 'styled-components'
import _ from 'lodash'

import { NewUserModal, EditUserModal, ConfirmModal, ExpirationModal } from 'src/components/Modals'
import { UserRow } from 'src/components/Rows'

import SearchInput from 'src/components/Inputs/SearchInput'
import ColumnDrops from 'src/components/Dropdowns/ColumnDrops'
import Spinner from 'src/components/Spinners'
import Pagination from 'src/components/Paginations'

import { doDeleteUser } from 'src/redux/actions/AuthAction'

import BackImg from 'src/assets/images/backs/user_management.jpg'

const UserManagement = () => {
    const [openNewUser, setOpenNewUser] = useState(false)
    const [openExpiration, setOpenExpiration] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(false)
    const [openMsg, setOpenMsg] = useState(false)
    const [index, setIndex] = useState(-1)
    const [jIndex, setJIndex] = useState('')
    const [flags, setFlags] = useState([true, false, false])
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)

    const dispatch = useDispatch()
    const users = useSelector(state => state.AuthReducer.users)
    const [current_user,setCurrentdUser] = useState('')
    let totalrows=users.length;
    // console.log(totalrows,keyword);
    if(keyword !='')
    {
        totalrows=0;

        for(let x=0;x<users.length;x++)
        {
            if((flags[0] && users[x]?.user_name.search(keyword) !== -1) ||
            (flags[1] && users[x].user_type.search(keyword) !== -1) ||
            (flags[2] && users[x].user_first_name.search(keyword) !== -1) ||
            (flags[3] && users[x].user_last_name.search(keyword) !== -1))
            {
                totalrows++;
            }
        }
    }
    //console.log(users)

    const handleDelete = () => {
        dispatch(doDeleteUser(jIndex))
    }

    return (
        <StyledContainer>
            <Background />
            <Box padding='20px'>
                <Box fontSize='20px' fontWeight='600'>User management</Box>
                <UserFilter display='flex' justifyContent='flex-end'>
                    <ColumnDrops flags={flags} setFlags={setFlags} filterValues={['Email', 'Type', 'First Name', 'Last Name']} />
                    <SearchInput keyword={keyword} setKeyword={setKeyword} />
                    <Box>
                        <Button variant='contained' color='primary' onClick={() => setOpenNewUser(true)}>New user</Button>
                    </Box>
                    {/* <Box>
                        <Button variant='outlined' color='primary' onClick={() => setOpenExpiration(true)}>
                            <Box ml={1}>Expiration</Box>
                        </Button>
                    </Box> */}
                </UserFilter>
                <Box width='100%' boxShadow='2px 2px 5px 1px #ccc' mt={3} overflow='auto'>
                    <Table width='100%' component='table'>
                        <THead component='thead'>
                            <tr>
                                <th>No</th>
                                <th>Email</th>
                                <th>Practice Name</th>
                                {/* <th>Practice Name</th> */}
                                <th>Type</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th width='10%' >Permission</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </THead>
                        <TBody component='tbody' pb='20px'>
                            {flags.some(e => e === true) ? _.map(_.filter(_.filter(users, e => (flags[0] && e?.user_name.search(keyword) !== -1) ||
                                (flags[1] && e.user_type.search(keyword) !== -1) ||
                                (flags[2] && e.user_first_name.search(keyword) !== -1) ||
                                (flags[3] && e.user_last_name.search(keyword) !== -1)
                            ), (e, uIndex) => uIndex >= 20 * (page - 1) && uIndex <= 20 * (page - 1) + 19), (each, i) =>
                                <UserRow
                                    key={i} id={i + 20 * (page - 1)}
                                    eachInfo={each}
                                    setOpenMsg={setOpenMsg} setOpenUpdate={setOpenUpdate}
                                    setIndex={setIndex} setJIndex={setJIndex} setCurrentdUser={setCurrentdUser}
                                />
                            ) :
                                _.map(_.filter(_.filter(users, e => (e?.user_name.search(keyword) !== -1) ||
                                    (e.user_first_name.search(keyword) !== -1) ||
                                    (e.user_last_name.search(keyword) !== -1) ||
                                    (e.user_type.search(keyword) !== -1)
                                ), (e, uIndex) => uIndex >= 20 * (page - 1) && uIndex <= 20 * (page - 1) + 19), (each, i) =>
                                    <UserRow
                                        key={i} id={i}
                                        eachInfo={each}
                                        setOpenMsg={setOpenMsg} setOpenUpdate={setOpenUpdate}
                                        setIndex={setIndex} setJIndex={setJIndex} setCurrentdUser={setCurrentdUser}
                                    />
                                )
                            }

                        </TBody>
                    </Table>
                    {(users && users.length === 0) && <Box width='100%' display='flex' justifyContent='center'>
                        <Spinner />
                    </Box>
                    }
                </Box>
                <TFoot mt='20px' textAlign='right'>
                    {/* old */}
                    {/* {(users && users.length > 0) && <Pagination step={20} totalCount={users.length} page={page} setPage={setPage} />} */}
                    {(users && users.length > 0) && <Pagination step={20} totalCount={totalrows} page={page} setPage={setPage} keyword={keyword} />}
                </TFoot>
            </Box>
            <ExpirationModal open={openExpiration} setOpen={setOpenExpiration} flags={flags} page={page} />
            <NewUserModal open={openNewUser} setOpen={setOpenNewUser} />
            {/* <EditUserModal open={openUpdate} setOpen={setOpenUpdate} user={users[index]} /> */}
            <EditUserModal open={openUpdate} setOpen={setOpenUpdate} user={current_user} />
            <ConfirmModal open={openMsg} setOpen={setOpenMsg} confirmDelete={handleDelete} />
        </StyledContainer>
    )
}

const UserFilter = styled(Box)`
    >div {
        margin-right: 0;
    }
    >div+div {
        margin-left: 16px;
    }
`

const THead = styled(Box)``

const TBody = styled(Box)``

const Table = styled(Box)`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: white;
        font-weight: 600;
    }
    thead >tr >th:nth-of-type(6) {
        // text-align: center;
    }
    tbody tr {
        transition: .3s;
        background-color: white;
        border-top: 1px solid lightgrey;
    }
    tr:hover {background-color: #eee;}
    th, td {
        padding: 8px;
    }
`

const Background = styled(Box)`
    position: absolute;
    width: 100%; height: 100%;
    background: url(${BackImg});
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position-y: center;
    opacity: .15;
    user-select: none;
    pointer-events: none;
`

const StyledContainer = styled(Box)`
    position: relative;
    min-height: 500px;
`

// const MyButton = styled(Button)`
//     text-transform: none !important;
// `

const TFoot = styled(Box)``

export default UserManagement