import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Box } from '@material-ui/core'
import { ExitToApp, ExpandMore, MenuOutlined, VpnKey } from '@material-ui/icons'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { RiLoginBoxLine, RiLogoutBoxLine } from "react-icons/ri";

import { doLogoutUser } from 'src/redux/actions/AuthAction'
import { pelletMenu, patientMenu, reportMenu1,invMenu } from './entry'
import * as PAGES from 'src/constants/pages'

import Logo from 'src/assets/images/logo.png'
import TopDrops from 'src/components/Dropdowns/TopDrops'

const Topbar = () => {
    const [mobileFlags, setMobileFlags] = useState([false, false, false, false, false])
    const [topMenuIndex, setTopMenuIndex] = useState(-1)
    const [showMobile, setShowMobile] = useState(false)
    const [focus, setFocus] = useState(false)

    const user = localStorage.getItem('jwtToken')
    const history = useHistory()
    const dispatch = useDispatch()

    const handleNavigation = async (path) => {
        setShowMobile(false)
        history.push(`/${path}`)

    }

    return (
        <>
            <StyledContainer component='header'>
                <Box>
                    <Box>
                        <LogoLink component='a' {...{ href: '/' }}>
                            <img src={Logo} alt='' />
                        </LogoLink>
                        <AppMenu>
                            {user !== null &&
                                <>
                                    <Box>
                                        <Box>
                                            <StyledButton component='button' onClick={() => handleNavigation(PAGES.DASHBOARD)}>
                                                <Box component='span'>Dashboard</Box>
                                            </StyledButton>
                                        </Box>
                                    </Box>
                                    {/* 8ec5ebdd748084f9138d80fa0c42e60a == Administrator */}
                                    {jwtDecode(user).userType === 'Administrator' &&
                                        <Box>
                                            <Box>
                                                <StyledButton component='button' onClick={() => handleNavigation(PAGES.USERS)}>
                                                    <Box component='span'>Users</Box>
                                                </StyledButton>
                                            </Box>
                                        </Box>
                                    }
                                    {/* {jwtDecode(user).userType === 'Administrator' &&
                                        <Box>
                                            <Box>
                                                <StyledButton component='button' onClick={() => handleNavigation(PAGES.INVADJUST)}>
                                                    <Box component='span'>Inventory Adj</Box>
                                                </StyledButton>
                                            </Box>
                                        </Box>
                                    } */}
                                    {jwtDecode(user).userType === 'Administrator' &&
                                        <Box onMouseEnter={() => setTopMenuIndex(3)} onMouseLeave={() => setTopMenuIndex(-1)}>
                                        {topMenuIndex === 3 && <TopDrops items={invMenu} open={topMenuIndex === 3} />}
                                        <Box>
                                            <StyledButton component='button'>
                                                <Box component='span'>Inventory Adj</Box>
                                                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M16.5 12l-5.668 6L9.5 16.59 13.827 12 9.5 7.41 10.832 6l5.668 6z'></path></svg>
                                            </StyledButton>
                                        </Box>
                                        </Box>
                                    }
                                    {jwtDecode(user).userType === 'Practice' &&
                                        <Box onMouseEnter={() => setTopMenuIndex(0)} onMouseLeave={() => setTopMenuIndex(-1)}>
                                            {topMenuIndex === 0 && <TopDrops items={pelletMenu.filter(x => x.title != "Deleted Pellets Log")} open={topMenuIndex === 0} />}
                                            <Box>
                                                <StyledButton component='button'>
                                                    <Box component='span'>Pellets</Box>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M16.5 12l-5.668 6L9.5 16.59 13.827 12 9.5 7.41 10.832 6l5.668 6z'></path></svg>
                                                </StyledButton>
                                            </Box>
                                        </Box>
                                    }
                                    {jwtDecode(user).userType === 'Administrator' &&
                                        <Box onMouseEnter={() => setTopMenuIndex(0)} onMouseLeave={() => setTopMenuIndex(-1)}>
                                            {topMenuIndex === 0 && <TopDrops items={pelletMenu.filter(x => x.title == "Manage Pellets" || x.title == "Deleted Pellets Log")} open={topMenuIndex === 0} />}
                                            <Box>
                                                <StyledButton component='button'>
                                                    <Box component='span'>Pellets</Box>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M16.5 12l-5.668 6L9.5 16.59 13.827 12 9.5 7.41 10.832 6l5.668 6z'></path></svg>
                                                </StyledButton>
                                            </Box>
                                        </Box>
                                    }
                                    {jwtDecode(user).userType === 'Practice' &&
                                        <Box onMouseEnter={() => setTopMenuIndex(1)} onMouseLeave={() => setTopMenuIndex(-1)}>
                                            {topMenuIndex === 1 && <TopDrops items={patientMenu.filter(x => x.title != "Deleted Patients Log" )} open={topMenuIndex === 1} />}
                                            <Box>
                                                <StyledButton component='button'>
                                                    <Box component='span'>Patients</Box>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M16.5 12l-5.668 6L9.5 16.59 13.827 12 9.5 7.41 10.832 6l5.668 6z'></path></svg>
                                                </StyledButton>
                                            </Box>
                                        </Box>
                                    }
                                    {jwtDecode(user).userType === 'Administrator' &&
                                        <Box onMouseEnter={() => setTopMenuIndex(1)} onMouseLeave={() => setTopMenuIndex(-1)}>
                                            {topMenuIndex === 1 && <TopDrops items={patientMenu.filter(x => x.title == "Manage Patients" || x.title == "Deleted Patients Log" )} open={topMenuIndex === 1} />}
                                            <Box>
                                                <StyledButton component='button'>
                                                    <Box component='span'>Patients</Box>
                                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M16.5 12l-5.668 6L9.5 16.59 13.827 12 9.5 7.41 10.832 6l5.668 6z'></path></svg>
                                                </StyledButton>
                                            </Box>
                                        </Box>
                                    }
                                    <Box onMouseEnter={() => setTopMenuIndex(2)} onMouseLeave={() => setTopMenuIndex(-1)}>
                                        {topMenuIndex === 2 && <TopDrops items={reportMenu1} open={topMenuIndex === 2} hasSections />}
                                        <Box>
                                            <StyledButton component='button'>
                                                <Box component='span'>Reports</Box>
                                                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M16.5 12l-5.668 6L9.5 16.59 13.827 12 9.5 7.41 10.832 6l5.668 6z'></path></svg>
                                            </StyledButton>
                                        </Box>
                                    </Box>
                                </>
                            }
                        </AppMenu>
                        <AuthMenu>
                            {user !== null ?
                                <AuthLink component='a' onClick={() => dispatch(doLogoutUser(history))}>
                                    <RiLogoutBoxLine fontSize={"24px"}></RiLogoutBoxLine>
                                    Logout
                                </AuthLink>
                                :
                                <AuthLink component='a' onClick={() => handleNavigation(PAGES.LOGIN)}>
                                    {/* <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fillRule='evenodd' d='M4.667 5.667a3.667 3.667 0 107.333 0 3.667 3.667 0 00-7.333 0zm6 0a2.333 2.333 0 11-4.667 0 2.333 2.333 0 014.667 0z' clipRule='evenodd'></path><path fillRule='evenodd' d='M13.705 13.263c-1.424 1.037-9.516.927-10.79 0-1.273-.928 2.624-3.93 5.395-3.93 2.771 0 6.819 2.893 5.395 3.93zM8.31 10.667c.989 0 2.42.55 3.49 1.323.186.134.349.268.484.392-.844.164-2.37.294-4.023.284-1.518-.009-2.962-.136-3.813-.28a5.69 5.69 0 01.445-.367c1.065-.794 2.451-1.352 3.417-1.352z' clipRule='evenodd'></path></svg> */}
                                    <RiLoginBoxLine fontSize={"24px"}></RiLoginBoxLine>
                                    Login
                                </AuthLink>
                            }
                            {/* {user === null && <GetStarted onClick={() => handleNavigation(PAGES.SIGNUP)}>Get Started</GetStarted>} */}
                        </AuthMenu>
                        {user !== null ?
                            <>
                                <Box display='flex' alignItems='center'>
                                    <LogoutButton onClick={() => dispatch(doLogoutUser(history))}>
                                        <RiLogoutBoxLine fontSize={"24px"}></RiLogoutBoxLine>
                                    </LogoutButton>
                                    <MobileMenuButton onClick={() => setShowMobile(!showMobile)}>
                                        <MenuOutlined />
                                    </MobileMenuButton>
                                </Box>
                            </>
                            :
                            <LoginButton onClick={() => handleNavigation(PAGES.LOGIN)}
                                onMouseEnter={() => setFocus(true)}
                                onMouseLeave={() => setFocus(false)}
                                focus={focus ? 1 : 0}
                            >
                                <VpnKey />
                            </LoginButton>
                        }
                    </Box>
                </Box>
            </StyledContainer>
            <MobileMenu position='fixed' width='100%' bgcolor='white' boxShadow='0px 0px 2px .5px lightgrey' active={showMobile ? 1 : 0}>
                <Box onClick={() => handleNavigation('')}>
                    Dashboard
                </Box>
                {user !== null && jwtDecode(user).userType === 'Administrator' &&
                    <Box onClick={() => handleNavigation('user_management')}>
                        Users
                    </Box>
                }
                {/* {user !== null && jwtDecode(user).userType === 'Administrator' &&
                    <Box onClick={() => handleNavigation('inv_adjustment')}>
                        Inv Adjustment
                    </Box>
                } */}
                {user !== null && jwtDecode(user).userType === 'Administrator' &&
                    <Box>
                    <Box onClick={() => {
                        let tmp = [...mobileFlags]
                        tmp[3] = !tmp[3]
                        setMobileFlags(tmp)
                    }}>
                        Inv Adjustment
                        <ExpandMore />
                    </Box>
                    {mobileFlags[3] &&
                        <Box>
                            {_.map(invMenu, (each, i) =>
                                <Box key={i} onClick={() => handleNavigation(each.path)}>{each.title}</Box>
                            )}
                        </Box>
                    }
                </Box>
                }
                {user !== null && jwtDecode(user).userType === 'Administrator' &&
                    <Box>
                        <Box onClick={() => {
                            let tmp = [...mobileFlags]
                            tmp[0] = !tmp[0]
                            setMobileFlags(tmp)
                        }}>
                            Pellets
                            <ExpandMore />
                        </Box>
                        {mobileFlags[0] &&
                            <Box>
                                {_.map(pelletMenu.filter(x => x.title == "Manage Pellets" || x.title == "Deleted Pellets Log"), (each, i) =>
                                    <Box key={i} onClick={() => handleNavigation(each.path)}>{each.title}</Box>
                                )}
                            </Box>
                        }
                    </Box>
                }
                {user !== null && jwtDecode(user).userType === 'Practice' &&
                    <Box>
                        <Box onClick={() => {
                            let tmp = [...mobileFlags]
                            tmp[0] = !tmp[0]
                            setMobileFlags(tmp)
                        }}>
                            Pellets
                            <ExpandMore />
                        </Box>
                        {mobileFlags[0] &&
                            <Box>
                                {_.map(pelletMenu.filter(x => x.title != "Deleted Pellets Log"), (each, i) =>
                                    <Box key={i} onClick={() => handleNavigation(each.path)}>{each.title}</Box>
                                )}
                            </Box>
                        }
                    </Box>
                }
                {user !== null && jwtDecode(user).userType === 'Administrator' &&
                    <Box>
                        <Box onClick={() => {
                            let tmp = [...mobileFlags]
                            tmp[1] = !tmp[1]
                            setMobileFlags(tmp)
                        }}>
                            Patients
                            <ExpandMore />
                        </Box>
                        {mobileFlags[1] &&
                            <Box>
                                {_.map(patientMenu, (each, i) =>
                                    <Box key={i} onClick={() => handleNavigation(each.path)}>{each.title}</Box>
                                )}
                            </Box>
                        }
                    </Box>
                }
                {user !== null && jwtDecode(user).userType === 'Practice' &&
                    <Box>
                        <Box onClick={() => {
                            let tmp = [...mobileFlags]
                            tmp[1] = !tmp[1]
                            setMobileFlags(tmp)
                        }}>
                            Patients
                            <ExpandMore />
                        </Box>
                        {mobileFlags[1] &&
                            <Box>
                                {_.map(patientMenu.filter(x => x.title != "Deleted Patients Log" ), (each, i) =>
                                    <Box key={i} onClick={() => handleNavigation(each.path)}>{each.title}</Box>
                                )}
                            </Box>
                        }
                    </Box>
                }
                <Box>
                    <Box onClick={() => {
                        let tmp = [...mobileFlags]
                        tmp[2] = !tmp[2]
                        setMobileFlags(tmp)
                    }}>
                        Reports
                        <ExpandMore />
                    </Box>
                    {mobileFlags[2] &&
                        <Box>
                            {_.map(reportMenu1, (each, i) =>
                                <Box key={i} onClick={() => handleNavigation(each.path)}>{each.title}</Box>
                            )}
                        </Box>
                    }
                </Box>
            </MobileMenu>
        </>
    )
}

const LogoutButton = styled(Box)`
    cursor: pointer;
    margin-right: 10px;
    transition: .3s;
    color: black;
    &:hover {
        color: #195AFE;
    }
    @media (min-width: 1024px) {
        display: none;
    }
`

const LoginButton = styled(Box)`
    cursor: pointer;
    transition: .3s;
    animation-duration: 1s;
    animation-play-state: ${({ focus }) => focus ? 'paused' : 'unset'};
    animation-iteration-count: infinite;
    animation-name: pushup;
    @keyframes pushup {
        0% {transform: translate(0px, 0px)}
        50% {transform: translate(0px, -5px)}
        100% {transform: translate(0px, 0px)}
    }
    >svg {
        transform: rotate(-45deg);
    }
    &:hover {
        color: #195AFE;
    }
    @media (min-width: 1024px) {
        display: none;
    }
`

const MobileMenu = styled(Box)`
    top: 56px;
    height: ${({ active }) => active ? 'calc(100% - 56px)' : '0px'};
    z-index: 999;
    overflow: hidden;
    transition: .3s;
    >div {
        cursor: pointer;
        padding: 15px 20px;
        padding-bottom: 0px;
        font-size: 20px;
        >div:first-of-type {
            display: flex;
            justify-content: space-between;
        }
        >div:nth-of-type(2) {
            font-size: 17px;
            color: grey;
        }
    }
    @media (min-width: 1024px) {
        display: none;
    }
`

const MobileMenuButton = styled(Box)`
    font-weight: 400;
    box-sizing: border-box;
    padding: 4px;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    appearance: none;    
    transition: .3s;
    &:hover {
        color: #195AFE;
    }
    @media (min-width: 1024px) {
        display: none;
    }
`

const GetStarted = styled(Box)`
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.875rem 24px;
    border: 0;
    border-radius: 3rem;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.2s;
    cursor: pointer;
    user-select: none;
    background-color: #F4732A;
    color: #F5F9FD;
    white-space: nowrap;
    letter-spacing: 0.1em;
    padding: 0.625rem 16px;
    &:hover {
        background-color: #E64506;
    }
`

const AuthLink = styled(Box)`
    margin-right: 16px;
    @media (min-width: 1201px) {
        padding: 6px 12px;
    }
    box-sizing: border-box;
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 6px 8px;
    background-color: transparent;
    border: 0;
    border-radius: 6px;
    font-family: inherit;
    font-size: 0.9375rem;
    color: #092540;
    line-height: 1.29;
    letter-spacing: 0.01em;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: color 0.3s;
    @media (min-width: 1201px) {
        padding: 6px 12px;
    }
    margin-right: 16px;
    >svg {
        flex-shrink: 0;
        margin-right: 8px;
        fill: currentColor;
    }
`

const AuthMenu = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    @media (max-width: 1023px) {
        display: none;
        position: fixed;
        z-index: 5;
        justify-content: center;
        width: 100%;
        height: auto;
        bottom: 0;
        left: 0;
        padding: 16px 0;
        background-color: #FFF;
        box-shadow: 0 4px 32px rgb(64 68 77 / 8%);
    }
`

const StyledButton = styled(Box)`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 6px 8px;
    background-color: transparent;
    border: 0;
    border-radius: 6px;
    font-family: inherit;
    font-size: 0.9375rem;
    color: #092540;
    line-height: 1.29;
    letter-spacing: 0.01em;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: color 0.3s;
    font-weight: 400;
    @media (min-width: 1201px) {
        padding: 6px 12px;
    }
    >span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    >svg {
        flex-shrink: 0;
        fill: currentColor;
        margin-right: 4px;
        fill: rgba(9,37,64,0.3);
        overflow: hidden;
        @media (min-width: 1024px) {
            width: 16px;
            height: 16px;
            margin-right: 0;
            margin-left: 4px;
            transform: rotate(90deg);
            transition: transform 0.2s ease-in, fill 0.3s;
        }
    }
    @media (min-width: 888px) {
        &:hover {
            color: #195AFE;
            svg { fill: #195AFE; }
        }
    }
`

const AppMenu = styled(Box)`
    display: none;
    box-sizing: border-box;
    align-self: stretch;
    min-width: 0;
    @media (min-width: 1024px) {
        display: flex;
    }
    >div {
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        max-width: 566px;
        min-width: 0;
        width: 100%;
        padding: 0 16px;
        @media (min-width: 888px) {
            max-width: 1248px;
            padding: 0 24px;
        }
        @media (min-width: 1024px) {
            justify-content: center;
            max-width: 100%;
            width: auto;
            padding: 3px 0;
        }
        display: none;
        @media (min-width: 1024px) {
            display: flex;
        }
    }
`

const LogoLink = styled(Box)`
    align-self: center;
    flex-shrink: 0;
    >img {
        overflow: hidden; display: block;
        height: 52px;
        @media (min-width: 1024px) {
            height: 60px;
        }
        width: auto;
    }
`

const StyledContainer = styled(Box)`
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    height: 56px;
    background-color: #FFF;
    box-shadow: 0 6px 11px rgb(64 68 77 / 6%);
    @media (min-width: 1024px) {
        height: 64px;
    }
    >div:first-of-type {
        box-sizing: border-box;
        width: 100%;
        padding: 0 16px;
        margin: 0 auto;
        @media (min-width: 888px) {
            padding-right: 24px;
            padding-left: 24px;
            max-width: 1248px;
        }
        >div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }
    }
`

export default Topbar