import React from 'react'
import { Box, Button } from '@material-ui/core'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import Badge from 'src/components/Badges'
import { doAddExpiration, doSetFullTime } from 'src/redux/actions/AuthAction'

const SimpleUserRow = ({ id, eachInfo }) => {

    const dispatch = useDispatch()

    const handleSetTime = () => {
        let curDate = new Date(eachInfo.permission_time)
        let formdata = {
            where: eachInfo._id,
            permission_time: curDate
        }
        if (eachInfo.flag_permission_time === 2) alert('This user is already certified')
        else dispatch(doAddExpiration(formdata))
    }

    const handleSetFullTime = (where) => {
        dispatch(doSetFullTime(where))
    }

    const handleReturnLeftDate = () => {
        var a = new Date()
        var b = new Date(eachInfo.permission_time)
        return b - a ? Math.round((b - a) / 1000 / 3600) >= 1 ? `${Math.round((b - a) / 1000 / 3600 / 24)} days left` : `${Math.round((b - a) / 1000 / 3600)}hrs left` : ''
    }

    return (
        <StyledContainer component='tr'>
            <td>{id + 1}</td>
            <td>{eachInfo.user_name}</td>
            <td align='center'>
                {eachInfo.flag_permission_time === 2 ?
                    <>
                        <Badge type='Certified' />
                    </>
                    :
                    <>
                        <Badge type={handleReturnLeftDate} />
                    </>
                }

            </td>
            <td align='center'>
                <Button variant='outlined' color='primary' onClick={handleSetTime}>Add Time</Button>
            </td>
               <td align='center'>  
             </td>
        </StyledContainer>
    )
}
//    but
//                  <Button variant='outlined' color='primary' onClick={() => handleSetFullTime(eachInfo._id)} style={{ marginLeft: '5px' }} >Full Time</Button>




// const BlockIcon = styled(BlockOutlined)`
//     fill: red !important;
// `

// const BlockButton = styled(VerifiedUserOutlined)`
//     cursor: pointer;
//     opacity: .5;
//     &:hover {
//         transition: .3s;
//         opacity: 1;
//     }
// `

// const EditButton = styled(EditOutlined)`
//     cursor: pointer;
//     opacity: .5;
//     &:hover {
//         transition: .3s;
//         opacity: 1;
//     }
// `

// const DeleteButton = styled(DeleteOutlined)`
//     cursor: pointer;
//     opacity: .5;
//     &:hover {
//         transition: .3s;
//         opacity: 1;
//     }
// `

const StyledContainer = styled(Box)`
`

export default SimpleUserRow