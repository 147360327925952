import { Box, Fade } from '@material-ui/core'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import _ from 'lodash'

const TopDrops = ({ items, open, hasSections }) => {
    const history = useHistory()

    return (
        <>
            <Fade in={open} timeout={500}>
                <StyledContainer active={hasSections ? 1 : 0}>
                    {_.map(items, (each, i) =>
                        <Box key={i}>
                            {i === 0 && hasSections && <Box component='h6'>Inventory #R</Box>}
                            {i === 2 && hasSections && <Box component='h6'>Patient #R</Box>}
                            {i === 5 && hasSections && <Box component='h6'>Misc #R</Box>}
                            <Box onClick={() => history.push(each.path)}>{each.title}</Box>
                        </Box>
                    )}
                </StyledContainer>
            </Fade>
        </>
    )
}

const StyledContainer = styled(Box)`
    position: absolute;
    top: 100%;
    left: 0%;
    width: 200px;
    background: white;
    box-shadow: 0px 0px 5px 1px #ccc;
    >div {
        >div {
            position: relative;
            transition: .3s;
            padding: ${({ active }) => active ? '10px 10px 10px 30px' : '10px 20px'};
            font-size: 14px;
            cursor: pointer;
            color: #777;
            border-top: 1px solid #eee;
            &::before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 3px;
                height: 100%;
                background: transparent;
                transition: .3s;
            }
            &:hover {
                &::before {
                    background: #04D7E1;
                }
            }
        }
        >h6 {
            border-top: 1px solid #eee;
            padding: 15px 20px 5px 20px;
        }
    }
`

export default TopDrops