import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Modal, Fade, Backdrop } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import _ from 'lodash'

import { doRegisterUser } from 'src/redux/actions/AuthAction'

const NewUserModal = ({ open, setOpen }) => {
    const [newUser, setNewUser] = useState({
        'user_name': '',
        'user_first_name': '',
        'user_last_name': '',
        'password': '',
        'user_type_id': 0,
        'user_type': 'Administrator',
        'permission': false,
        'sendToClient': true,
        'biosana_id': '',
        'practice_name': '',
        'practioner_name': '',
        'dea_no': '',
    })
    //new
    /*const handlePasswordChange =(evnt)=>{
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        const NewPasswordInput = {...passwordInput,[passwordInputFieldName]:passwordInputValue}
        setPasswordInput(NewPasswordInput);
        
    }*/
    const handleValidation= (evnt)=>{
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
            //for password 
    //if(passwordInputFieldName==='password'){
        const uppercaseRegExp   = /(?=.*?[A-Z])/;
        const lowercaseRegExp   = /(?=.*?[a-z])/;
        const digitsRegExp      = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp   = /.{6,}/;
        const passwordLength =      passwordInputValue.length;
        const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
        const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
        const digitsPassword =      digitsRegExp.test(passwordInputValue);
        const specialCharPassword = specialCharRegExp.test(passwordInputValue);
        const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
        let errMsg =null;
        if(passwordLength===0){
                errMsg="Password is empty";
        }else if(!uppercasePassword){
                errMsg="At least one Uppercase";
               
        }else if(!lowercasePassword){
                errMsg="At least one Lowercase";
        }else if(!digitsPassword){
                errMsg="At least one digit";

        }/*else if(!specialCharPassword){
                errMsg="At least one Special Characters";
        }*/else if(!minLengthPassword){
                errMsg="At least minumum 6 characters";   
        }else{
            errMsg=null;
        }
        setPasswordErr(errMsg);
        //}
        // for confirm password
        /*if(passwordInputFieldName=== "confirmPassword" || (passwordInputFieldName==="password" && passwordInput.confirmPassword.length>0) ){
                
            if(passwordInput.confirmPassword!==passwordInput.password)
            {
            setConfirmPasswordError("Confirm password is not matched");
            }else{
            setConfirmPasswordError("");
            }
            
        }*/
    }
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [ferror, setFError] = useState(null);
    const [lerror, setLError] = useState(null);
    const [perror, setPError] = useState(null);
    const [prerror, setPRError] = useState(null);
    const [biorerror, setBioError] = useState(null);
    const [dearerror, setDeaError] = useState(null);
    const [passwordError, setPasswordErr] = useState(null);
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function isValidName(name) {
        //console.log(/^(?!\s)[A-Za-z0-9-_&*#+\s]+$/.test(name));
        return /^(?!\s)[A-Za-z0-9-_&*#+()\s]+$/.test(name);
    }

    const handleChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError('Email is invalid');
        } else {
          setError(null);
        }
    
        setMessage(event.target.value);
      };

    const handleChange2 = event => {
        //console.log(event.target.value);
        if (!isValidName(event.target.value) && event.target.value!='') {
          setPError('Practice Name is invalid');
        } else {
          setPError(null);
        }
    
        setMessage(event.target.value);
      }; 
      
      
      const handleChange3 = event => {
        //console.log(event.target.value);
        if (!isValidName(event.target.value) && event.target.value!='') {
          setPRError('Practioner Name is invalid');
        } else {
            setPRError(null);
        }
    
        setMessage(event.target.value);
      };  
      

      const handleChange4 = event => {
        //console.log(event.target.value);
        if (!isValidName(event.target.value) && event.target.value!='') {
          setFError('First Name is invalid');
        } else {
            setFError(null);
        }
    
        setMessage(event.target.value);
      };

      const handleChange5 = event => {
        //console.log(event.target.value);
        if (!isValidName(event.target.value) && event.target.value!='') {
          setLError('Last Name is invalid');
        } else {
            setLError(null);
        }
    
        setMessage(event.target.value);
      };

      const handleChange6 = event => {
        //console.log(event.target.value);
        if (newUser.user_type_id==2 && event.target.value=='') {
          setBioError('Biosana ID# is required');
        } else {
            setBioError(null);
        }
    
        setMessage(event.target.value);
      }; 
      const handleChange7 = event => {
        //console.log(event.target.value);
        if (newUser.user_type_id==2 && event.target.value=='') {
            setDeaError('DEA# is required');
        } else {
            setDeaError(null);
        }
    
        setMessage(event.target.value);
      };
    const dispatch = useDispatch()

    const userTypes = useSelector(state => state.AuthReducer.userTypes)

    const handleNewUser = () => {
        dispatch(doRegisterUser(newUser))
    }
    //new
    const closeModal = () => {
    newUser.user_name='';
    newUser.user_first_name='';
    newUser.user_last_name='';
    newUser.password='';
    newUser.user_type_id= 0;
    newUser.user_type='Administrator';
    newUser.permission= false;
    newUser.sendToClient=true;
    newUser.biosana_id='';
    newUser.practice_name='';
    newUser.practioner_name='';
    newUser.dea_no='';
    setPasswordErr(null);
            setError(null)
            setOpen(false);
        }
    return (
        <>
            {open &&
                <Modal open={open} onClose={() => setOpen(false)}
                    BackdropComponent={Overlay}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <StyledContainer bgcolor='white' fontFamily='Poppins' color='white'>
                            <ModalHeader component='header'>
                                <Box component='h4' width='100%' lineHeight='110%' margin='0px' fontWeight='600' fontSize='20px' color='rgb(4,17,29)'>
                                    Create a new user
                                </Box>
                            </ModalHeader>
                            <ModalBody component='section'>
                                <Box>
                                    <VerifyHeader>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>Email</Box>
                                            <Box component='input' fontSize='14px' width='300px' type='email'
                                                value={newUser.user_name} onChange={(e) => {
                                                    handleChange(e);
                                                    let tmp = { ...newUser }
                                                    tmp.user_name = e.target.value
                                                    setNewUser(tmp)
                                                }}
                                                />
                                        </Box>
                                                {error && <Box width='230px' style={{color: 'red'}}>{error}</Box>}
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>First Name:</Box>
                                            <Box component='input' fontSize='14px' width='300px'
                                                value={newUser.user_first_name} onChange={(e) => {
                                                    handleChange4(e);
                                                    let tmp = { ...newUser }
                                                    tmp.user_first_name = e.target.value
                                                    setNewUser(tmp)
                                                }}
                                            />
                                        </Box>
                                            {ferror && <Box width='230px' style={{color: 'red'}}>{ferror}</Box>}
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>Last Name:</Box>
                                            <Box component='input' fontSize='14px' width='300px'
                                                value={newUser.user_last_name} onChange={(e) => {
                                                    handleChange5(e);
                                                    let tmp = { ...newUser }
                                                    tmp.user_last_name = e.target.value
                                                    setNewUser(tmp)
                                                }}
                                            />
                                        </Box>
                                            {lerror && <Box width='230px' style={{color: 'red'}}>{lerror}</Box>}
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>Password:</Box>
                                            <Box component='input' fontSize='14px' width='300px'  onKeyUp={handleValidation}
                                                value={newUser.password} onChange={(e) => {
                                                    let tmp = { ...newUser }
                                                    tmp.password = e.target.value
                                                    setNewUser(tmp)
                                                }}
                                            />
                                        </Box>
                                            {passwordError && <Box width='230px' style={{color: 'red'}}>{passwordError}</Box>}
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>User Type</Box>
                                            <ComboBox width='300px'>
                                                <Box component='select' width='100%'
                                                    value={newUser.user_type_id} onChange={(e) => {
                                                        let tmp = { ...newUser }
                                                        tmp.user_type_id = e.target.value
                                                        tmp.user_type = userTypes[parseInt(e.target.value)].user_type_name
                                                        setNewUser(tmp)
                                                        if(e.target.value==2)
                                                        {
                                                            //console.log(e.target.value,newUser);
                                                           if(newUser.practioner_name =="")
                                                           {
                                                                setPRError('Practioner Name is required');
                                                           }
                                                           if(newUser.practice_name =="")
                                                           {
                                                                setPError('Practice Name is required');
                                                           }
                                                           if(newUser.biosana_id =="")
                                                           {
                                                                setBioError('Biosana ID# is required');
                                                           }
                                                           if(newUser.dea_no=="")
                                                           {
                                                                setDeaError('DEA# is required');
                                                           }

                                                        }else{
                                                            setDeaError(null);
                                                            setBioError(null);
                                                            setPError(null);
                                                            setPRError(null);
                                                        }
                                                    }}
                                                >
                                                    {_.map(userTypes, (each, i) =>
                                                        <option key={i} value={i}>{each.user_type_name}</option>
                                                    )}
                                                </Box>
                                            </ComboBox>
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>Permission</Box>
                                            <ComboBox width='300px'>
                                                <Box component='select' width='100%'
                                                    value={newUser.permission} onChange={(e) => {
                                                        let tmp = { ...newUser }
                                                        tmp.permission = e.target.value
                                                        setNewUser(tmp)
                                                    }}
                                                >
                                                    <option value='0'>Blocked</option>
                                                    <option value='1'>Allowed</option>
                                                </Box>
                                            </ComboBox>
                                        </Box>
                                        {/* ///////////////////////////////////////////////////////////
                                        ////////////////////// New Update 4 fields/////////////////
                                        ///////////////////////////////////////////////////////////*/}
                                        {newUser.user_type_id == 2 ?

                                            (
                                                <Box>
                                                    <Box display='flex' alignItems='center' mt='15px'>
                                                        <Box width='230px'>Biosana ID#:</Box>
                                                        <Box component='input' fontSize='14px' width='300px'
                                                            value={newUser.biosana_id} onChange={(e) => {
                                                                handleChange6(e);
                                                                let tmp = { ...newUser }
                                                                tmp.biosana_id = e.target.value
                                                                setNewUser(tmp)
                                                            }}
                                                        />
                                                    </Box>
                                                        {biorerror && <Box width='230px' style={{color: 'red'}}>{biorerror}</Box>}

                                                    <Box display='flex' alignItems='center' mt='15px'>
                                                        <Box width='230px'> Practice Name: </Box>
                                                        <Box component='input' fontSize='14px' width='300px'
                                                            value={newUser.practice_name} onChange={(e) => {
                                                                handleChange2(e);
                                                                let tmp = { ...newUser }
                                                                tmp.practice_name = e.target.value
                                                                setNewUser(tmp)
                                                            }}
                                                        />
                                                    </Box>
                                                        {perror && <Box width='230px' style={{color: 'red'}}>{perror}</Box>}

                                                    <Box display='flex' alignItems='center' mt='15px'>
                                                        <Box width='230px'>Practioner Name:</Box>
                                                        <Box component='input' fontSize='14px' width='300px'
                                                            value={newUser.practioner_name} onChange={(e) => {
                                                                handleChange3(e);
                                                                let tmp = { ...newUser }
                                                                tmp.practioner_name = e.target.value
                                                                setNewUser(tmp)
                                                            }}
                                                        />
                                                    </Box>
                                                        {prerror && <Box width='230px' style={{color: 'red'}}>{prerror}</Box>}
                                                    <Box display='flex' alignItems='center' mt='15px'>
                                                        <Box width='230px'>DEA#:</Box>
                                                        <Box component='input' fontSize='14px' width='300px'
                                                            value={newUser.dea_no} onChange={(e) => {
                                                                handleChange7(e);
                                                                let tmp = { ...newUser }
                                                                tmp.dea_no = e.target.value
                                                                setNewUser(tmp)
                                                            }}
                                                        />
                                                    </Box>
                                                        {dearerror && <Box width='230px' style={{color: 'red'}}>{dearerror}</Box>}

                                                </Box>
                                            )

                                            : null
                                        }

                                    </VerifyHeader>
                                </Box>
                            </ModalBody>
                            <ModalFoot>
                                <Action mt='30px'>
                                    <MyButton variant='contained' color='primary' disabled={
                                        !(newUser.user_name !== '' &&
                                            newUser.user_first_name !== '' &&
                                            newUser.user_last_name !== '' &&
                                            newUser.password !== '' && error==null && passwordError==null && ferror==null  && lerror==null && perror==null && prerror==null && biorerror==null && dearerror==null)
                                    }
                                        onClick={() => {
                                            handleNewUser()
                                            setOpen(false)
                                        }}
                                    >
                                        Save</MyButton>
                                    {/* <MyButton variant='contained' color='secondary' onClick={() => setOpen(false)}>Cancel</MyButton> */}
                                    {/* new */}
                                    <MyButton variant='contained' color='secondary' onClick={() => closeModal()}>Cancel</MyButton>
                                </Action>
                            </ModalFoot>
                            <Box display='flex' position='absolute' right='24px' top='24px'>
                                <CloseButton component='button' onClick={() => setOpen(false)}>
                                    <CloseOutlined style={{ fontSize: 24 }} />
                                </CloseButton>
                            </Box>
                        </StyledContainer>
                    </Fade>
                </Modal >
            }
        </>
    )
}

const ComboBox = styled(Box)`
    border: 1px solid lightgrey;
    border-radius: 5px;
    &:focus {
        border-color: red;
    }
`

const VerifyHeader = styled(Box)`
    >div {
        @media (max-width: 1040px) {
            align-items: initial;
            flex-direction: column;
            >input {
                width: 100%;
            }
        }
    }
`

const Action = styled(Box)`
    >button+button {
        margin-left: 30px;
    }
`

const MyButton = styled(Button)`
    text-transform: none !important;
`

const Overlay = styled(Backdrop)`
    background-color: rgba(0, 0, 0, 0.8) !important;
`

const CloseButton = styled(Box)`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 100%;
    font-family: inherit;
    border: 0px;
    padding: 0px;
    background: inherit;
    >svg { color: rgb(138, 147, 155); }
    &:hover {
        >svg { color: rgb(112, 122, 131); }
    }
`

const ModalHeader = styled(Box)`
    padding: 24px;
    border-bottom: 1px solid rgb(229, 232, 235);
    >h4 { word-break: break-word; }
`

const ModalBody = styled(Box)`
    padding: 24px;
    height: 100%;
    overflow-y: auto;
    font-weight: 400;
    color: rgb(53, 56, 64);
`

const ModalFoot = styled(Box)`
    padding: 24px;
    text-align: right;
`

const StyledContainer = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: transparent;
    max-height: calc(100% - 50px);
    overflow-y: auto;
    width: 500px;
    @media (max-width: 1000px) {
        width: calc(100% - 32px);
    }
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-corner {
        background: inherit;
    }
    &::-webkit-scrollbar-thumb {
        width: 30px;
        border-radius: 15px;
        background: rgb(33,37,41);
    }
    input {
        outline: none;
        border: 1.5px solid lightgrey;
        border-radius: 5px;
        transition: .3s;
        padding: 5px;
        height: fit-content;
        align-self: center;
        &:focus {
            border-color: #c850c0;
        }
    }
    select {
        border: none;
        outline: none;
        padding: 4px;
        border-radius: 5px;
    }
`

export default NewUserModal