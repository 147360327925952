import { PELLET_CRUD } from 'src/redux/actions/types'
import _ from 'lodash'

const initState = {
    receipts: [],
}

export default function todo(state = initState, action) {
    let tmp
    switch (action.type) {
        case PELLET_CRUD.CREATE:
            return {
                ...state,
                receipts: action.receipts,
            }
        case PELLET_CRUD.READ:
            return {
                ...state,
                receipts: action.receipts
            }
        case PELLET_CRUD.UPDATE:
            const receiptData = action.updatedData.receiptData
            tmp = [...state.receipts]
            receiptData._id = action.updatedData.where
            tmp[_.findIndex(tmp, item => item._id === action.updatedData.where)] = action.updatedData.receiptData
            return {
                ...state,
                receipts: tmp,
            }
        case PELLET_CRUD.DELETE:
            tmp = [...state.receipts]
            tmp.splice(_.findIndex(e => e._id === action.id), 1)
            return {
                ...state,
                receipts: tmp
            }
        default:
            return state
    }
}