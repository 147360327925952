import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { doGetWholeUsers, doGetWholeUserTypes } from 'src/redux/actions/AuthAction'
import { doGetWholeInv,doGetWholeInvDel } from 'src/redux/actions/InvAction'
import { doGetWholeReceipts,doGetWholeReceiptsDel } from 'src/redux/actions/PelletAction'
import { doGetWholeDispense, doGetWholeProcedure, doGetWholeDoses,doGetWholeDispenseDel,doGetWholeProcedureDel } from 'src/redux/actions/PatientAction'
import { doGetMTD, doGetAddress, doGetPatient } from 'src/redux/actions/ReportAction'
// import { doGetMTD } from 'src/redux/actions/ReportAction'

const Dispatcher = ({ children }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(doGetWholeUsers())
        dispatch(doGetWholeUserTypes())
        dispatch(doGetWholeInv())
        dispatch(doGetWholeProcedure())
        dispatch(doGetWholeDoses())
        dispatch(doGetWholeReceipts())
        dispatch(doGetWholeDispense())
        dispatch(doGetMTD());
        dispatch(doGetPatient());
        dispatch(doGetAddress());
        dispatch(doGetWholeInvDel())
        dispatch(doGetWholeReceiptsDel())
        dispatch(doGetWholeDispenseDel())
        dispatch(doGetWholeProcedureDel())

    }, [dispatch])

    return (
        <>
            {children}
        </>
    )
}

Dispatcher.propTypes = {
    children: PropTypes.node.isRequired
}

export default Dispatcher