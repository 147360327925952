import { useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Box, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import _ from 'lodash'
import jwtDecode from 'jwt-decode'

import { doAddNewReceipt } from 'src/redux/actions/PelletAction'
import { doseTypes } from 'src/entry'

const ReceiptAdd = () => {
    let uniqueIndex = -1
    let doseTotalLength = 0

    doseTypes.forEach(each => {
        doseTotalLength += each.sizes.length
    })

    const history = useHistory()
    const dispatch = useDispatch()

    const user = localStorage.getItem('jwtToken')
    const receipts = useSelector(state => state.PelletReducer.receipts)
    // if adminstrator then forward to home page
    if (jwtDecode(user).userType == "Administrator") {
        history.push('/');
    }

    const [info, setInfo] = useState({
        'pellet_date': `${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '0' + new Date().getDate() : new Date().getDate()}`,
        'pellet_receipt_invoice': '',
        'pellet_receipt_verified_by': '',
        'created_by': jwtDecode(user).userName,
        'practice_id':jwtDecode(user).id,
    })

    const [quantity, setQuantity] = useState(Array(doseTotalLength).fill(''))
    const [lotSharp, setLotSharp] = useState(Array(doseTotalLength).fill(''))
    const [expDates, setExpDates] = useState(Array(doseTotalLength).fill(''))
    const [pricePer, setPricePer] = useState(Array(doseTotalLength).fill(''))
    const [inputValid, setInputValid] = useState(Array(doseTotalLength).fill())

    const [validFlags, setValidFlags] = useState({
        verify: [true, false, false],
    })
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    function containsAny(str, substrings) {
        for (var i = 0; i != substrings.length; i++) {
           var substring = substrings[i];
           if (str.indexOf(substring) != - 1) {
             return substring;
           }
        }
        return null; 
    }
    const handleQtyInput = (e) => {
        const i = parseInt(e.target.name)
        let if_qty_correct=containsAny(e.target.value, exceptThisSymbols)
        // console.log(if_qty_correct)
        setQuantity(prev => { const tmp = [...prev]; tmp[i] = e.target.value; return tmp })
        setInputValid(prev => {
            const tmp = [...prev]
            if (e.target.value === '' && lotSharp[i] === '' && expDates[i] === '' ) tmp[i] = undefined
            else if (e.target.value === '' || lotSharp[i] === '' || expDates[i] === '' ) tmp[i] = false
            else tmp[i] =
                (e.target.value === '' && lotSharp[i] === '' && expDates[i] === '' && if_qty_correct !==null) ||
                (e.target.value !== '' && lotSharp[i] !== '' && expDates[i] !== '' && if_qty_correct ===null)
            return tmp
        })
    }

    const handleLotInput = (e) => {
        const i = parseInt(e.target.name)
        let if_qty_correct=containsAny(quantity[i], exceptThisSymbols)
        // console.log(if_qty_correct)
        setLotSharp(prev => { const tmp = [...prev]; tmp[i] = e.target.value; return tmp })
        setInputValid(prev => {
            const tmp = [...prev]
            if (quantity[i] === '' && e.target.value === '' && expDates[i] === '' ) tmp[i] = undefined
            else if (quantity[i] === '' || e.target.value === '' || expDates[i] === '' ) tmp[i] = false
            else tmp[i] =
                (quantity[i] === '' && e.target.value === '' && expDates[i] === '' && if_qty_correct !==null) ||
                (quantity[i] !== '' && e.target.value !== '' && expDates[i] !== ''  && if_qty_correct ===null)
            return tmp
        })
    }

    const handleExpDates = (e) => {
        const i = parseInt(e.target.name)
       let if_qty_correct=containsAny(quantity[i], exceptThisSymbols)
    //    console.log(if_qty_correct)
        setExpDates(prev => { const tmp = [...prev]; tmp[i] = e.target.value; return tmp })
        setInputValid(prev => {
            const tmp = [...prev]
            if (quantity[i] === '' && lotSharp[i] === '' && e.target.value === '' ) tmp[i] = undefined
            else if (quantity[i] === '' || lotSharp[i] === '' || e.target.value === '' ) tmp[i] = false
            else tmp[i] =
                (quantity[i] === '' && lotSharp[i] === '' && e.target.value === '' && if_qty_correct !==null) ||
                (quantity[i] !== '' && lotSharp[i] !== '' && e.target.value !== '' && if_qty_correct ===null)
            return tmp
        })
    }

    const handlePricePer = (e) => {
        const i = parseInt(e.target.name)
        setPricePer(prev => { const tmp = [...prev]; tmp[i] = e.target.value; return tmp })
    }
   
    const handleNewPelletReceipt = () => {
        // console.log(jwtDecode(user))
        let formData = { receiptData: info, doseData: [] }
        // console.log(formData.receiptData)
        let cnt = 0
        doseTypes.forEach(type => {
            type.sizes.forEach(size => {
                if (quantity[cnt] !== '' && lotSharp[cnt] !== '' && expDates[cnt] !== '')
                    formData.doseData.push({
                        'dose_name_type': type.name,
                        'dose_size': size,
                        'dose_qty': quantity[cnt],
                        'dose_lot_number': lotSharp[cnt],
                        'pellet_exp_date': expDates[cnt],
                        'dose_total': quantity[cnt],
                        'pellet_date': info.pellet_date,

                    })
                cnt++
            })
        })
        if (formData.doseData.length > 0) {
            dispatch({ type: 'SET_WAITING', isWaiting: true })
            const timer = setTimeout(() => {
                dispatch(doAddNewReceipt(formData, history, true,receipts))
                const innerTimer = setTimeout(() => {
                    dispatch({ type: 'SET_WAITING', isWaiting: false })
                    clearInterval(innerTimer)
                }, 2000)
                clearInterval(timer)
            }, 3000)
        }
        else
            alert("nothing to add")
    }

    return (
        <StyledContainer>
            <Box fontSize='20px' fontWeight='600'>Pellet Receipt</Box>
            {/* <Box fontSize='20px' fontWeight='600'>New Pellet</Box> */}
            <VerifyHeader>
                <Box display='flex' alignItems='center'>
                    <Box width='230px'>Date:</Box>
                    <Box component='input' fontSize='14px' width='300px' type='date'
                        value={info.pellet_date} onChange={(e) => {
                            setInfo(_.merge({ ...info }, info, info.pellet_date = e.target.value))
                            setValidFlags(_.merge({ ...validFlags }, validFlags, e.target.value !== '' ? validFlags.verify[0] = true : validFlags.verify[0] = false))
                        }}
                        max="9999-12-31"
                    />
                </Box>
                <Box display='flex' alignItems='center' mt='15px'>
                    <Box width='230px'>Receipt Inv #:</Box>
                    <Box component='input' fontSize='14px' width='300px'
                        value={info.pellet_receipt_invoice} onChange={(e) => {
                            setInfo(_.merge({ ...info }, info, info.pellet_receipt_invoice = e.target.value))
                            setValidFlags(_.merge({ ...validFlags }, validFlags, e.target.value !== '' ? validFlags.verify[1] = true : validFlags.verify[1] = false))
                        }}
                    />
                </Box>
                <Box display='flex' alignItems='center' mt='15px'>
                    <Box width='230px'>Receipt Verified By:</Box>
                    <Box component='input' fontSize='14px' width='300px'
                        value={info.pellet_receipt_verified_by} onChange={(e) => {
                            setInfo(_.merge({ ...info }, info, info.pellet_receipt_verified_by = e.target.value))
                            setValidFlags(_.merge({ ...validFlags }, validFlags, e.target.value !== '' ? validFlags.verify[2] = true : validFlags.verify[2] = false))
                        }}
                    />
                </Box>
            </VerifyHeader>
            {_.map(doseTypes, (each, i) =>
                <Box key={i} mt='30px'>
                    <FormHead display='flex' flexWrap='wrap'>
                        <Box width='280px' fontSize='20px' fontWeight='600'>{each.name} / Dose </Box>
                        <Box width='200px' ml='50px' fontSize='20px' fontWeight='600'>Lot # </Box>
                        <Box width='200px' ml='50px' fontSize='20px' fontWeight='600'>Exp Date </Box>
                        <Box width='200px' ml='50px' fontSize='20px' fontWeight='600' display="none">Price Per Dose </Box>
                    </FormHead>
                    <FormBody>
                        {_.map(each.sizes, eac => {
                            uniqueIndex++
                            return <Box key={uniqueIndex} display='flex' flexWrap='wrap'>
                                <Box display='flex' justifyContent='flex-start' alignItems='center'>
                                    <Box width='100px' alignSelf='center'>{eac} mg</Box>
                                    <Box width='130px' component='input' type='number' name={`${uniqueIndex}`} value={quantity[uniqueIndex]}  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={handleQtyInput} />
                                    <Box ml='20px'>Qty</Box>
                                </Box>
                                <Box width='200px' ml='50px' component='input' name={`${uniqueIndex}`} value={lotSharp[uniqueIndex]} onChange={handleLotInput} />
                                <Box width='200px' ml='50px' component='input' {...{ type: 'date' }} name={`${uniqueIndex}`} value={expDates[uniqueIndex]} onChange={handleExpDates} max="9999-12-31" />
                                <Box width='200px' ml='50px' mr='50px' component='input' name={`${uniqueIndex}`} value={pricePer[uniqueIndex]} onChange={handlePricePer} display="none" />
                                {inputValid[uniqueIndex] === false && <Alert severity='error'>Error</Alert>}
                            </Box>
                        }
                        )}
                    </FormBody>
                </Box>
            )}
            <Action mt='30px'>
                <MyButton variant='contained' color='primary' disabled={
                    validFlags.verify.some(e => e === false) ||
                    inputValid.some(e => e === false) ||
                    !inputValid.some(e => e === true || e === false)
                }
                    onClick={handleNewPelletReceipt}
                >
                    Save
                </MyButton>
                <MyButton variant='contained' color='secondary' onClick={() => history.goBack()}>Cancel</MyButton>
            </Action>
        </StyledContainer >
    )
}

const VerifyHeader = styled(Box)`
    >div {
        @media (max-width: 970px) {
            align-items: initial;
            flex-direction: column;
            >input {
                width: 100%;
            }
        }
    }
`

const Action = styled(Box)`
    display: flex;
    justify-content: flex-end;
    >button+button {
        margin-left: 30px;
    }
`

const MyButton = styled(Button)`
    text-transform: none !important;
`

const FormHead = styled(Box)`
    @media (max-width: 970px) {
        >div {
            flex: 1;
        }
        >div:not(:first-of-type) {
            margin-left: unset;
            display: none;
        }
    }
`

const FormBody = styled(Box)`
    >div+div {
        margin-top: 15px;
    }
    >div >div {
        @media (max-width: 970px) {
            width: 100%;
            >input {
                flex: 1;
            }
        }
    }
    >div >input, >div >div:last-of-type {
        @media (max-width: 970px) {
            margin-left: unset;
            margin-right: unset;
            margin-top: 15px;
            width: 100%;
        }
    }
`

const StyledContainer = styled(Box)`
    padding: 20px;
    input {
        outline: none;
        border: 1.5px solid lightgrey;
        border-radius: 5px;
        transition: .3s;
        padding: 5px;
        height: fit-content;
        align-self: center;
        &:focus {
            border-color: #c850c0;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
`

export default ReceiptAdd