import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import _ from 'lodash'

import FilterBar from './FilterBar'
import Exporter from 'src/components/Exporter'

import BackImg from 'src/assets/images/backs/procedure_bg.jpg'

import { doseTypes } from 'src/entry'
import { doGetMTD, doGetAddress, doGetPatient } from 'src/redux/actions/ReportAction'
import Pagination from 'src/components/Paginations'
import jwtDecode from 'jwt-decode'


const LookupDetail = () => {

    // Start practice dropdown
    const practiceList = useSelector(state => state.AuthReducer.users).filter(u => u.user_type == "Practice")
    const [practice, setpractice] = useState("All");
    // End practice dropdown

    const tBodyRef = useRef()
    const [pushed, setPushed] = useState(true)
    const [check_patient, set_patient] = useState([])
    const [check_record_no, set_record_no] = useState([])
    const [check, set_check] = useState([true, true, true, true, true, true, true, true, true, true])
    const patient_dispence = useSelector(state => state.PatientReducer.dispense)
    let filtered_patient_dispence = [];
    let filtered_patient_dispence_filter=[];
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    const [activity_date, set_activity_date] = useState(``)
    const [activity_date1, set_activity_date1] = useState(``)

    const user = localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken'))
    const userTypes = JSON.parse(localStorage.getItem('uid'))
    const [cstep, setCStep] = useState(15)
    const [ostep, setOStep] = useState(15)
    const [title, setTitle] = useState('Lookup Detail Report');
    const [ptitle, setPTitle] = useState('Practice: All');

    // filter doses by current user 
    if (user.userType == "Practice") {
        var user_name_all=user.userFName+' '+user.userLName+' ('+user.practice_name+' '+user.practioner_name+')';
        // patient dspense
        for (let i = 0; i < patient_dispence?.length; i++) {
            //if (patient_dispence[i].practice_name == user.userName) {
            if (patient_dispence[i].practice_name == user.userName || patient_dispence[i].practice_name == user_name_all){
                filtered_patient_dispence.push(patient_dispence[i]);
            }
        }
    }
    else if (user.userType == "Administrator") {
        if (practice != "All") {
            let  prac_sp = practice.split("/");
            // patient dspense
            for (let i = 0; i < patient_dispence?.length; i++) {
                // if (patient_dispence[i].practice_name == practice) {
                    if (patient_dispence[i].practice_name == prac_sp[0] || patient_dispence[i].practice_name == prac_sp[1]) {
                    filtered_patient_dispence.push(patient_dispence[i]);
                }
            }
        }
        else {
            filtered_patient_dispence = patient_dispence;
        }
    }
    let totalrows=filtered_patient_dispence?.length;
    filtered_patient_dispence_filter=filtered_patient_dispence;
    // console.log(pushed,totalrows);
    // if(keyword !='')
    // {
    //     totalrows=0;

    //     for(let x=0;x<filtered_patient_dispence?.length;x++)
    //     {
    //         if((flags[0] && users[x]?.user_name.search(keyword) !== -1) ||
    //         (flags[1] && users[x].user_type.search(keyword) !== -1) ||
    //         (flags[2] && users[x].user_first_name.search(keyword) !== -1) ||
    //         (flags[3] && users[x].user_last_name.search(keyword) !== -1))
    //         {
    //             totalrows++;
    //         }
    //     }
    // }

    useEffect(() => {
        //console.log(patient)
        dispatch(doGetMTD());
        dispatch(doGetPatient());
        dispatch(doGetAddress());
    }, [dispatch])

    const sum_qty = (value, id) => {
        let sum = 0;
        for (let i = 0; i < value?.length; i++) {
            if (value[i].dose_size_id === id) {
                sum += parseInt(value[i].dose_qty);
            }
        }
        return sum;
    }

    //paging in filter
   // console.log(check_patient,check_record_no,activity_date,activity_date1);
    if(check_patient.includes(true))
    {
        //console.log(check_patient,check_record_no,activity_date,activity_date1,'1');
         let list_check=[];
          filtered_patient_dispence_filter=[];
     //console.log(check_patient);
        for(let x=0;x<check_patient.length;x++)
        {
            if(check_patient[x]==true)
            {
                var fullname=filtered_patient_dispence[x].patient_first_name + ' ' + filtered_patient_dispence[x].patient_last_name;
                 list_check.push(fullname);
            }
        }
     //console.log(list_check);
        for(let x=0;x<filtered_patient_dispence.length;x++)
        {
            var fullname=filtered_patient_dispence[x].patient_first_name + ' ' + filtered_patient_dispence[x].patient_last_name;
            if(activity_date !='' && activity_date1 !='')
            {
                if( (activity_date <= filtered_patient_dispence[x]?.dispense_date.slice(0, 10)) && (filtered_patient_dispence[x]?.dispense_date.slice(0, 10) <= activity_date1) && !list_check.includes(fullname))
                {
                    //console.log(fullname);
                    filtered_patient_dispence_filter.push(filtered_patient_dispence[x]);
                
                }

            }else{

                if(!list_check.includes(fullname))
                {
                    //console.log(fullname);
                    filtered_patient_dispence_filter.push(filtered_patient_dispence[x]);
                
                }
            }
        }

    }
//console.log(check_record_no);
    if(check_record_no.includes(true))
    {
       // console.log(check_patient,check_record_no,activity_date,activity_date1,'2');
         let list_check=[];
          filtered_patient_dispence_filter=[];

        for(let x=0;x<check_record_no.length;x++)
        {
            if(check_record_no[x]==true)
            {

                 list_check.push(filtered_patient_dispence[x].procedure_id);
            }
        }
     //console.log(list_check);
        for(let x=0;x<filtered_patient_dispence.length;x++)
        {
           
            if(!list_check.includes(filtered_patient_dispence[x].procedure_id))
            {
                //console.log(filtered_patient_dispence[x].procedure_id);
                filtered_patient_dispence_filter.push(filtered_patient_dispence[x]);
            
            }
        }
    }
    if(activity_date !='' && activity_date1 !='')
    {
       // console.log(check_patient,check_record_no,activity_date,activity_date1,'3');
        // console.log('ffffff');
        let list_check=[];
        if(check_patient.includes(true))
        {
            //console.log(check_patient,check_record_no,activity_date,activity_date1,'1');
            
        //console.log(check_patient);
            for(let x=0;x<check_patient.length;x++)
            {
                if(check_patient[x]==true)
                {
                    var fullname=filtered_patient_dispence[x].patient_first_name + ' ' + filtered_patient_dispence[x].patient_last_name;
                    list_check.push(fullname);
                }
            }
        //console.log(list_check);

        }
        filtered_patient_dispence_filter=[];
        for(let x=0;x<filtered_patient_dispence.length;x++)
        {
            var fullname=filtered_patient_dispence[x].patient_first_name + ' ' + filtered_patient_dispence[x].patient_last_name;
            if((activity_date <= filtered_patient_dispence[x]?.dispense_date.slice(0, 10)) && (filtered_patient_dispence[x]?.dispense_date.slice(0, 10) <= activity_date1) && !list_check.includes(fullname))
            {
                filtered_patient_dispence_filter.push(filtered_patient_dispence[x]);
            
            }
        }
    }
    return (
        <>
            <Exporter tBodyRef={tBodyRef} setPushed={setPushed} setCStep={setCStep} totalColumn={filtered_patient_dispence?.length} oldStep={15} currentTable={'tablert'} emptyBox={'emptyBox'} title={title} ptitle={ptitle} currentuser={user.userType} practiceHere={'practiceHere'} currentpractice={practice}/>
            <StyledContainer>
                <FilterBar pushed={pushed} setPushed={setPushed}
                    check_patient={check_patient} set_patient={set_patient} check_record_no={check_record_no} set_record_no={set_record_no}
                    activity_date={activity_date} set_activity_date={set_activity_date} activity_date1={activity_date1} set_activity_date1={set_activity_date1} check={check} set_check={set_check} />
                <Wrapper flex='1' position='relative' overflow='auto'>
                    <Background />
                    <Box {...{ ref: tBodyRef }} padding='20px'>
                        <Box fontSize='20px' fontWeight='600'>Lookup Detail Report</Box>

                        <br /> <br />
                        {user.userType == "Practice" ?
                            (
                                <Box id="practiceHere">
                                    <Box fontSize='16px' fontWeight='600'>  Biosana ID#:  <span style={{ color: '#666666', fontStyle: 'italic' }}> {userTypes.biosana_id} </span> </Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practice Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practice_name} </span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practioner Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practioner_name}</span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  DEA#:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.dea_no} </span> </Box>
                                </Box>
                            )
                            : null}
                        <br />

                        {user.userType == "Administrator" ?
                            (
                                // show practice dropdown
                                <Box display='flex' alignItems='center' mt='15px' style={{
                                    display: "inline-block", float: "right", "margin-top": "-100px"
                                }}>
                                    <Box width='100px' float="right">Practice:</Box>
                                    <select onChange={(e) => {
                                        // handle practice change

                                        setpractice(e.target.value);
                                        var sval=e.target.value.split("/")
                                        var new_title='Practice: '+sval[0];
                                        setPTitle(new_title);
                                    }}>
                                        <option selected value="All">All</option>
                                        {
                                            //practiceList.map((u) => <option value={u.user_name} key={u.UsernameKey}>{u.user_name}</option>)
                                            //new
                                            // practiceList.map((u) => <option value={u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'+'/'+u.user_name} key={u.UsernameKey}>{u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                            practiceList.map((u) => <option value={u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'+'/'+u.user_name+'/'+u.biosana_id} key={u.UsernameKey}>{u.biosana_id+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                        }
                                    </select>
                                </Box>
                            )
                            : null}
                        <br />

                        <Box width='100%' boxShadow='2px 2px 5px 1px #ccc' mt={3} >
                                <Box id='emptyBox'></Box>
                            <Table width='100%' component='table' id='tablert'>
                                <THead component='thead'>
                                    <tr>
                                        <th>Patient Name</th>
                                        <th>Dispense Date</th>
                                        <th width='10%'>Procedure Record No#</th>
                                        {/* {_.map(doseTypes[0].sizes, (each, i) =>
                                            <th key={i}>E{each}</th>
                                        )}
                                        {_.map(doseTypes[1].sizes, (each, i) =>
                                            <th key={i}>T{each}</th>
                                        )} */}
                                        {check[0] &&<th>E6</th>}
                                        {check[1] &&<th>E10</th>}
                                        {check[2] &&<th>E12.5</th>}
                                        {check[3] &&<th>E15</th>}
                                        {check[4] &&<th>T25</th>}
                                        {check[5] &&<th>T37.5</th>}
                                        {check[6] &&<th>T50</th>}
                                        {check[7] &&<th>T87.5</th>}
                                        {check[8] &&<th>T100</th>}
                                        {check[9] &&<th>T200</th>}


                                    </tr>
                                </THead>
                                <TBody component='tbody' pb='20px'>

                                    {/* {_.map(_.filter(filtered_patient_dispence, (e, uIndex) => uIndex >= 15 * (page - 1) && uIndex <= 15 * (page - 1) + 14), (each, i) => */}
                                    {_.map(_.filter(filtered_patient_dispence_filter, (e, uIndex) => uIndex >= cstep * (page - 1) && uIndex <= cstep * (page - 1) + (cstep-1)), (each, i) =>
                                        <tr key={i} id={i}>
                                            {/* {(!check_patient[i] && !check_record_no[i] && (activity_date === '' || (activity_date <= each.dispense_date.slice(0, 10) && activity_date1 >= each.dispense_date.slice(0, 10)))) && */}
                                            {/* {((activity_date === '' || (activity_date <= each.dispense_date.slice(0, 10) && activity_date1 >= each.dispense_date.slice(0, 10)))) && */}
                                                <>
                                                    <td>{each.patient_first_name + ' ' + each.patient_last_name}</td>
                                                    <td>{each.dispense_date.slice(0, 10)}</td>
                                                    <td>{each.procedure_id}</td>
                                                    {/* <td>{_.map(each.inputedDoses[0], (each,i)=>{
                                                console.log(each)
                                            })}</td> */}
                                                   {check[0] &&<td>{sum_qty(each.inputedDoses[0], '0')}</td>}
                                                   {check[1] &&<td>{sum_qty(each.inputedDoses[0], '1')}</td>}
                                                    {check[2] &&<td>{sum_qty(each.inputedDoses[0], '2')}</td>}
                                                    {check[3] &&<td>{sum_qty(each.inputedDoses[0], '3')}</td>}
                                                    {check[4] &&<td>{sum_qty(each.inputedDoses[1], '0')}</td>}
                                                    {check[5] &&<td>{sum_qty(each.inputedDoses[1], '1')}</td>}
                                                    {check[6] &&<td>{sum_qty(each.inputedDoses[1], '2')}</td>}
                                                    {check[7] &&<td>{sum_qty(each.inputedDoses[1], '3')}</td>}
                                                    {check[8] &&<td>{sum_qty(each.inputedDoses[1], '4')}</td>}
                                                    {check[9] &&<td>{sum_qty(each.inputedDoses[1], '5')}</td>}
                                                    {/* {_.map(each.inputedDoses[0], (each,i) =>
                                                <td>123</td>

                                            )} */}
                                                </>
                                            {/* } */}
                                        </tr>

                                    )}
                                    {/* <td>Beshay, Mena</td>
                                        <td>9/9/2021</td>
                                        <td>1</td>
                                        {_.map(doseTypes[0].sizes, (each, i) =>
                                            <td key={i}>5</td>
                                        )}
                                        {_.map(doseTypes[1].sizes, (each, i) =>
                                            <td key={i}>5</td>
                                        )} */}
                                </TBody>
                            </Table>
                        </Box>

                    </Box>
                    <TFoot mt='20px' textAlign='right'>
                        {/* {(filtered_patient_dispence && filtered_patient_dispence?.length > 0) && <Pagination step={15} totalCount={filtered_patient_dispence?.length} page={page} setPage={setPage} />} */}
                        {(filtered_patient_dispence_filter && filtered_patient_dispence_filter?.length > 0) && <Pagination step={cstep} totalCount={filtered_patient_dispence_filter?.length} page={page} setPage={setPage} />}
                    </TFoot>
                </Wrapper>
            </StyledContainer>
        </>)
}

const TFoot = styled(Box)``

const Background = styled(Box)`
    position: absolute;
    width: 100%; height: 100%;
    background: url(${BackImg});
    background-size: 100% 100%;
    opacity: .15;
    user-select: none;
    pointer-events: none;
`

const THead = styled(Box)``

const TBody = styled(Box)``

const Table = styled(Box)`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: white;
        font-weight: 600;
    }
    tbody tr {
        transition: .3s;
        background-color: white;
        border-top: 1px solid lightgrey;
    }
    tr:hover {background-color: #eee;}
    th, td {
        padding: 8px;
    }
`
const Wrapper = styled(Box)`
    // background: url(${BackImg});
    // background-size: 100% 100%;
`

const StyledContainer = styled(Box)`
    display: flex;
    flex: 1;
`

export default LookupDetail