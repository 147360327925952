import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Box, Button, Modal, Fade, Backdrop } from '@material-ui/core'
import styled from 'styled-components'
import _ from 'lodash'

import FormBodyInv from 'src/pages/InvAdjustment/FormBodyInv'

import { doseTypes, multiplier } from 'src/entry'
import { doAddNewInv } from 'src/redux/actions/InvAction'
import jwtDecode from 'jwt-decode'

const InvAddModal = ({ open, setOpen }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const user = localStorage.getItem('jwtToken')
    const doses = useSelector(state => state.PatientReducer.doses)

    const users = useSelector(state => state.AuthReducer.users).filter(u => u.user_type == "Practice")

    const [info, setInfo] = useState({
        'inv_date': `${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '0' + new Date().getDate() : new Date().getDate()}`,
        'practice_name': '',
        'inv_receipt_verified_by': '',
        'created_by': jwtDecode(user).userName,
        'practice_id':'',
    })

    const [practice, setpractice] = useState("")
    //  const [dosesS, setdoses] =useState([])
    const [showError, setShowError] = useState(false)
    const [quantity, setQuantity] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [lotSharp, setLotSharp] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [lotSharpQuantity, setlotSharpQuantity] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [expDates, setExpDates] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [sizeID, setSizeID] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '0')))
    const [inputValid, setInputValid] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => undefined)))
    const [overflowQty, setOverflowQty] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => 0)))
    const [doseID, setDoseID] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '0')))
    const [validFlags, setValidFlags] = useState([true, false, false])

    useEffect(() => {

        if (open) {

            setShowError(false)
            setQuantity(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
            setLotSharp(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
            setExpDates(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
            setSizeID(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '0')))
            setInputValid(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => undefined)))
            setOverflowQty(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => 0)))
            setValidFlags([true, false, false])
        }
    }, [open])

    const handleNewInv = () => {

        let selectedDoses = []
        let dosesAll = [...doses]
        let inputedDoses = [[], []]

         //console.log("dosesAll", dosesAll,practice);

        setShowError(true)
        _.forEach(quantity, (e1, i) => {
            _.forEach(e1, (e2, j) => {

                if (e2 !== '' && lotSharp[i][j] !== '' && expDates[i][j] !== '')
                    inputedDoses[i].push({
                        'dose_size_id': sizeID[i][j],
                        'dose_qty': quantity[i][j],
                        'dose_lot_number': lotSharp[i][j],
                        'pellet_exp_date': expDates[i][j],
                        'input_valid': inputValid[i][j],
                        'overflow_qty': overflowQty[i][j],
                        'dose_id':doseID[i][j]
                    })
            })
        })
        if (validFlags.some(e => e === false))
            alert('credential error')
        else {
            if(practice==null || practice=="Select" || practice=="")
            {
                alert('Select Practice')
                return;
            }
            if (inputValid.join(',').search('true') === -1 && inputValid.join(',').search('false') === -1) {
                alert('empty values')
            }
            else
                if (inputValid.join(',').search('false') === -1) {
                    _.forEach(sizeID, (eachI, i) => {
                        _.forEach(eachI, (eachJ, j) => {
                            console.log(lotSharpQuantity[i][j]);
                            //let eachLot = _.filter(dosesAll, dose => dose.dose_size === doseTypes[i].sizes[eachJ].toString() && dose.dose_lot_number === lotSharp[i][j])[0]
                            let eachLot = _.filter(dosesAll, dose => dose.dose_size === doseTypes[i].sizes[eachJ].toString() && dose.dose_lot_number === lotSharp[i][j] && dose.dose_qty === lotSharpQuantity[i][j])[0]
                            if (eachLot) {
                                //Adds new if doesn't exist on type
                                if (_.findIndex(selectedDoses, e => e._id === eachLot._id) === -1) {
                                    let tmp = { ..._.filter(dosesAll, dose => dose._id === eachLot._id)[0] }
                                    tmp.dose_qty = parseFloat(_.filter(dosesAll, dose => dose._id === eachLot._id)[0].dose_qty) - parseFloat(quantity[i][j])
                                    selectedDoses.push(tmp)
                                }
                                //Subtracts if exists
                                else {
                                    selectedDoses[_.findIndex(selectedDoses, e => e._id === eachLot._id)].dose_qty -= parseFloat(quantity[i][j])
                                }
                            }
                        })
                    })
                    if (_.some(selectedDoses, e => e.dose_qty < 0)){ alert("there is minus!!!");}
                    else {
                        //console.log(selectedDoses);
                        let form = { invData: info, selDoses: selectedDoses }
                        form.invData.inputedDoses = inputedDoses
                        dispatch(doAddNewInv(form, history))
                        setOpen(false)
                    }
                }
                else
                    alert('validation error')


        }
    }

    const returnFormBodies = (i, each) =>

        _.map(Array(multiplier).fill(''), (e, j) =>

            <FormBodyInv
                key={i * 2 + j} i={i} j={j} each={each}
                showError={showError}
                quantity={quantity[i][j]} setQuantity={setQuantity}
                lotSharp={lotSharp[i][j]} setLotSharp={setLotSharp}
                expDates={expDates[i][j]} setExpDates={setExpDates}
                overflowQty={overflowQty[i][j]} setOverflowQty={setOverflowQty}
                sizeID={sizeID[i][j]} setSizeID={setSizeID}
                inputValid={inputValid[i][j]} setInputValid={setInputValid}
                practice={practice}
                doseID={doseID[i][j]} setDoseID={setDoseID}
                lotSharpQuantity={lotSharpQuantity[i][j]} setlotSharpQuantity={setlotSharpQuantity}
            />
        )
    //new
    const closeModal = () => {
 
        info.inv_receipt_verified_by='';
        setOpen(false);
    }
    return (
        <>
            {open &&
                <Modal open={open} onClose={() => setOpen(false)}
                    BackdropComponent={Overlay}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <StyledContainer>
                            <Box fontSize='20px' fontWeight='600'>  Pharmacy </Box>
                            <VerifyHeader>
                                <Box display='flex' alignItems='center'>
                                    <Box width='230px'>Date:</Box>
                                    <Box component='input' fontSize='14px' width='300px' type='date'
                                        value={info.inv_date} onChange={(e) => {
                                            setInfo(prev => { const tmp = { ...prev }; tmp.inv_date = e.target.value; return tmp })
                                            setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[0] = true : tmp[0] = false; return tmp })
                                        }}
                                        max="9999-12-31"
                                    />
                                </Box>
                                <Box display='flex' alignItems='center' mt='15px' >
                                    <Box width='230px'>Practice:</Box>
                                    <ComboBox width='523px'>
                                    <select id="sel_pra" onChange={(e) => {

                                        setInfo(prev => { const tmp = { ...prev }; tmp.practice_name = e.target.value; return tmp })
                                        setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[1] = true : tmp[1] = false; return tmp })                                        
                                        //setpractice(e.target.value)
                                        setpractice(e.target[e.target.selectedIndex].id)
                                        setInfo(prev => { const tmp = { ...prev }; tmp.practice_id = e.target[e.target.selectedIndex].id; return tmp })
                                        // console.log(e.target[e.target.selectedIndex].id);
                                    }}>
                                        <option value="Select">Select Practice</option>
                                        {
                                            // users.map((u) => <option value={u.UsernameKey} key={u.UsernameKey} id={u._id}>{'('+u.user_first_name+' '+u.user_last_name+') '+' '+ u.user_name}</option>)
                                            users.map((u) => <option value={u.UsernameKey} key={u.UsernameKey} id={u._id}>{u.biosana_id+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                        }
                                    </select>
                                    </ComboBox>
                                    { /*  <Box component='input' fontSize='14px' width='300px'
                                        value={info.inv_receipt_invoice} onChange={(e) => {
                                            setInfo(prev => { const tmp = { ...prev }; tmp.inv_receipt_invoice = e.target.value; return tmp })
                                            setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[1] = true : tmp[1] = false; return tmp })
                                        }}
                                    />*/
                                    }
                                </Box>
                                <Box display='flex' alignItems='center' mt='15px'>
                                    <Box width='230px'>Receipt Verified By:</Box>
                                    <Box component='input' fontSize='14px' width='300px'
                                        value={info.inv_receipt_verified_by} onChange={(e) => {
                                            setInfo(prev => { const tmp = { ...prev }; tmp.inv_receipt_verified_by = e.target.value; return tmp })
                                            setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[2] = true : tmp[2] = false; return tmp })
                                        }}
                                    />
                                </Box>
                            </VerifyHeader>
                            {_.map(doseTypes, (each, i) =>
                                <Box key={i} mt='30px'>
                                    <Box fontSize='20px' fontWeight='600'>{each.name}:</Box>
                                    <FormHead display='flex' flexWrap='wrap'>

                                        <BlankColumn width='150px' fontSize='20px' fontWeight='600' />
                                        <Box width='200px' fontSize='20px' fontWeight='600'>Qty</Box>
                                        <Box width='180px' ml='50px' fontSize='20px' fontWeight='600'>Lot</Box>
                                        <Box width='200px' ml='50px' fontSize='20px' fontWeight='600'>Exp Date</Box>
                                    </FormHead>
                                    <FormBodyWrapper>

                                        {
                                            //  console.log("form mmmmmmmmm",i , each)
                                            returnFormBodies(i, each)
                                        }
                                    </FormBodyWrapper>
                                </Box>
                            )

                            }
                            <Action mt='30px'>
                                <MyButton variant='contained' color='primary' onClick={handleNewInv}>Save</MyButton>
                                {/* <MyButton variant='contained' color='secondary' onClick={() => setOpen(false)}>Cancel</MyButton> */}
                                {/* new */}
                                <MyButton variant='contained' color='secondary' onClick={() => closeModal()}>Cancel</MyButton>
                            </Action>
                        </StyledContainer>
                    </Fade>
                </Modal>
            }
        </>
    )
}

const FormBodyWrapper = styled(Box)`
    >div+div {
        margin-top: 15px;
    }
`

const BlankColumn = styled(Box)`
    @media (max-width: 1040px) {
        display: none;
    }
`

const Overlay = styled(Backdrop)`
    background-color: rgba(0, 0, 0, 0.8) !important;
`

const VerifyHeader = styled(Box)`
    >div {
        @media (max-width: 970px) {
            align-items: initial;
            flex-direction: column;
            >input {
                width: 100%;
            }
        }
    }
`

const Action = styled(Box)`
    display: flex;
    justify-content: flex-end;
    >button+button {
        margin-left: 30px;
    }
`

const MyButton = styled(Button)`
    text-transform: none !important;
`

const FormHead = styled(Box)`
    @media (max-width: 970px) {
        >div {
            flex: 1;
        }
        >div:not(:first-of-type) {
            margin-left: unset;
            display: none;
        }
    }
`

const StyledContainer = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: white;
    max-height: calc(100% - 50px);
    overflow-y: auto;
    width: calc(100% - 32px);
    padding: 20px;
    input {
        outline: none;
        border: 1.5px solid lightgrey;
        border-radius: 5px;
        transition: .3s;
        padding: 5px;
        height: fit-content;
        align-self: center;
        &:focus {
            border-color: #c850c0;
        }
        &:disabled {
            cursor: not-allowed;
            background-color: -internal-light-dark(rgba(209, 209, 209, 0.3), rgba(29, 29, 29, 0.3)) !important;
        }
    }
    select:not(#sel_pra) {
        border: none;
        outline: none;
        padding: 4px;
        border-radius: 5px;
    }

    #sel_pra {
        // border: none;
        outline: none;
        border: 1px solid lightgrey;
        padding: 4px;
        border-radius: 5px;
    }
`
const ComboBox = styled(Box)`
   // border: 1px solid lightgrey;
    border-radius: 5px;
    &:focus {
        border-color: red;
    }
`

export default InvAddModal