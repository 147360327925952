import { useState, useRef } from 'react'
import { Box } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import styled from 'styled-components'
import _ from 'lodash'

import { useOutsideDetector } from '../../hooks/useOutsideDetector'
import Check from 'src/assets/images/checkmark.png'

const ColumnDrops = ({ flags, setFlags, filterValues }) => {
    const [open, setOpen] = useState(false)
    const titleRef = useRef()
    const contentRef = useRef()

    useOutsideDetector([titleRef, contentRef], setOpen)

    return (
        <StyledContainer mr={2} onClick={() => setOpen(!open)} {...{ ref: titleRef }}>
            <Box>{_.filter(flags, e => e === true).length > 1 ? `${_.filter(flags, e => e === true).length} items` : filterValues[flags.findIndex(e => e === true)]}</Box>
            <ExpandMoreIcon />
            {open &&
                <Content {...{ ref: contentRef }}>
                    {_.map(filterValues, (each, i) =>
                        <Box key={i} display='flex' justifyContent='space-between' onClick={() => {
                            let tmp = [...flags]
                            tmp[i] = !tmp[i]
                            setFlags(tmp)
                        }}>
                            <Box>By {each}</Box>
                            {flags[i] && <img src={Check} width='20px' height='20px' alt='' />}
                        </Box>
                    )}
                </Content>
            }
        </StyledContainer>
    )
}

const Content = styled(Box)`
    z-index: 1;
    position: absolute;
    left: 0px;
    top: 100%;
    width: 100%;
    border: 1px solid #ccc;
    background: white;
    >div {
        padding: 5px 13px;
        border-bottom: 1px solid #eee;
        background: white;
        transition: .3s;
        &:hover {
            background: #eee;
        }
    }
`

const ExpandMoreIcon = styled(ExpandMore)``

const StyledContainer = styled(Box)`
    position: relative;
    min-width: 200px;
    width: fit-content;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 13px;
    cursor: pointer;
`

export default ColumnDrops