import { useState } from 'react'
import { Box } from '@material-ui/core'
import { AssignmentReturn, EditOutlined, DeleteOutlined } from '@material-ui/icons'
import styled from 'styled-components'

import Badge from 'src/components/Badges'

const ReceiptDeletedRow = ({ id, eachInfo, setOpen, setOpenMsg, setIndex, setJIndex, showPharmacy, handleSetPharmacy,setCurrentdReceipt }) => {
    const [returnPharmacy, setReturnPharmacy] = useState(eachInfo.pellet_return_pharmacy)

    return (
        <StyledContainer component='tr'>
            <td>{id + 1}</td>
            <td>{eachInfo.pellet_date.slice(0, 10)}</td>
            <td>{eachInfo.pellet_receipt_invoice}</td>
            <td>{eachInfo.pellet_receipt_verified_by}</td>
            {/* <td>{eachInfo.created_by}</td> */}
            <td>{eachInfo.created_by_new}</td> 
            <td>{eachInfo.deleted_by}</td>
            <td>{eachInfo.createdAt}</td>
            <td>{eachInfo.created_pellet_at}</td>
            {showPharmacy &&
                <td>
                    {returnPharmacy && <Badge type='Returned' />}
                </td>
            }
            {showPharmacy &&
                <td>
                    <PharmacyButton onClick={() => {
                        let formData = {
                            where: eachInfo._id,
                            value: returnPharmacy
                        }
                        handleSetPharmacy(formData)
                        setReturnPharmacy(!returnPharmacy)
                    }} />
                </td>
            }
            <td>
                <EditButton onClick={() => {
                    setCurrentdReceipt(eachInfo)
                    setIndex(id)
                    setJIndex(eachInfo._id)
                    setOpen(true)
                }} />
            </td>
        </StyledContainer>
    )
}

const PharmacyButton = styled(AssignmentReturn)`
    cursor: pointer;
    opacity: .5;
    &:hover {
        transition: .3s;
        opacity: 1;
    }
`

const EditButton = styled(EditOutlined)`
    cursor: pointer;
    opacity: .5;
    &:hover {
        transition: .3s;
        opacity: 1;
    }
`

const DeleteButton = styled(DeleteOutlined)`
    cursor: pointer;
    opacity: .5;
    &:hover {
        transition: .3s;
        opacity: 1;
    }
`

const StyledContainer = styled(Box)`
`

export default ReceiptDeletedRow