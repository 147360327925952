import { Box, Button } from '@material-ui/core'
import { History } from '@material-ui/icons'
import styled from 'styled-components'
import _ from 'lodash'

import Badge from 'src/components/Badges'

const LogCard = ({ owner }) => {
    return (
        <StyledContainer mt={2}>
            <Row fontWeight='500' fontSize='20px' display='flex' alignItems='center' flexDirection={"column"}>
                <Box display={"flex"} justifyContent='space-between' alignItems='center' width={"100%"} >
                    <Box>Logs</Box>
                    <Button disabled={!localStorage.getItem('logData')} color='primary' variant='contained' onClick={() => {
                        localStorage.removeItem('logData')
                        window.location.reload()
                    }}>
                        <History />
                        Clear
                    </Button>
                </Box>
                <Box display={"flex"} width={"100%"} mt="2%">
                    <Box display={"flex"} width={"5%"}>No</Box>
                    <Box display={"flex"} width={"15%"} justifyContent="flex-start">User</Box>
                    <Box display={"flex"} width={"15%"} justifyContent="flex-start">Action</Box>
                    <Box display={"flex"} width={"30%"} justifyContent="flex-start">Description</Box>
                    <Box display={"flex"} width={"35%"} justifyContent="flex-end">Date {'&'} Time</Box>
                </Box>
            </Row>
            <Box height='300px' overflow='auto'>
                {_.map(_.reverse(_.filter(JSON.parse(localStorage.getItem("logData") || "[]"), e => e.userName === owner)), (each, i) =>
                    <Row key={i}>
                        <Box display='flex' alignItems='center' width={"100%"} overflow='auto'>
                            <Box display={"flex"} width={"5%"}>
                                {i + 1}
                            </Box>
                            <Box display={"flex"} width={"15%"}>
                                <Badge type={each.userName} />
                            </Box>
                            <Box display={"flex"} width={"15%"}>
                                <Badge type={each.actionShort} />
                            </Box>
                            <Box display={"flex"} width={"30%"}>{each.action}</Box>
                            <Box display='flex' width={"35%"} alignItems='center'  justifyContent="flex-end">
                                
                                <Box color='#555' >{each.actionDate.slice(0, 10)}</Box>
                                <Box color='#555' ml={1}>{each.actionDate.slice(11, 16)}</Box>
                            </Box>
                        </Box>

                    </Row>
                )}
            </Box>
        </StyledContainer>
    )
}

const Row = styled(Box)`
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledContainer = styled(Box)`
    padding: 0 10px;
    border-radius: 10px;
    border: 2px solid rgb(236,236,236);
    >div:first-of-type {
        border-bottom: 1px solid rgb(236,236,236);
    }
    >div:last-of-type {
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-corner {
            background: inherit;
        }
        &::-webkit-scrollbar-thumb {
            width: 30px;
            border-radius: 15px;
            background: rgb(33,37,41);
        }
        >div:not(:last-of-type) {
            border-bottom: 1px solid rgb(236,236,236);
        }
    }
`

export default LogCard