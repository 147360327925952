import React from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components'

const Spinner = () => {
    return (
        <StyledContainer>
            <svg viewBox='0 0 120 120'>
                <circle cx='60' cy='60' r='30' />
                <circle cx='60' cy='60' r='50' />
            </svg>
        </StyledContainer>
    );
}

const StyledContainer = styled(Box)`
    margin: 8px;
    >svg {
        transform: rotate(-90deg);
        stroke-linecap: round;
        stroke-width: 4;
        fill: none;
        overflow: hidden;
        width: 32px;
        height: 32px;
        >circle:first-of-type {
            stroke-linecap: round;
            stroke-width: 4;
            fill: none;
            stroke-dashoffset: 0;
            transform-origin: center center;
            stroke: rgb(32, 129, 226);
            stroke-dasharray: 187;
            animation: 1s ease-in-out 0s infinite normal none running inner;
            @keyframes inner {
                0% {
                    stroke-dashoffset: 187;
                }
                25% {
                    stroke-dashoffset: 80;
                }
                100% {
                    stroke-dashoffset: 187;
                    transform: rotate(360deg);
                }
            }
        }
        >circle:nth-of-type(2) {
            stroke-dashoffset: 0;
            transform-origin: center center;
            stroke-dasharray: 312;
            animation: 1s linear 0s infinite normal none running outer;
            stroke: rgb(229, 232, 235);
            @keyframes outer {
                0% {
                    stroke-dashoffset: 312;
                    transform: rotate(70deg);
                }
                60% {
                    stroke-dashoffset: -312;
                }
                100% {
                    stroke-dashoffset: -312;
                    transform: rotate(450deg);
                }
            }
        }
    }
`

export default Spinner