import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { LOGOUT, LOGIN } from 'src/redux/actions/types';

import { PrivateRoute } from './components/PrivateRoute'
import Layout from './layout'
import Dispatcher from './layout/Dispatcher'
import EmailVerify from './pages/Auth/EmailVerify'
import Reset from './pages/Auth/PasswordReset'
import Login from './pages/Auth/Login'
import Signup from './pages/Auth/Signup'
import Forgot from './pages/Auth/Forgot'
import UserManagement from './pages/Auth/UserManagement'
import InvAdjustment from './pages/InvAdjustment'
import InvAdjustmentDeleted from './pages/InvAdjustmentDeleted'
import Dashboard from './pages/Dashboard'
import ReceiptAdd from './pages/PelletReceipt/ReceiptAdd'
import ReceiptEdit from './pages/PelletReceipt/ReceiptEdit'
import ReceiptDelete from './pages/PelletReceipt/ReceiptDelete'
import DispenseAdd from './pages/Patient/DispenseAdd'
import DispenseEdit from './pages/Patient/DispenseEdit'
import DispenseDelete from './pages/Patient/DispenseDelete'
import ProcedureTracker from './pages/Report/ProcedureTracker'
import MTDR from './pages/Report/MTDR'
import DDR from './pages/Report/DDR'
import LookupDetail from './pages/Report/LookupDetail'
import LotReview from './pages/Report/LotReview'
import RescheduleTracker from './pages/Report/RescheduleTracker'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import * as PAGES from './constants/pages'
import './utils/api'

import { doLogoutUser } from 'src/redux/actions/AuthAction'

import IdleTimer from "./IdleTimer";
import React, { useEffect, useState } from "react";
import LookupDetail2 from './pages/Report/LookupDetail2'

const App = () => {

  const history = useHistory()
  const dispatch = useDispatch()

  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {

    const timer = new IdleTimer({
      timeout: 3600,//900, //expire after 900 seconds means 15 min
      onTimeout: () => {
        // do something if expired on load
        // log out form the system
        // dispatch(doLogoutUser('./'));
        localStorage.removeItem('jwtToken')
        //window.location.reload(false);
        window.location.reload('/' + PAGES.LOGIN);
         history.push('/' + PAGES.LOGIN)
        history.push(`/${PAGES.DASHBOARD}`)
        dispatch({ type: LOGOUT })
        setIsTimeout(true);
      },
      onExpired: () => {
         setIsTimeout(false);
      }
    });
    timer.startInterval();
    return () => {
      timer.cleanUp();
    };

  }, []);

  return <Router>
    <Switch>
      <Route exact path={`/${PAGES.LOGIN}`} component={Login} />
      <Route exact path={`/${PAGES.SIGNUP}`} component={Signup} />
      <Route exact path={`/${PAGES.FORGOT}`} component={Forgot} />
      <Route exact path={`/${PAGES.RESET}/:id/:password`} render={({ match }) => <Reset match={match} />} />
      <Route exact path={`/${PAGES.VERIFY}/:id`} render={({ match }) => <EmailVerify match={match} />} />
      <Layout>
        <Dispatcher>
          <Route exact path={`/${PAGES.DASHBOARD}`} component={Dashboard} />
          <PrivateRoute exact path={`/${PAGES.USERS}`} component={UserManagement} />
          <PrivateRoute exact path={`/${PAGES.INVADJUST}`} component={InvAdjustment} />
          <PrivateRoute exact path={`/${PAGES.INVADJUSTDEL}`} component={InvAdjustmentDeleted} />
          <PrivateRoute exact path={`/${PAGES.RECEIPTADD}`} component={ReceiptAdd} />
          <PrivateRoute exact path={`/${PAGES.RECEIPTEDIT}`} component={ReceiptEdit} />
          <PrivateRoute exact path={`/${PAGES.RECEIPTDELETE}`} component={ReceiptDelete} />
          <PrivateRoute exact path={`/${PAGES.DISPENSEADD}`} component={DispenseAdd} />
          <PrivateRoute exact path={`/${PAGES.DISPENSEEDIT}`} component={DispenseEdit} />
          <PrivateRoute exact path={`/${PAGES.DISPENSEDELETE}`} component={DispenseDelete} />
          <PrivateRoute exact path={`/${PAGES.MTDREPORT}`} component={MTDR} />
          <PrivateRoute exact path={`/${PAGES.DDREPORT}`} component={DDR} />
          <PrivateRoute exact path={`/${PAGES.LOOKUPDETAIL}`} component={LookupDetail} />
          <PrivateRoute exact path={`/${PAGES.PROCTRACKER}`} component={ProcedureTracker} />
          <PrivateRoute exact path={`/${PAGES.LOOKUPDETAIL2}`} component={LookupDetail2} />
          <PrivateRoute exact path={`/${PAGES.RESCHEDULE}`} component={RescheduleTracker} />
          <PrivateRoute exact path={`/${PAGES.LOTREVIEW}`} component={LotReview} />
        </Dispatcher>
      </Layout>
    </Switch>
  </Router>
}

export default App
