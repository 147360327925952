import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import _ from 'lodash'

import FilterBar from './FilterBar'
import Exporter from 'src/components/Exporter'

import BackImg from 'src/assets/images/backs/procedure_bg.jpg'
import NewPatient from 'src/assets/images/dispense/new-patient_add.png'
import OldPatient1 from 'src/assets/images/dispense/new-patient_edit.png'
import { doGetMTD, doGetAddress, doGetPatient } from 'src/redux/actions/ReportAction'
import Pagination from 'src/components/Paginations'
import jwtDecode from 'jwt-decode'

const ProcedureTracker = () => {

    // Start practice dropdown
    const practiceList = useSelector(state => state.AuthReducer.users).filter(u => u.user_type == "Practice")
    const [practice, setpractice] = useState("All");
    // End practice dropdown

    const tBodyRef = useRef()
    const [pushed, setPushed] = useState(true)
    const dispatch = useDispatch()

    const patient_dispence = useSelector(state => state.PatientReducer.dispense)
    let filtered_patient_dispence = [];
    let filtered_patient_dispence_filter=[];

    const [page, setPage] = useState(1)
    const [check_patient, set_patient] = useState([])
    const [check_practice, set_practice] = useState([])
    //const [check_procedure, set_procedure] = useState([])
    const [check_procedure, set_procedure] = useState([true,true,true,true,true,true,true,true,true,true,true])
    // const [date, set_date]=useState(`${new Date().getFullYear()}-${new Date().getMonth()+1}`)
    const [date, set_date] = useState(``)
    const [date1, set_date1] = useState(``)
    const [cstep, setCStep] = useState(15)
    const [ostep, setOStep] = useState(15)
    const [title, setTitle] = useState('Procedure Tracker');
    const [ptitle, setPTitle] = useState('Practice: All');

    // const userTypes = useSelector(state => state.AuthReducer.user_id)
    const user = localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken'))
    const userTypes = JSON.parse(localStorage.getItem('uid'))

    // filter doses by current user 
    if (user.userType == "Practice") {
        var user_name_all=user.userFName+' '+user.userLName+' ('+user.practice_name+' '+user.practioner_name+')';
        // patient dspense
        for (let i = 0; i < patient_dispence?.length; i++) {
           // if (patient_dispence[i].practice_name == user.userName) {
            if (patient_dispence[i].practice_name == user.userName || patient_dispence[i].practice_name == user_name_all) {
                filtered_patient_dispence.push(patient_dispence[i]);
            }
        }
    }
    else if (user.userType == "Administrator") {
        if (practice != "All") {
            let  prac_sp = practice.split("/");
            console.log(practice,prac_sp);
            // patient dspense
            for (let i = 0; i < patient_dispence?.length; i++) {
                console.log(patient_dispence[i].practice_name);
                // if (patient_dispence[i].practice_name == practice) {
                    if (patient_dispence[i].practice_name == prac_sp[0] || patient_dispence[i].practice_name == prac_sp[1]) {
                    filtered_patient_dispence.push(patient_dispence[i]);
                }
            }
        }
        else {
            filtered_patient_dispence = patient_dispence;
        }
    }
    filtered_patient_dispence_filter=filtered_patient_dispence;
    

    const check_procedure_type = (type) => {
        if (type === 'Male' && (check_procedure[0] === true)) {

            return 1;
        }
        if (type === 'Male-Large' && (check_procedure[1] === true)) {

            return 1;
        }
        if (type === 'Male-Boost' && (check_procedure[2] === true)) {

            return 1;
        }
        if (type === 'Female' && (check_procedure[3] === true)) {

            return 1;
        }
        if (type === 'Female-Boost' && (check_procedure[4] === true)) {

            return 1;
        }
        if (type === 'Male- Disc 50%' && (check_procedure[5] === true)) {

            return 1;
        }
        if (type === 'Male-Disc 100%' && (check_procedure[6] === true)) {

            return 1;
        }
        if (type === 'Male-Large Disc 50%' && (check_procedure[7] === true)) {

            return 1;
        }
        if (type === 'Male-Large Disc 100%' && (check_procedure[8] === true)) {

            return 1;
        }
        if (type === 'Female Disc 50%' && (check_procedure[9] === true)) {

            return 1;
        }
        if (type === 'Female Disc 100%' && (check_procedure[10] === true)) {

            return 1;
        }
    }
    //paging in filter
    //console.log(check_patient,check_procedure);
    if(check_patient.includes(true))
    {
         let list_check=[];
          filtered_patient_dispence_filter=[];
     //console.log(check_patient);
        for(let x=0;x<check_patient.length;x++)
        {
            if(check_patient[x]==true)
            {
                var fullname=filtered_patient_dispence[x].patient_first_name + ' ' + filtered_patient_dispence[x].patient_last_name;
                 list_check.push(fullname);
            }
        }
     //console.log(list_check);
        for(let x=0;x<filtered_patient_dispence.length;x++)
        {
           
            var fullname=filtered_patient_dispence[x].patient_first_name + ' ' + filtered_patient_dispence[x].patient_last_name;
            if(!list_check.includes(fullname))
            {
                filtered_patient_dispence_filter.push(filtered_patient_dispence[x]);
            
            }
        }
    }
//  console.log(check_procedure);
    if(check_procedure.includes(false))
    {
        let list_check=['Male','Male-Large','Male-Boost','Female','Female-Boost','Male- Disc 50%','Male-Disc 100%','Male-Large Disc 50%','Male-Large Disc 100%','Female Disc 50%','Female Disc 100%'];
        filtered_patient_dispence_filter=[];
       // console.log(check.indexOf(false),list_check);
        for(let x=0;x<check_procedure.length;x++)
        {
            if(check_procedure[x]==false)
            {

                delete list_check[x];
            }
        }
       // console.log(check.indexOf(false),list_check);
       for(let x=0;x<filtered_patient_dispence.length;x++)
       {
          
           if(list_check.includes(filtered_patient_dispence[x].procedureInfo?.[0]?.procedure_name))
           {
               filtered_patient_dispence_filter.push(filtered_patient_dispence[x]);
           
           }
       }
    }
    if(date !='' && date1 !='')
    {
        let list_check=[];
        if(check_patient.includes(true))
        {
            filtered_patient_dispence_filter=[];
        //console.log(check_patient);
            for(let x=0;x<check_patient.length;x++)
            {
                if(check_patient[x]==true)
                {
                    var fullname=filtered_patient_dispence[x].patient_first_name + ' ' + filtered_patient_dispence[x].patient_last_name;
                    list_check.push(fullname);
                }
            }
        //console.log(list_check);
           
        }
    // console.log('ffffff');
    filtered_patient_dispence_filter=[];
        for(let x=0;x<filtered_patient_dispence.length;x++)
        {
            var fullname=filtered_patient_dispence[x].patient_first_name + ' ' + filtered_patient_dispence[x].patient_last_name;
            if((date <= filtered_patient_dispence[x]?.dispense_date.slice(0, 10)) && (filtered_patient_dispence[x]?.dispense_date.slice(0, 10) <= date1) && !list_check.includes(fullname))
            {
                filtered_patient_dispence_filter.push(filtered_patient_dispence[x]);
            
            }
        }
    }

    useEffect(() => {
        dispatch(doGetMTD());
        // dispatch(doGetPatient());
        // dispatch(doGetAddress());
    }, [dispatch])
    return (
        <>
            <Exporter tBodyRef={tBodyRef} setPushed={setPushed} setCStep={setCStep} totalColumn={filtered_patient_dispence_filter?.length} oldStep={15} currentTable={'tablert'} emptyBox={'emptyBox'} title={title} ptitle={ptitle} currentuser={user.userType} practiceHere={'practiceHere'} currentpractice={practice}/>
            <StyledContainer>
                <FilterBar pushed={pushed} setPushed={setPushed} date={date} set_date={set_date} date1={date1} set_date1={set_date1}
                    check_patient={check_patient} set_patient={set_patient}  check_procedure={check_procedure} set_procedure={set_procedure} />
                <Wrapper flex='1' position='relative' overflow='auto'>
                    <Background />
                    <Box {...{ ref: tBodyRef }} padding='20px'>
                        <Box fontSize='20px' fontWeight='600'>Procedure Tracker</Box>

                        <br /> <br />
                        {user.userType == "Practice" ?
                            (
                                <Box id="practiceHere">
                                    <Box fontSize='16px' fontWeight='600'>  Biosana ID#:  <span style={{ color: '#666666', fontStyle: 'italic' }}> {userTypes.biosana_id} </span> </Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practice Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practice_name} </span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practioner Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practioner_name}</span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  DEA#:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.dea_no} </span> </Box>
                                </Box>
                            )
                            : null}
                        <br />

                        {user.userType == "Administrator" ?
                            (
                                // show practice dropdown
                                <Box display='flex' alignItems='center' mt='15px' style={{
                                    display: "inline-block", float: "right", "margin-top": "-100px"
                                }}>
                                    <Box width='100px' float="right">Practice:</Box>
                                    <select onChange={(e) => {
                                        // handle practice change

                                        setpractice(e.target.value);
                                        var sval=e.target.value.split("/")
                                        var new_title='Practice: '+sval[0];
                                        setPTitle(new_title);
                                    }}>
                                        <option selected value="All">All</option>
                                        {
                                            //practiceList.map((u) => <option value={u.user_name} key={u.UsernameKey}>{u.user_name}</option>)
                                            //new
                                            // practiceList.map((u) => <option value={u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'+'/'+u.user_name} key={u.UsernameKey}>{u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                            practiceList.map((u) => <option value={u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'+'/'+u.user_name+'/'+u.biosana_id} key={u.UsernameKey}>{u.biosana_id+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                        }
                                    </select>
                                </Box>
                            )
                            : null}
                        <br />

                        <Box width='100%' boxShadow='2px 2px 5px 1px #ccc' mt={3} >
                            <Box id='emptyBox'></Box>
                            <Table width='100%' component='table' id='tablert'>
                                <THead component='thead'>
                                    <tr>
                                        <th>Patient Name</th>
                                        <th>Practice Name</th>
                                        {/* <th>Next Projected <br />Dispense Date</th> */}
                                        <th>Last Dispense Date</th>
                                        {/* <th>Patient Address / City / State / State / ZIP</th>
                                        <th>Phone</th> */}
                                        <th>Procedure</th>
                                    </tr>
                                </THead>
                                <TBody component='tbody' pb='20px'>
                                  
                                    {_.map(_.filter(filtered_patient_dispence_filter, (e, uIndex) => uIndex >= cstep * (page - 1) && uIndex <= cstep * (page - 1) + (cstep-1)), (each, i) =>    
                                        <tr key={i}>
                                            {/* {(!check_patient[i] && !check_practice[i] && check_procedure_type(each.procedureInfo?.[0]?.procedure_name) && (((date <= each.dispense_date.slice(0, 10)) && (each.dispense_date.slice(0, 10) <= date1)) || (date === '' && date1 === ''))) && */}
                                            {(!check_practice[i] && check_procedure_type(each.procedureInfo?.[0]?.procedure_name)) &&
                                                <><td>{each.patient_first_name + ' ' + each.patient_last_name}</td>
                                                    <td>{each.practice_name}</td>
                                                    <td>{each.dispense_date.slice(0, 10)}</td>
                                                    <td>{each.procedureInfo?.[0]?.procedure_name}</td></>}
                                        </tr>

                                    )}
                                </TBody>
                            </Table>
                        </Box>

                    </Box>
                    <TFoot mt='20px' textAlign='right'>
                        {/* {(filtered_patient_dispence && filtered_patient_dispence?.length > 0) && <Pagination step={15} totalCount={filtered_patient_dispence?.length} page={page} setPage={setPage} />} */}
                        {/* {(filtered_patient_dispence && filtered_patient_dispence?.length > 0) && <Pagination step={cstep} totalCount={filtered_patient_dispence?.length} page={page} setPage={setPage} />} */}
                        {(filtered_patient_dispence_filter && filtered_patient_dispence_filter?.length > 0) && <Pagination step={cstep} totalCount={filtered_patient_dispence_filter?.length} page={page} setPage={setPage} />}
                    </TFoot>
                </Wrapper>
            </StyledContainer>
        </>)
}

const ImageBox = styled(Box)`
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(50% - 5px);
        height: 7px;
        width: 35px;
        background: grey;
        transform: translate(-50%, 3px);
        border-radius: 50%;
        filter: blur(3px);
    }
    >img { transition: transform .3s; }
    &:hover {
        >img {
            transform: translate(0px, -5px) scale(1.2);
        }
    }
`

const Background = styled(Box)`
    position: absolute;
    width: 100%; height: 100%;
    background: url(${BackImg});
    background-size: 100% 100%;
    opacity: .15;
    user-select: none;
    pointer-events: none;
`

const THead = styled(Box)``

const TBody = styled(Box)``

const Table = styled(Box)`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: white;
        font-weight: 600;
    }
    tbody tr {
        transition: .3s;
        background-color: white;
        border-top: 1px solid lightgrey;
    }
    tr:hover {background-color: #eee;}
    th, td {
        padding: 8px;
    }
`
const Wrapper = styled(Box)`
    // background: url(${BackImg});
    // background-size: 100% 100%;
`

const StyledContainer = styled(Box)`
    display: flex;
    flex: 1;
`
const TFoot = styled(Box)``

export default ProcedureTracker