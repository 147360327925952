import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'
import styled from 'styled-components'

import FilterBar from './FilterBar'
import Exporter from 'src/components/Exporter'

import BackImg from 'src/assets/images/backs/procedure_bg.jpg'
import _ from 'lodash'
import { doGetMTD } from 'src/redux/actions/ReportAction'
import Pagination from 'src/components/Paginations'
import jwtDecode from 'jwt-decode'


const LotReview = () => {

    // Start practice dropdown
    const practiceList = useSelector(state => state.AuthReducer.users).filter(u => u.user_type == "Practice")
    const [practice, setpractice] = useState("All");

    // End practice dropdown

    const user = localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken'))

    const tBodyRef = useRef()
    const [page, setPage] = useState(1)
    const [pushed, setPushed] = useState(true)
    const dispatch = useDispatch()
    const [cstep, setCStep] = useState(15)
    const [ostep, setOStep] = useState(15)
    const [title, setTitle] = useState('Lot Review');
    const [ptitle, setPTitle] = useState('Practice: All');

    //const dose = useSelector(state => state.ReportReducer.report_MTD)

    // need to get pellet_receipt_records with array of doses
    let mtd_all_dose = useSelector(state => state.ReportReducer.report_MTD);
    let filtered_dose = [];
    let filtered_dose_filter = [];
    let filtered_dose_quantity = [];
    const [check, set_check] = useState([true, true, true, true, true, true, true, true, true, true])
    const [check_lot, set_lot] = useState([])
    const [check_lot_qnty, set_lot_qnty] = useState([])
    // const userTypes = useSelector(state => state.AuthReducer.user_id)
    const userTypes = JSON.parse(localStorage.getItem('uid'))

    
    // filter doses by current user 
    if (user.userType == "Practice") {
        var user_name_all=user.userFName+' '+user.userLName+' ('+user.practice_name+' '+user.practioner_name+')';
        for (let i = 0; i < mtd_all_dose?.length; i++) {
            //new
            if(mtd_all_dose[i].PelletReceipt[0]?.created_by)
            {
               // if (mtd_all_dose[i].PelletReceipt[0].created_by == user.userName) {
                if (mtd_all_dose[i].PelletReceipt[0].created_by == user.userName || mtd_all_dose[i].PelletReceipt[0].created_by == user_name_all){
                    filtered_dose.push(mtd_all_dose[i]);
                    if(!filtered_dose_quantity.includes(mtd_all_dose[i].dose_qty))
                        {
                            filtered_dose_quantity.push(mtd_all_dose[i].dose_qty)
                        }
                }

            }
        }
    }
    else if (user.userType == "Administrator") {
        if (practice != "All") {
            let  prac_sp = practice.split("/");
            for (let i = 0; i < mtd_all_dose?.length; i++) {
                //new
                if (mtd_all_dose[i].PelletReceipt[0]?.created_by){

                    // if (mtd_all_dose[i].PelletReceipt[0].created_by == practice) {
                    if (mtd_all_dose[i].PelletReceipt[0].created_by == prac_sp[0] || mtd_all_dose[i].PelletReceipt[0].created_by == prac_sp[1]) {
                        filtered_dose.push(mtd_all_dose[i]);
                        if(!filtered_dose_quantity.includes(mtd_all_dose[i].dose_qty))
                        {
                            filtered_dose_quantity.push(mtd_all_dose[i].dose_qty)
                        }
                        
                    }
                }
            }
        }
        else {
            filtered_dose = mtd_all_dose;
            for (let i = 0; i < mtd_all_dose?.length; i++) {
                if(!filtered_dose_quantity.includes(mtd_all_dose[i].dose_qty))
                        {
                            filtered_dose_quantity.push(mtd_all_dose[i].dose_qty)
                        }
            }
        }
    }
    filtered_dose_filter=filtered_dose;
    //console.log(filtered_dose_quantity,check_lot_qnty);

    const [activity_date, set_activity_date] = useState({
        // date:`${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '0' + new Date().getDate() : new Date().getDate()}`,
        // date:`${new Date().getFullYear()}-${new Date().getMonth()+1}`
        date: ''
    })


    const [lot_date, set_lot_date] = useState({
        // date:`${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '0' + new Date().getDate() : new Date().getDate()}`,
        // date:`${new Date().getFullYear()}-${new Date().getMonth()+1}`
        date: ''
    })

    const [activity_date1, set_activity_date1] = useState({
        // date:`${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '0' + new Date().getDate() : new Date().getDate()}`,
        // date:`${new Date().getFullYear()}-${new Date().getMonth()+1}`
        date: ''
    })


    const [lot_date1, set_lot_date1] = useState({
        // date:`${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '0' + new Date().getDate() : new Date().getDate()}`,
        // date:`${new Date().getFullYear()}-${new Date().getMonth()+1}`
        date: ''
    })
    // useEffect(() => {
    //     dispatch(doGetMTD());

    // }, [dispatch,filtered_dose])
    

    const renderSwitch = (param) => {
        switch (param) {
            case '6':
                return 'E6';
            case '10':
                return 'E10';
            case '12.5':
                return 'E12.5';
            case '15':
                return 'E15';
            case '25':
                return 'T25';
            case '37.5':
                return 'T37.5';
            case '50':
                return 'T50';
            case '87.5':
                return 'T87.5';
            case '100':
                return 'T100';
            case '200':
                return 'T200';
            default:
                return '';
        }
    }


    const check_pellet_type = (type) => {
        if (type === '6' && (check[0] === true)) {

            return 1;
        }
        if (type === '10' && (check[1] === true)) {

            return 1;
        }
        if (type === '12.5' && (check[2] === true)) {

            return 1;
        }
        if (type === '15' && (check[3] === true)) {

            return 1;
        }
        if (type === '25' && (check[4] === true)) {

            return 1;
        }
        if (type === '37.5' && (check[5] === true)) {

            return 1;
        }
        if (type === '50' && (check[6] === true)) {

            return 1;
        }
        if (type === '87.5' && (check[7] === true)) {

            return 1;
        }
        if (type === '100' && (check[8] === true)) {

            return 1;
        }
        if (type === '200' && (check[9] === true)) {

            return 1;
        }
    }
    //paging in filter
    if(check_lot_qnty.includes(true))
    {
        let list_check=[];
        filtered_dose_filter=[];
       // console.log(check.indexOf(false),list_check);
        for(let x=0;x<check_lot_qnty.length;x++)
        {
            if(check_lot_qnty[x]==true)
            {

                 list_check.push(x);
            }
        }
        let final_quantity=[];
        for(let x=0;x<filtered_dose_quantity.length;x++)
        {
            if(!list_check.includes(x))
            {
                final_quantity.push(filtered_dose_quantity[x]);
            }

        }
        for(let x=0;x<filtered_dose.length;x++)
        {
            if(lot_date.date !='' && lot_date1.date !='')
            {
                if(activity_date.date !='' && activity_date1.date !='')
                {
                    if((activity_date.date <= filtered_dose[x]?.pellet_date) && (activity_date1.date >= filtered_dose[x]?.pellet_date) && final_quantity.includes(filtered_dose[x]?.dose_qty) && lot_date.date <= filtered_dose[x]?.pellet_exp_date && lot_date1.date >= filtered_dose[x]?.pellet_exp_date)
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }
                }else{
                    if(final_quantity.includes(filtered_dose[x]?.dose_qty) && lot_date.date <= filtered_dose[x]?.pellet_exp_date && lot_date1.date >= filtered_dose[x]?.pellet_exp_date)
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }
                }
            }else{
                if(activity_date.date !='' && activity_date1.date !='')
                {
                    if((activity_date.date <= filtered_dose[x]?.pellet_date) && (activity_date1.date >= filtered_dose[x]?.pellet_date) && final_quantity.includes(filtered_dose[x]?.dose_qty))
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }
                }else{

                    if(final_quantity.includes(filtered_dose[x]?.dose_qty))
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }
                }
            }
        }
        //console.log(list_check,final_quantity);
    }
    if(check.includes(false))
    {
        let list_check=['6','10','12.5','15','25','37.5','50','87.5','100','200'];
        let list_check_qty=[];
        filtered_dose_filter=[];
        console.log(check.indexOf(false),list_check,check_lot_qnty);
        for(let x=0;x<check.length;x++)
        {
            if(check[x]==false)
            {

                delete list_check[x];
            }
        }
        for(let x=0;x<check_lot_qnty.length;x++)
        {
            if(check_lot_qnty[x]==true)
            {

                 list_check_qty.push(x);
            }
        }
        let final_quantity=[];
        for(let x=0;x<filtered_dose_quantity.length;x++)
        {
            if(!list_check_qty.includes(x))
            {
                final_quantity.push(filtered_dose_quantity[x]);
            }

        }
        //console.log(check.indexOf(false),list_check,final_quantity);
        for(let x=0;x<filtered_dose.length;x++)
        {
            if(list_check.includes(filtered_dose[x]?.dose_size) && final_quantity.includes(filtered_dose[x]?.dose_qty))
            {
                filtered_dose_filter.push(filtered_dose[x]);
            
            }
        }
    }
    if(activity_date.date !='' && activity_date1.date !='')
    {
       // console.log('ffffff');
       let list_check=[];
       let final_quantity=[];
       if(check_lot_qnty.includes(true))
        {
            for(let x=0;x<check_lot_qnty.length;x++)
            {
                if(check_lot_qnty[x]==true)
                {

                    list_check.push(x);
                }
            }
            for(let x=0;x<filtered_dose_quantity.length;x++)
            {
                if(!list_check.includes(x))
                {
                    final_quantity.push(filtered_dose_quantity[x]);
                }

            }
           
            //console.log(list_check,final_quantity);
        }
        filtered_dose_filter=[];
        for(let x=0;x<filtered_dose.length;x++)
        {
            if(final_quantity.length>0)
            {
                if(lot_date.date !='' && lot_date1.date !='')
                {
                    if((activity_date.date <= filtered_dose[x]?.pellet_date) && (activity_date1.date >= filtered_dose[x]?.pellet_date) && final_quantity.includes(filtered_dose[x]?.dose_qty) && lot_date.date <= filtered_dose[x]?.pellet_exp_date && lot_date1.date >= filtered_dose[x]?.pellet_exp_date)
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }
                }else{
                    if((activity_date.date <= filtered_dose[x]?.pellet_date) && (activity_date1.date >= filtered_dose[x]?.pellet_date) && final_quantity.includes(filtered_dose[x]?.dose_qty))
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }

                }
            }else{
                if(lot_date.date !='' && lot_date1.date !='')
                {
                    if((activity_date.date <= filtered_dose[x]?.pellet_date) && (activity_date1.date >= filtered_dose[x]?.pellet_date) && lot_date.date <= filtered_dose[x]?.pellet_exp_date && lot_date1.date >= filtered_dose[x]?.pellet_exp_date)
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }
                }else{
                    if((activity_date.date <= filtered_dose[x]?.pellet_date) && (activity_date1.date >= filtered_dose[x]?.pellet_date))
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }

                }
            }
           
        }
    }
    if(lot_date.date !='' && lot_date1.date !='')
    {
        //console.log('ffffff');
        let list_check=[];
       let final_quantity=[];
       if(check_lot_qnty.includes(true))
        {
            for(let x=0;x<check_lot_qnty.length;x++)
            {
                if(check_lot_qnty[x]==true)
                {

                    list_check.push(x);
                }
            }
            for(let x=0;x<filtered_dose_quantity.length;x++)
            {
                if(!list_check.includes(x))
                {
                    final_quantity.push(filtered_dose_quantity[x]);
                }

            }
           
            //console.log(list_check,final_quantity);
        }
        filtered_dose_filter=[];
        for(let x=0;x<filtered_dose.length;x++)
        {
            if(final_quantity.length>0)
            {
                if(activity_date.date !='' && activity_date1.date !='')
                {
                    if((activity_date.date <= filtered_dose[x]?.pellet_date) && (activity_date1.date >= filtered_dose[x]?.pellet_date) && final_quantity.includes(filtered_dose[x]?.dose_qty) && lot_date.date <= filtered_dose[x]?.pellet_exp_date && lot_date1.date >= filtered_dose[x]?.pellet_exp_date)
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }
                }else{
                    if(lot_date.date <= filtered_dose[x]?.pellet_exp_date && lot_date1.date >= filtered_dose[x]?.pellet_exp_date && final_quantity.includes(filtered_dose[x]?.dose_qty))
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }

                }
            }else{
                if(activity_date.date !='' && activity_date1.date !='')
                {
                    if((activity_date.date <= filtered_dose[x]?.pellet_date) && (activity_date1.date >= filtered_dose[x]?.pellet_date) && lot_date.date <= filtered_dose[x]?.pellet_exp_date && lot_date1.date >= filtered_dose[x]?.pellet_exp_date)
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }
                }else{
                    if(lot_date.date <= filtered_dose[x]?.pellet_exp_date && lot_date1.date >= filtered_dose[x]?.pellet_exp_date)
                    {
                        filtered_dose_filter.push(filtered_dose[x]);
                    
                    }

                }
            }
           
        }
    }
    
    useEffect(() => {
        //console.log("patient");
        dispatch(doGetMTD());

    }, [dispatch])

    return (
        <>
            <Exporter tBodyRef={tBodyRef} setPushed={setPushed} setCStep={setCStep} totalColumn={filtered_dose?.length} oldStep={15} currentTable={'tablert'} emptyBox={'emptyBox'} title={title} ptitle={ptitle} currentuser={user.userType} practiceHere={'practiceHere'} currentpractice={practice}/>
            <StyledContainer>
                <FilterBar pushed={pushed} setPushed={setPushed} check={check} set_check={set_check} filtered_dose_quantity={filtered_dose_quantity}
                    activity_date={activity_date} set_activity_date={set_activity_date} lot_date={lot_date} set_lot_date={set_lot_date}
                    activity_date1={activity_date1} set_activity_date1={set_activity_date1} lot_date1={lot_date1} set_lot_date1={set_lot_date1} check_lot={check_lot} set_lot={set_lot} check_lot_qnty={check_lot_qnty} set_lot_qnty={set_lot_qnty}/>
                <Wrapper flex='1' position='relative' overflow='auto'>
                    <Background />
                    <Box {...{ ref: tBodyRef }} padding='20px'>
                        <Box fontSize='20px' fontWeight='600'>Lot Review

                        </Box>

                        <br /> <br />
                        {user.userType == "Practice" ?
                            (
                                <Box id="practiceHere">
                                    <Box fontSize='16px' fontWeight='600'>  Biosana ID#:  <span style={{ color: '#666666', fontStyle: 'italic' }}> {userTypes.biosana_id} </span> </Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practice Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practice_name} </span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  Practioner Name:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.practioner_name}</span></Box>

                                    <Box fontSize='16px' fontWeight='600'>  DEA#:  <span style={{ color: '#666666', fontStyle: 'italic' }}>{userTypes.dea_no} </span> </Box>
                                </Box>
                            )
                            : null}
                        <br />

                        {user.userType == "Administrator" ?
                            (
                                // show practice dropdown
                                // <Box display='flex' alignItems='center' mt='15px' style={{
                                //     display: "inline-block", float: "right", "margin-top": "-100px"
                                // }}>
                                <Box display='flex' alignItems='center' mt='15px' style={{
                                    display: "inline-block", float: "right", "marginTop": "-100px"
                                }}>
                                    <Box width='100px' float="right">Practice:</Box>
                                    <select onChange={(e) => {
                                        // handle practice change

                                        setpractice(e.target.value);
                                        var sval=e.target.value.split("/")
                                        var new_title='Practice: '+sval[0];
                                        setPTitle(new_title);
                                    }}>
                                        <option value="All">All</option>
                                        {
                                            //practiceList.map((u) => <option value={u.user_name} key={u.UsernameKey}>{u.user_name}</option>)
                                            //new
                                            //practiceList.map((u) => <option value={u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'+'/'+u.user_name} key={u.UsernameKey}>{u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                            practiceList.map((u) => <option value={u.user_first_name+' '+u.user_last_name+' ('+u.practice_name+' '+u.practioner_name+')'+'/'+u.user_name+'/'+u.biosana_id} key={u.UsernameKey}>{u.biosana_id+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                        }
                                    </select>
                                </Box>
                            )
                            : null}
                        <br />

                        <Box width='100%' boxShadow='2px 2px 5px 1px #ccc' mt={3} overflow='auto'>
                            <Box id='emptyBox'></Box>
                            <Table width='100%' component='table' id='tablert'>
                                <THead component='thead'>
                                    <tr>
                                        <th>Pellet Type</th>
                                        <th>Activity Date</th>
                                        <th>Lot #</th>
                                        <th>Lot Expiration Date</th>
                                        <th>Inventory on hand</th>
                                    </tr>
                                </THead>
                                <TBody component='tbody' pb='20px'>
                                    {/* {_.map(_.filter(filtered_dose, (e, uIndex) => uIndex >= 15 * (page - 1) && uIndex <= 15 * (page - 1) + 14), (each, i) => */}
                                    {/* {_.map(_.filter(filtered_dose, (e, uIndex) => uIndex >= cstep * (page - 1) && uIndex <= cstep * (page - 1) + (cstep-1)), (each, i) => */}
                                    {_.map(_.filter(filtered_dose_filter, (e, uIndex) => uIndex >= cstep * (page - 1) && uIndex <= cstep * (page - 1) + (cstep-1)), (each, i) =>
                                        <tr key={i}>
                                            {/* {(check_pellet_type(each.dose_size) && (((activity_date.date <= each.pellet_date) && (activity_date1.date >= each.pellet_date)) || (activity_date.date === '' && activity_date1.date === '')) && ((lot_date.date <= each.pellet_exp_date && lot_date1.date >= each.pellet_exp_date) || (lot_date.date === '' && lot_date1.date === ''))) && */}
                                            {(check_pellet_type(each.dose_size)) &&
                                                <>
                                                    <td>{renderSwitch(each.dose_size)}</td>
                                                    <td>{each.pellet_date}</td>
                                                    <td>{each.dose_lot_number}</td>
                                                    <td>{each.pellet_exp_date}</td>
                                                    <td>{each.dose_qty}</td>
                                                </>
                                            }
                                        </tr>
                                    )}
                                    {/* {_.map(_.filter(dose, e=>((e.dose_size === '6'))&&(check[0])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'E6' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )}
                                    {_.map(_.filter(dose, e=>(e.dose_size === '10')&&(check[1])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'E10' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )}
                                    {_.map(_.filter(dose, e=>(e.dose_size === '12.5')&&(check[2])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'E12.5' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )}
                                    {_.map(_.filter(dose, e=>(e.dose_size === '15')&&(check[3])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'E15' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )}
                                    {_.map(_.filter(dose, e=>(e.dose_size === '25')&&(check[4])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'T25' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )}
                                    {_.map(_.filter(dose, e=>(e.dose_size === '37.5')&&(check[5])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'T37.5' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )}
                                    {_.map(_.filter(dose, e=>(e.dose_size === '50')&&(check[6])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'T50' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )}
                                    {_.map(_.filter(dose, e=>(e.dose_size === '87.5')&&(check[7])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'T87.5' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )}
                                    {_.map(_.filter(dose, e=>(e.dose_size === '100')&&(check[8])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'T100' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )}
                                    {_.map(_.filter(dose, e=>(e.dose_size === '200')&&(check[9])&&((activity_date.date <= (e.pellet_date).toString().slice(0,10) && activity_date1.date>=(e.pellet_date).toString().slice(0,10))|| (activity_date.date===''&&activity_date1.date===''))&&((lot_date.date <= (e.pellet_exp_date).toString().slice(0,10)&&lot_date1.date >= (e.pellet_exp_date).toString().slice(0,10))||(lot_date.date===''&&lot_date1.date===''))), (each, i)=>
                                        <tr key={i}>
                                            <td>{i == 0 ? 'T200' : ''}</td>
                                            <td>{each.pellet_date}</td>
                                            <td>{each.dose_lot_number}</td>
                                            <td>{each.pellet_exp_date}</td>
                                            <td>{each.dose_qty}</td>
                                        </tr>
                                    )} */}

                                    {/* <tr>
                                        <td>T100</td>
                                        <td>9/9/2021</td>
                                        <td>20210909@3</td>
                                        <td>6/1/2022</td>
                                        <td>5</td>
                                    </tr> */}
                                </TBody>
                            </Table>
                        </Box>
                        <TFoot mt='20px' textAlign='right'>
                            {/* {(filtered_dose && filtered_dose?.length > 0) && <Pagination step={15} totalCount={filtered_dose?.length} page={page} setPage={setPage} />} */}
                            {/* {(filtered_dose && filtered_dose?.length > 0) && <Pagination step={cstep} totalCount={filtered_dose?.length} page={page} setPage={setPage} />} */}
                            {(filtered_dose_filter && filtered_dose_filter?.length > 0) && <Pagination step={cstep} totalCount={filtered_dose_filter?.length} page={page} setPage={setPage} />}
                        </TFoot>
                    </Box>

                </Wrapper>
            </StyledContainer>
        </>)
}

const TFoot = styled(Box)``

const Background = styled(Box)`
    position: absolute;
    width: 100%; height: 100%;
    background: url(${BackImg});
    background-size: 100% 100%;
    opacity: .15;
    user-select: none;
    pointer-events: none;
`

const THead = styled(Box)``

const TBody = styled(Box)``

const Table = styled(Box)`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: white;
        font-weight: 600;
    }
    tbody tr {
        transition: .3s;
        background-color: white;
        border-top: 1px solid lightgrey;
    }
    tr:hover {background-color: #eee;}
    th, td {
        padding: 8px;
    }
`
const Wrapper = styled(Box)`
    // background: url(${BackImg});
    // background-size: 100% 100%;
`

const StyledContainer = styled(Box)`
    display: flex;
    flex: 1;
`

export default LotReview