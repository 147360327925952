import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Box, Button } from '@material-ui/core'
import styled from 'styled-components'
import _ from 'lodash'

import { InputDrops } from 'src/components/Dropdowns'
import FormBody from './FormBody'

import { doseTypes, multiplier } from 'src/entry'
import { doGetWholeDoses, doAddNewDispense } from 'src/redux/actions/PatientAction'
import { useOutsideDetector } from 'src/hooks/useOutsideDetector'
import jwtDecode from 'jwt-decode'

const DispenseAdd = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const user = localStorage.getItem('jwtToken')
    const procedures = useSelector(state => state.PatientReducer.procedures)
    const filteredDispense = useSelector(state => state.PatientReducer.filteredDispense)
    const dispense = useSelector(state => state.PatientReducer.dispense)
    const doses = useSelector(state => state.PatientReducer.doses)
   
    const users = useSelector(state => state.AuthReducer.users).filter(u => u.user_type == "Practice")
// console.log(jwtDecode(user).id);
    // if adminstrator then forward to home page
    // if (jwtDecode(user).userType == "Administrator") {
    //     history.push('/');
    // }
    
    
    const [info, setInfo] = useState({
        'dispense_date': `${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${new Date().getDate() <= 9 ? '0' + new Date().getDate() : new Date().getDate()}`,
        'patient_first_name': '',
        'patient_last_name': '',
        'practice_name': jwtDecode(user).userType=="Administrator"?'':jwtDecode(user).userName,
        'practice_id':jwtDecode(user).userType=="Administrator"? '':jwtDecode(user).id,
        'patient_phone_number': '',
        'patient_dob': '',
        'is_new': true,
        'address': '',
        'procedure_id': 0,
    })
    const [practice, setpractice] = useState('')
    const [showError, setShowError] = useState(false)
    const [quantity, setQuantity] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [lotSharp, setLotSharp] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [expDates, setExpDates] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [lotSharpQuantity, setlotSharpQuantity] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '')))
    const [sizeID, setSizeID] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '0')))
    const [inputValid, setInputValid] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => undefined)))
    const [overflowQty, setOverflowQty] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => 0)))
    const [doseID, setDoseID] = useState(Array.from({ length: doseTypes.length }, () => Array.from({ length: multiplier }, () => '0')))
    const [validFlags, setValidFlags] = useState([true, false, false, false])
    const [nameFilterOpen, setNameFilterOpen] = useState(false)
    const [filterName, setFilterName] = useState('')

    const nameTitleRef = useRef()
    const nameContentRef = useRef()

    useEffect(() => {
        dispatch(doGetWholeDoses())
        // console.log("12123123", procedures);
    }, [dispatch])

    const handleCopyNames = (e, each) => {
        let selectedDispense = filteredDispense[_.findIndex(filteredDispense, e => e._id === each._id)]
        setInfo(prev => {
            const tmp = { ...prev };
            tmp.address = selectedDispense.addressInfo.length > 0 ? `${selectedDispense.addressInfo[0].city} ${selectedDispense.addressInfo[0].street} ${selectedDispense.addressInfo[0].state} ${selectedDispense.addressInfo[0].zip}` : ''
            tmp.patient_first_name = selectedDispense.patient_first_name
            tmp.patient_last_name = selectedDispense.patient_last_name
            // tmp.address = selectedDispense.addressInfo.length > 0 ?
            //     `${selectedDispense.addressInfo[0].city ? selectedDispense.addressInfo[0].city : ''}/${selectedDispense.addressInfo[0].street ? selectedDispense.addressInfo[0].street : ''}/${selectedDispense.addressInfo[0].state ? selectedDispense.addressInfo[0].state : ''}/${selectedDispense.addressInfo[0].zip ? selectedDispense.addressInfo[0].zip : ''}` : ''
            tmp.patient_dob = selectedDispense.patient_dob
            tmp.procedure_id = parseInt(selectedDispense.procedure_id)
            return tmp
        })
        setValidFlags(prev => {
            const tmp = [...prev];
            tmp[1] = tmp[2] = tmp[3] = true
            return tmp
        })

        setFilterName(each.patient_first_name + ' ' + each.patient_last_name)
        setNameFilterOpen(false)
    }

    const handleNewPatientDispense = () => {


        let selectedDoses = []
        let dosesAll = [...doses]
        let inputedDoses = [[], []];

        // let originalQty = []
        setShowError(true)
        _.forEach(quantity, (e1, i) => {
            _.forEach(e1, (e2, j) => {
                if (e2 !== '' && lotSharp[i][j] !== '' && expDates[i][j] !== '')
                    inputedDoses[i].push({
                        'dose_size_id': sizeID[i][j],
                        'dose_qty': quantity[i][j],
                        'dose_lot_number': lotSharp[i][j],
                        'pellet_exp_date': expDates[i][j],
                        'input_valid': inputValid[i][j],
                        'overflow_qty': overflowQty[i][j],
                        'dose_id':doseID[i][j]
                    })
            })
        })

        if (validFlags.some(e => e === false))
            alert('credential error')
        else {
            if(user.userType=='Administrator')
            {
                if(practice=='' || practice=="Select")
                {
                    alert('Select Practice')
                    return;
                }

            }
            if (info.is_new && _.filter(dispense, e => e.patient_first_name === info.patient_first_name && e.patient_last_name === info.patient_last_name && e.patient_dob === info.patient_dob).length > 0)
                alert("User you entered already exists")
            else {
                if (inputValid.join(',').search('true') === -1 && inputValid.join(',').search('false') === -1) alert('empty values')
                else
                    if (inputValid.join(',').search('false') === -1) {
                        _.forEach(sizeID, (eachI, i) => {
                            _.forEach(eachI, (eachJ, j) => {
                                //let eachLot = _.filter(dosesAll, dose => dose.dose_size === doseTypes[i].sizes[eachJ].toString() && dose.dose_lot_number === lotSharp[i][j])[0]
                                let eachLot = _.filter(dosesAll, dose => dose.dose_size === doseTypes[i].sizes[eachJ].toString() && dose.dose_lot_number === lotSharp[i][j] && dose.dose_qty === lotSharpQuantity[i][j])[0]
                                if (eachLot) {
                                    //Adds new if doesn't exist on type
                                    if (_.findIndex(selectedDoses, e => e._id === eachLot._id) === -1) {

                                        let tmp = { ..._.filter(dosesAll, dose => dose._id === eachLot._id)[0] }

                                        // let test1 = parseFloat(_.filter(dosesAll, dose => dose._id === eachLot._id)[0].original_qty)
                                        // let test2 = parseFloat(quantity[i][j])

                                        tmp.dose_qty = tmp.dose_qty - parseFloat(quantity[i][j]);
                                        selectedDoses.push(tmp)
                                        //console.log(dosesAll)
                                    }
                                    //Subtracts if exists
                                    else {
                                        selectedDoses[_.findIndex(selectedDoses, e => e._id === eachLot._id)].dose_qty -= parseFloat(quantity[i][j])
                                    }
                                }
                            })
                        })
                        if (_.some(selectedDoses, e => e.dose_qty < 0)) alert("there is minus!!!")
                        else {
                            let form = { dispenseData: info, selDoses: selectedDoses }
                            form.dispenseData.inputedDoses = inputedDoses
                            dispatch({ type: 'SET_WAITING', isWaiting: true })
                            const timer = setTimeout(() => {
                                dispatch(doAddNewDispense(form, history))
                                const innerTimer = setTimeout(() => {
                                    dispatch({ type: 'SET_WAITING', isWaiting: false })
                                    clearTimeout(innerTimer)
                                }, 2000)
                                clearTimeout(timer)
                            }, 3000)
                        }
                    }
                    else
                        alert('validation error')
            }
        }
    }

    useOutsideDetector([nameTitleRef, nameContentRef], setNameFilterOpen)

    const returnFormBodies = (i, each) =>

        _.map(Array(multiplier).fill(''), (e, j) =>
            <FormBody
                key={i * 2 + j} i={i} j={j} each={each}
                showError={showError}
                quantity={quantity[i][j]} setQuantity={setQuantity}
                lotSharp={lotSharp[i][j]} setLotSharp={setLotSharp}
                expDates={expDates[i][j]} setExpDates={setExpDates}
                overflowQty={overflowQty[i][j]} setOverflowQty={setOverflowQty}
                sizeID={sizeID[i][j]} setSizeID={setSizeID}
                doseID={doseID[i][j]} setDoseID={setDoseID}
                inputValid={inputValid[i][j]} setInputValid={setInputValid}
                lotSharpQuantity={lotSharpQuantity[i][j]} setlotSharpQuantity={setlotSharpQuantity}
                practice={practice}
            />
        )

    return (
        <StyledContainer>
            <Box fontSize='20px' fontWeight='600'>Patient Dispense</Box>
            {/* <Box fontSize='20px' fontWeight='600'>New Patients</Box> */}
            <Box>
                <VerifyHeader>
                    <Box display='flex' alignItems='center' mt='15px'>
                        {/* <Box width='150px'>Date:</Box> */}
                        <Box width='150px'>Dispense Date:</Box>
                        <Box component='input' fontSize='14px' width='300px' type='date'
                            value={info.dispense_date} onChange={(e) => {
                                setInfo(prev => { const tmp = { ...prev }; tmp.dispense_date = e.target.value; return tmp })
                                setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[0] = true : tmp[0] = false; return tmp })
                            }}
                            max="9999-12-31"
                        />
                    </Box>
                    {jwtDecode(user).userType == "Administrator"?(
                            <Box display='flex' alignItems='center' mt='15px'>
                                <Box width='150px'>Practice:</Box>
                                    <ComboBox>
                                            <select onChange={(e) => {
                                                //console.log(e.target[e.target.selectedIndex],e.target.value);
                                                setInfo(prev => { const tmp = { ...prev }; tmp.practice_name = e.target.value; return tmp })
                                                setInfo(prev => { const tmp = { ...prev }; tmp.practice_id = e.target[e.target.selectedIndex].id; return tmp })
                                                setpractice(e.target[e.target.selectedIndex].id)
                                            }}>
                                                <option value="Select">Select Practice</option>
                                                {
                                                    // users.map((u) => <option value={u.user_name} key={u.UsernameKey} id={u._id}>{'('+u.user_first_name+' '+u.user_last_name+') '+' '+ u.user_name}</option>)
                                                    users.map((u) => <option value={u.user_name} key={u.UsernameKey} id={u._id}>{u.biosana_id+' ('+u.practice_name+' '+u.practioner_name+')'}</option>)
                                                }
                                            </select>
                                    </ComboBox>       
                            </Box>
                        ):''
                    }
                    <Box display='flex' alignItems='center' mt='15px'>
                        <Box width='150px'>New Patient?</Box>
                        <ComboBox width='300px'>
                            <Box component='select' width='100%'
                                value={info.is_new ? 1 : 0} onChange={(e) => {
                                    setInfo(prev => { const tmp = { ...prev }; tmp.is_new = Boolean(parseInt(e.target.value)); return tmp })
                                    if (parseInt(e.target.value) === 1)
                                        setInfo(prev => { const tmp = { ...prev }; tmp.patient_first_name = tmp.patient_last_name = ''; return tmp })
                                }}
                            >
                                <option value='0'>No</option>
                                <option value='1'>Yes</option>
                            </Box>
                        </ComboBox>
                    </Box>
                    {!info.is_new &&
                        <Box display='flex' alignItems='center' mt='15px'>
                            <Box width='150px'>Filter</Box>
                            <Box position='relative' ref={nameTitleRef}>
                                <Box component='input' fontSize='14px' width='300px' position='relative'
                                    value={filterName}
                                    placeholder='Please input name'
                                    onFocus={() => setNameFilterOpen(true)}
                                    onChange={(e) => {
                                        setFilterName(e.target.value)
                                        dispatch({ type: 'PATIENT_SEACH', value: e.target.value })
                                    }}
                                />
                                {nameFilterOpen &&
                                    <InputDrops
                                        contentRef={nameContentRef}
                                        data={filteredDispense}
                                        handleCopyNames={handleCopyNames}
                                        practice={practice}
                                    />
                                }
                            </Box>
                        </Box>
                    }
                    <Box display='flex' alignItems='center' mt='15px'>
                        <Box width='150px'>First Name:</Box>
                        <Box component='input' fontSize='14px' width='300px'
                            value={info.patient_first_name} {...{ disabled: !info.is_new }} onChange={(e) => {
                                setInfo(prev => { const tmp = { ...prev }; tmp.patient_first_name = e.target.value; return tmp })
                                setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[1] = true : tmp[1] = false; return tmp })
                            }}
                        />
                        <Box component='span' ml={1} color='red'>*</Box>
                    </Box>
                    <Box display='flex' alignItems='center' mt='15px'>
                        <Box width='150px'>Last Name:</Box>
                        <Box component='input' fontSize='14px' width='300px'
                            value={info.patient_last_name} {...{ disabled: !info.is_new }} onChange={(e) => {
                                setInfo(prev => { const tmp = { ...prev }; tmp.patient_last_name = e.target.value; return tmp })
                                setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[2] = true : tmp[2] = false; return tmp })
                            }}
                        />
                        <Box component='span' ml={1} color='red'>*</Box>
                    </Box>
                    <Box display='flex' alignItems='center' mt='15px'>
                        <Box width='150px'>Address:</Box>
                        <Box component='input' fontSize='14px' width='300px'
                            placeholder='City/Street/State/Zip'
                            value={info.address} onChange={(e) =>
                                setInfo(prev => { const tmp = { ...prev }; tmp.address = e.target.value; return tmp })
                            }
                        />
                    </Box>
                    <Box display='flex' alignItems='center' mt='15px'>
                        <Box width='150px'>Phone:</Box>
                        {/* old */}
                        {/* <Box component='input' fontSize='14px' width='300px'
                            placeholder='XXX-XXX-XXXX'
                            value={info.patient_phone_number} onChange={(e) =>
                                setInfo(prev => { const tmp = { ...prev }; tmp.patient_phone_number = e.target.value; return tmp })
                            }
                        /> */}
                        <Box component='input' fontSize='14px' width='300px'
                            placeholder='XXXXXXXXXX'
                            value={info.patient_phone_number} onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }} onChange={(e) =>
                                setInfo(prev => { const tmp = { ...prev }; tmp.patient_phone_number = e.target.value; return tmp })
                            }
                        />
                    </Box>
                    <Box display='flex' alignItems='center' mt='15px'>
                        <Box width='150px'>DOB:</Box>
                        <Box component='input' fontSize='14px' width='300px' type='date'
                            value={info.patient_dob} {...{ disabled: !info.is_new }} onChange={(e) => {
                                setInfo(prev => { const tmp = { ...prev }; tmp.patient_dob = e.target.value; return tmp })
                                setValidFlags(prev => { const tmp = [...prev]; e.target.value !== '' ? tmp[3] = true : tmp[3] = false; return tmp })
                            }}
                            max="9999-12-31"
                        />
                        <Box component='span' ml={1} color='red'>*</Box>
                    </Box>
                    <Box display='flex' alignItems='center' mt='15px'>
                        <Box width='150px'>Procedure:</Box>
                        <ComboBox width='300px'>
                            <Box component='select' width='100%'
                                value={info.procedure_id} onChange={(e) =>
                                    setInfo(prev => { const tmp = { ...prev }; tmp.procedure_id = e.target.value; return tmp })
                                }
                            >
                                {_.map(procedures, (each, i) =>
                                    <option key={i} value={each.procedure_id}>{each.procedure_name}</option>
                                )}
                            </Box>
                        </ComboBox>
                    </Box>
                </VerifyHeader>
            </Box>
            {_.map(doseTypes, (each, i) =>
                <Box key={i} mt='30px'>
                    <Box fontSize='20px' fontWeight='600'>{each.name}:</Box>
                    <FormHead display='flex' flexWrap='wrap'>
                        <BlankColumn width='150px' fontSize='20px' fontWeight='600' />
                        <Box width='200px' fontSize='20px' fontWeight='600'>Qty</Box>
                        <Box width='180px' ml='50px' fontSize='20px' fontWeight='600'>Lot</Box>
                        <Box width='200px' ml='50px' fontSize='20px' fontWeight='600'>Exp Date</Box>
                    </FormHead>
                    <FormBodyWrapper>
                        {returnFormBodies(i, each)}
                    </FormBodyWrapper>
                </Box>
            )}
            <Action mt='30px'>
                <MyButton variant='contained' color='primary' onClick={handleNewPatientDispense}>Save</MyButton>
                <MyButton variant='contained' color='secondary' onClick={() => history.goBack()}>Cancel</MyButton>
            </Action>
        </StyledContainer >
    )
}

const FormBodyWrapper = styled(Box)`
    >div+div {
        margin-top: 15px;
    }
`

const ComboBox = styled(Box)`
    padding-right: 8px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    @media (max-width: 1040px) {
        width: 100% !important;
    }
    &:focus {
        border-color: red;
    }
`

const VerifyHeader = styled(Box)`
    >div {
        @media (max-width: 1040px) {
            align-items: initial;
            flex-direction: column;
            >input {
                width: 100%;
            }
        }
    }
`

const Action = styled(Box)`
    display: flex;
    justify-content: flex-end;
    >button+button {
        margin-left: 30px;
    }
`

const MyButton = styled(Button)`
    text-transform: none !important;
`

const BlankColumn = styled(Box)`
    @media (max-width: 1040px) {
        display: none;
    }
`

const FormHead = styled(Box)`
    @media (max-width: 1040px) {
        >div {
            flex: 1;
        }
        >div:not(:first-of-type) {
            display: none;
            margin-left: unset;
        }
    }
`

const StyledContainer = styled(Box)`
    padding: 20px;
    input {
        outline: none;
        border: 1.5px solid lightgrey;
        border-radius: 5px;
        transition: .3s;
        padding: 5px;
        height: fit-content;
        align-self: center;
        &:focus {
            border-color: #c850c0;
        }
        &:disabled {
            cursor: not-allowed;
            background-color: -internal-light-dark(rgba(209, 209, 209, 0.3), rgba(29, 29, 29, 0.3)) !important;
        }
    }
    select {
        border: none;
        outline: none;
        padding: 4px;
        border-radius: 5px;
    }
`

export default DispenseAdd