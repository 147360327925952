import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Fade } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import _ from 'lodash'

import { InvDeletedRow } from 'src/components/Rows'
import { InvAddModal, InvEditModal, ConfirmModal } from 'src/components/Modals'
import Pagination from 'src/components/Paginations'
import ColumnDrops from 'src/components/Dropdowns/ColumnDrops'
import SearchInput from 'src/components/Inputs/SearchInput'

import { doDeleteInv } from 'src/redux/actions/InvAction'

import BackImg from 'src/assets/images/backs/procedure_bg.jpg'

const InvAdjustmentDeleted = () => {
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [openNewInv, setOpenNewInv] = useState(false)
    const [openMsg, setOpenMsg] = useState(false)
    const [index, setIndex] = useState(-1)
    const [jIndex, setJIndex] = useState('')
    const [page, setPage] = useState(1)
    const [flags, setFlags] = useState([true, false, false,false,false])
    const [keyword, setKeyword] = useState('')

    const openToast = useSelector(state => state.AuthReducer.openToast)
    const toastContent = useSelector(state => state.AuthReducer.toastContent)
    const inventories = useSelector(state => state.InvDeletedReducer.inventories_deleted);
    const [current_inventory,setCurrentdInventories] = useState('')

    // for (let i = 0; i < inventories.length; i++) {
    //     //console.log(inventories.practice_name + "  inventories");

    // }
    
    let totalrows=inventories.length;
    // console.log(totalrows,keyword);
    if(keyword !='')
    {
        totalrows=0;

        for(let x=0;x<inventories.length;x++)
        {
            if((flags[0] && inventories[x].inv_date.search(keyword) !== -1) ||
            (flags[1] && inventories[x].practice_name.search(keyword) !== -1) ||
            (flags[2] && inventories[x].inv_receipt_verified_by.search(keyword) !== -1) || (flags[3] && inventories[x].deleted_by.search(keyword) !== -1) || (flags[4] && inventories[x].createdAt.search(keyword) !== -1) || (flags[5] && inventories[x].created_inv_at.search(keyword) !== -1))
            {
                totalrows++;
            }
        }
    }

    useEffect(() => {
        if (openToast) {
            const timer = setTimeout(() => {
                dispatch({ type: 'TOAST', flag: false, toastContent: toastContent })
                clearTimeout(timer)
            }, 5000)
        }
    }, [openToast, dispatch, toastContent])

    const handleDelete = () => {
        dispatch(doDeleteInv(jIndex))
    }

    return (
        <StyledContainer>
            <Background />
            <Box padding='20px'>
                <Box fontSize='20px' fontWeight='600'>Deleted IA Log</Box>
                <Fade in={openToast} timeout={500}>
                    <CredentialAlert severity='info'>{toastContent}</CredentialAlert>
                </Fade>
                <Box display='flex' justifyContent='flex-end'>
                    <ColumnDrops flags={flags} setFlags={setFlags} filterValues={['Date', 'Practice Name', 'Verifier', 'Deleted By','Deleted At','Created At']} />
                    <SearchInput keyword={keyword} setKeyword={setKeyword} />
                </Box>
                <Box width='100%' boxShadow='2px 2px 5px 1px #ccc' mt={3} overflow='auto'>
                    <Table width='100%' component='table'>
                        <THead component='thead'>
                            <tr>
                                <th>No</th>
                                <th>Date</th>
                                <th>Practice Name</th>
                                <th>Verifier</th>
                                <th>Deleted By</th>
                                <th>Deleted At</th>
                                <th>Created At</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </THead>
                        <TBody component='tbody' pb='20px'>
                            {flags.some(e => e === true) ? _.map(_.filter(_.filter(inventories, e => (flags[0] && e.inv_date.search(keyword) !== -1) ||
                                (flags[1] && e.practice_name_new.search(keyword) !== -1) ||
                                (flags[2] && e.inv_receipt_verified_by.search(keyword) !== -1) || (flags[3] && e.deleted_by.search(keyword) !== -1) || (flags[4] && e.createdAt.search(keyword) !== -1) || (flags[5] && e.created_inv_at.search(keyword) !== -1)
                            ), (e, uIndex) => uIndex >= 20 * (page - 1) && uIndex <= 20 * (page - 1) + 19), (each, i) =>
                                <InvDeletedRow
                                    id={i + 20 * (page - 1)} key={i}
                                    eachInfo={each}
                                    setOpen={setOpen} setOpenMsg={setOpenMsg}
                                    setIndex={setIndex} setJIndex={setJIndex} setCurrentdInventories={setCurrentdInventories}
                                />
                            ) :
                                _.map(_.filter(_.filter(inventories, e => (e.inv_date.search(keyword) !== -1) ||
                                    (e.practice_name_new.search(keyword) !== -1) ||
                                    (e.inv_receipt_verified_by.search(keyword) !== -1) || (e.deleted_by.search(keyword) !== -1) || (e.createdAt.search(keyword) !== -1) || (e.created_inv_at.search(keyword) !== -1)
                                ), (e, uIndex) => uIndex >= 20 * (page - 1) && uIndex <= 20 * (page - 1) + 19), (each, i) =>
                                    <InvDeletedRow
                                        id={i + 20 * (page - 1)} key={i}
                                        eachInfo={each}
                                        setOpen={setOpen} setOpenMsg={setOpenMsg}
                                        setIndex={setIndex} setJIndex={setJIndex} setCurrentdInventories={setCurrentdInventories}
                                    />
                                )
                            }
                        </TBody>
                    </Table>
                </Box>
                {/* old */}
                {/* {(inventories && inventories.length > 0) && <Pagination step={20} totalCount={inventories.length} page={page} setPage={setPage} />} */}
                {(inventories && inventories.length > 0) && <Pagination step={20} totalCount={totalrows} page={page} setPage={setPage} keyword={keyword} />}
            </Box>
            <InvAddModal open={openNewInv} setOpen={setOpenNewInv} />
            {/* <InvEditModal open={open} setOpen={setOpen} where={jIndex} inventory={inventories[index]} /> */}
            <InvEditModal open={open} setOpen={setOpen} where={jIndex} inventory={current_inventory} />
            {/* <ConfirmModal open={openMsg} setOpen={setOpenMsg} confirmDelete={handleDelete} /> */}
        </StyledContainer >
    )
}

const CredentialAlert = styled(Alert)`
    position: fixed;
    top: 70px;
    right: 25px;
    z-index: 1;
`

// const MyButton = styled(Button)`
//     text-transform: none !important;
// `

const THead = styled(Box)``

const TBody = styled(Box)``

// const TFoot = styled(Box)``

const Table = styled(Box)`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: white;
        font-weight: 600;
    }
    tbody tr {
        transition: .3s;
        background-color: white;
        border-top: 1px solid lightgrey;
    }
    tr:hover {background-color: #eee;}
    th, td {
        padding: 8px;
    }
`

const Background = styled(Box)`
    position: absolute;
    width: 100%; height: 100%;
    background: url(${BackImg});
    background-size: 100% 100%;
    background-position-y: bottom;
    background-repeat: no-repeat;
    opacity: .15;
    user-select: none;  
    pointer-events: none;
`

const StyledContainer = styled(Box)`
    min-height: 500px;
    position: relative;
    
`

export default InvAdjustmentDeleted