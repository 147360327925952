import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@material-ui/core'
import styled from 'styled-components'
import Particles from 'react-tsparticles';
import Topbar from './Topbar'
import Footer from './Footer'

const Layout = ({ children }) => {
    const user = localStorage.getItem('jwtToken')
    const isWaiting = useSelector(state => state.AuthReducer.isWaiting)
    const particlesInit = (main) => {
    };

    const particlesLoaded = (container) => {
    };

    return (
        <StyledContainer>
            {/* <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    // background: {
                    //   color: {
                    //     value: "#0d47a1",
                    //   },
                    // },
                    fpsLimit: 100,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            bubble: {
                                distance: 100,
                                duration: 1,
                                opacity: 0.2,
                                size: 100,
                            },
                            push: {
                                quantity: 3,
                            },
                            repulse: {
                                distance: 150,
                                duration: 0.6,
                            },
                        },
                    },
                    particles: {
                        color: {
                            // value: "#495193",
                            value: "#37A6C5",
                        },
                        links: {
                            // color: "#495193",
                            color: "#37A6C5",
                            distance: 150,
                            enable: true,
                            opacity: 0.8,
                            width: 0.7,
                        },
                        collisions: {
                            enable: true,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outMode: "bounce",
                            random: false,
                            speed: 1,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 1000,
                            },
                            value: 40,   // count
                        },
                        opacity: {
                            value: 0.3,
                        },
                        shape: {
                            "type": "circle",
                            "stroke": {
                                "width": 3,
                                "color": "#37A6C5"
                            },
                            "polygon": {
                                "nb_sides": 5
                            },
                        },
                        size: {
                            random: true,
                            value: 5,
                        },
                    },
                    detectRetina: false,
                }}
            /> */}
            <Topbar />
            {isWaiting && <Waiting position='fixed' width='100%' height='100%' bgcolor='white'>
                <CircularProgress />
            </Waiting>
            }
            <Body component='main' auth={user !== null ? 1 : 0}>
                {children}
            </Body>
            <Footer />
        </StyledContainer>
    )
}

const Waiting = styled(Box)`
    z-index: 1;
    opacity: .7;
    >div {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
    }
`

const StyledContainer = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: rgb(255, 255, 255);
`

const Body = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 56px;
    display: flex;
    /* background-image: url(${require("../assets/images/back/13.jpg").default});
    background-size: 100% 100%;
    background-repeat: no-repeat; */
    @media (min-width: 1024px) {
        margin-top: 64px;
    }
`

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout