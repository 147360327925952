export const doseTypes = [
    {
        name: 'Estradiol',
        sizes: [
            6, 10, 12.5, 15
        ]
    },
    {
        name: 'Testosterone',
        sizes: [
            25, 37.5, 50, 87.5, 100, 200
        ]
    }
]

export const multiplier = 4