import { DISPENSE_DEL_CRUD, PATIENT_DEL_SEACH, PROCEDURE_DEL_CRUD, PROCEDURE_DEL_SEARCH, LOT_DEL_CRUD, ORIGINAL_DEL_CRUD} from 'src/redux/actions/types'

import _ from 'lodash'

const initState = {
    dispense: [],
    filteredDispense: [],
    procedures: [],
    filteredProcedures: [],
    doses: [],
    filteredLots: [],
    isWaiting: false,
    originalQty: []
};

const removeDuplicates = (originalArray, prop) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
}

export default function todo(state = initState, action) {
    let tmp, tmpFiltered
    switch (action.type) {
        case ORIGINAL_DEL_CRUD.READ:
            return {
                ...state,
                originalQty: action.originals,
            }
        case LOT_DEL_CRUD.READ:
            return {
                ...state,
                doses: action.doses,
                filteredDoses: action.doses
            }
        case PROCEDURE_DEL_SEARCH:
            return {
                ...state,
                filteredProcedures: _.filter(state.procedures, each => each.procedure_name.search(action.value) !== -1)
            }
        case PATIENT_DEL_SEACH:
            return {
                ...state,
                filteredDispense: _.filter(state.dispense, each => (each.patient_first_name.toLowerCase() + ' ' + each.patient_last_name.toLowerCase()).search(action.value.toLowerCase()) !== -1)
            }
        case PROCEDURE_DEL_CRUD.READ:
            return {
                ...state,
                procedures: action.procedures,
                filteredProcedures: action.procedures,
            }
        case DISPENSE_DEL_CRUD.CREATE:
            return {
                ...state,
                isWaiting: true,
                dispense: action.dispense,
                originalQty: action.originalQty,
                filteredDispense: removeDuplicates(action.dispense, "patient_first_name")
            }
        case DISPENSE_DEL_CRUD.READ:
            return {
                ...state,
                dispense: action.dispense,
                filteredDispense: removeDuplicates(action.dispense, "patient_first_name")
            }
        case DISPENSE_DEL_CRUD.UPDATE:
            const dispenseData = action.updatedData.dispenseData
            tmp = [...state.dispense]
            dispenseData._id = action.updatedData.where
            tmp[_.findIndex(tmp, item => item._id === action.updatedData.where)] = action.updatedData.dispenseData
            return {
                ...state,
                dispense: tmp,
            }
        case DISPENSE_DEL_CRUD.DELETE:
            tmp = [...state.dispense]
            tmp.splice(_.findIndex(e => e._id === action.id), 1)
            tmpFiltered = [...state.filteredDispense]
            tmpFiltered.splice(_.findIndex(e => e._id === action.id), 1)
            return {
                ...state,
                dispense: tmp,
                filteredDispense: tmpFiltered,
            }
        default:
            return state
    }
}