import { Box } from '@material-ui/core'
import styled from 'styled-components'

const Badge = ({ type }) => {
    return (
        <StyledContainer type={type}>
            {type}
        </StyledContainer>
    )
}

const StyledContainer = styled(Box)`
    width: fit-content;
    border-radius: 7px;
    padding: 2px 10px;
    background-color: ${({ type }) => {
        if (type === 'Created') return 'rgb(210, 243, 222)'
        if (type === 'Updated') return 'rgb(215, 236, 243)'
        if (type === 'Deleted') return 'rgb(251, 216, 208)'
        if (type === 'Returned') return 'rgb(251, 216, 208)'
        else return 'rgb(14 96 173)'
    }};
    color: ${({ type }) => {
        if (type === 'Created') return 'rgb(39, 172, 93)'
        if (type === 'Updated') return 'rgb(118, 189, 218)'
        if (type === 'Deleted') return 'rgb(211, 84, 56)'
        if (type === 'Returned') return 'rgb(211, 84, 56)'
        else return 'rgb(255 255 255)'
    }};
`

export default Badge