import { Box } from '@material-ui/core'
import { EditOutlined, DeleteOutlined } from '@material-ui/icons'
import styled from 'styled-components'

const InvDeletedRow = ({ id, eachInfo, setOpen, setOpenMsg, setIndex, setJIndex,setCurrentdInventories }) => {
    return (
        <StyledContainer component='tr'>
            <td>{id + 1}</td>
            <td>{eachInfo.inv_date.slice(0, 10)}</td>
            {/* <td>{eachInfo.practice_name}</td> */}
            <td>{eachInfo.practice_name_new}</td>
            <td>{eachInfo.inv_receipt_verified_by}</td>
            <td>{eachInfo.deleted_by}</td>
            <td>{eachInfo.createdAt}</td>
            <td>{eachInfo.created_inv_at}</td>
            <td>
                <EditButton onClick={() => {
                    setCurrentdInventories(eachInfo)
                    setIndex(id)
                    setJIndex(eachInfo._id)
                    setOpen(true)
                }} />
            </td>
        </StyledContainer>
    )
}

const EditButton = styled(EditOutlined)`
    cursor: pointer;
    opacity: .5;
    &:hover {
        transition: .3s;
        opacity: 1;
    }
`

const DeleteButton = styled(DeleteOutlined)`
    cursor: pointer;
    opacity: .5;
    &:hover {
        transition: .3s;
        opacity: 1;
    }
`

const StyledContainer = styled(Box)`
`

export default InvDeletedRow