import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import { ArrowBack, ArrowForward, ExpandMore, FilterList } from '@material-ui/icons'
import styled from 'styled-components'
import Check from 'src/assets/images/checkmark.png'
import _ from 'lodash'

const FilterBar = ({ pushed, setPushed, check_patient, set_patient,name_patient,set_name_patient, check_practice, set_practice, check_procedure, set_procedure, date, set_date ,date1, set_date1 }) => {
    const [opens, setOpens] = useState([false, false])
    const dispense = useSelector(state => state.PatientReducer.dispense)
    const procedure = useSelector(state => state.PatientReducer.procedures)
    const user = JSON.parse(localStorage.getItem('uid'))

    return (
        <>
            {pushed ?
                <StyledContainer>
                    <Box padding='10px' fontWeight='700' fontSize='20px' display='flex' alignItems='center' color='black' justifyContent='space-between'>
                        <Box display='flex'>
                            <FilterList />
                            <Box ml={1}>Filter</Box>
                        </Box>
                        <OmitButton onClick={() => setPushed(false)}>
                            <ArrowBack fill='black' />
                        </OmitButton>
                    </Box>
                    <FilterFactor active={opens[0] ? 1 : 0}>
                        <Box padding='10px' borderTop='1px solid lightgrey' borderBottom='1px solid lightgrey' >
                            <Box display='flex' justifyContent='space-between' alignItems='center' onClick={() => {
                                let tmp = [...opens]
                                tmp[0] = !tmp[0]
                                setOpens(tmp)
                            }}>
                                <Box fontSize='18px' color='black' fontWeight='500'>Patient Name</Box>
                                <ExpandMoreIcon />
                            </Box>
                        </Box>
                        {opens[0] &&
                            <Box fontSize='16px' color='#555' overflow='auto' maxHeight='200px'>
                                {_.map(dispense, (each, i) => (
                                    //console.log(each.patient_first_name)
                                    <FilterItem key={i} pt={1} pb={1} onClick={() => {
                                        let temp = [...check_patient]
                                        temp[i] = !temp[i]
                                        set_patient(temp)
                                        //set_name_patient([each.patient_first_name + ' ' + each.patient_last_name]);
                                    }}>{each.patient_first_name + ' ' + each.patient_last_name}{!check_patient[i] && <img src={Check} width='20px' height='20px' alt='' />}</FilterItem>
                                ))
                                }
                            </Box>
                        }
                    </FilterFactor>
                  
                  
                   




                    <FilterFactor active={opens[2] ? 1 : 0}>
                        <Box padding='10px' borderTop='1px solid lightgrey' borderBottom='1px solid lightgrey' >
                            <Box display='flex' justifyContent='space-between' alignItems='center' onClick={() => {
                                let tmp = [...opens]
                                tmp[2] = !tmp[2]
                                setOpens(tmp)
                            }}>
                                <Box textOverflow='ellipsis' fontSize='18px' color='black' fontWeight='500'>Last Dispense Date</Box>
                                <ExpandMoreIcon />
                            </Box>
                        </Box>
                        {opens[2] &&
                            <Box fontSize='16px' color='#555' overflow='auto' maxHeight='200px'>
                                <Box display="flex" alignItems="center" marginLeft="10px">From:<FilterItem pt={1} pb={1}><Box component='input' {...{ type: 'date' }} style={{ border: 'solid 1px' }} width="160px" value={date} onChange={(e) => {

                                    set_date(e.target.value)
                                }} 
                                max="9999-12-31"
                                /></FilterItem></Box>
                                <Box display="flex" alignItems="center" marginLeft="32px">To:<FilterItem pt={1} pb={1}><Box component='input' {...{ type: 'date' }} style={{ border: 'solid 1px' }} width="160px" value={date1} onChange={(e) => {

                                    set_date1(e.target.value)
                                }} 
                                max="9999-12-31"
                                /></FilterItem></Box>
                            </Box>
                        }
                    </FilterFactor>
                    <FilterFactor active={opens[3] ? 1 : 0}>
                        <Box padding='10px' borderTop='1px solid lightgrey' borderBottom='1px solid lightgrey' >
                            <Box display='flex' justifyContent='space-between' alignItems='center' onClick={() => {
                                let tmp = [...opens]
                                tmp[3] = !tmp[3]
                                setOpens(tmp)
                            }}>
                                <Box fontSize='18px' color='black' fontWeight='500'>Procedure</Box>
                                <ExpandMoreIcon />
                            </Box>
                        </Box>
                        {opens[3] &&
                            <Box fontSize='16px' color='#555' overflow='auto' maxHeight='200px'>
                                {/* {_.map(procedure, (each, i) => (
                                    //console.log(each.patient_first_name)
                                    <FilterItem key={i} pt={1} pb={1} onClick={() => {
                                        let temp = [...check_procedure]
                                        temp[i] = !temp[i]
                                        set_procedure(temp)
                                    }}>{each.procedure_name}{!check_procedure[i] && <img src={Check} width='20px' height='20px' alt='' />}</FilterItem>
                                ))
                                } */}

                                {_.map(procedure, (each, i) => (
                                    //console.log(each.patient_first_name)
                                    <FilterItem key={i} pt={1} pb={1} onClick={() => {
                                        let temp = [...check_procedure]
                                        temp[i] = !temp[i]
                                        set_procedure(temp)
                                    }}>{each.procedure_name}{check_procedure[i] && <img src={Check} width='20px' height='20px' alt='' />}</FilterItem>
                                ))
                                }
                            </Box>
                        }
                    </FilterFactor>
                </StyledContainer>
                :
                <OmittedContainer padding='14px'>
                    <Box onClick={() => setPushed(true)}>
                        <ArrowForward />
                    </Box>
                </OmittedContainer>
            }
        </>
    )
}

const OmittedContainer = styled(Box)`
    min-height: 300px;
    width: 50px;
    box-shadow: 0px -4px 4px 1px lightgrey;
    display: none;
    @media (min-width: 700px) {
        display: initial;
    }
    >div {
        cursor: pointer;
    }
`

const OmitButton = styled(Box)`
    cursor: pointer;
`

const ExpandMoreIcon = styled(ExpandMore)``

const FilterItem = styled(Box)`
    padding-left: 15px;
    transition: .5s;
    background: white;
    display: flex;
    justify-content: space-between;
    &:hover {
        background: #eee;
    }
`

const FilterFactor = styled(Box)`
    cursor: pointer;
    ${ExpandMoreIcon} {
        transition: .3s;
        transform: rotate(${({ active }) => active ? '-180deg' : '0deg'});
    }
`

const StyledContainer = styled(Box)`
    min-height: 300px;
    width: 250px;
    box-shadow: 0px -4px 4px 1px lightgrey;
    display: none;
    @media (min-width: 700px) {
        display: initial;
    }
    input {
        border: 1px solid lightgrey;
        border-radius: 5px;
        outline: none;
    }
`

export default FilterBar