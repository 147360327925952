import _ from 'lodash'

import { AUTH_CRUD, AUTH_EXPIRE, USER_TYPES_GET, AUTH_RESULT, LOGOUT, SET_WAITING, TOAST } from 'src/redux/actions/types'

const initState = {
    openToast: false,
    isWaiting: false,
    id: 0,
    userTypes: [],
    user: {},
    users: [],
    user_id: {},
    isValid: true,
    toastContent: '',
    formData: {
        username: '',
        password: ''
    }
}

export default function todo(state = initState, action) {
    let tmp
    const updatedData = action.updatedData
    switch (action.type) {
        case TOAST:
            return {
                ...state,
                openToast: action.flag,
                toastContent: action.toastContent
            }
        case AUTH_CRUD.CREATE:
            tmp = [...state.users]
            tmp.push(action.newUser)
            return {
                ...state,
                users: tmp,
                openToast: action.flag,
                toastContent: action.toastContent
            }
        case AUTH_CRUD.READ:
            return {
                ...state,
                users: _.filter(action.users, e => e.flag_delete === false)
            }
        case AUTH_CRUD.UPDATE:
            tmp = [...state.users]
            updatedData.password = ''
            tmp[tmp.findIndex(item => item._id === action.updatedData._id)] = updatedData
            return {
                ...state,
                users: tmp,
            }
        case AUTH_EXPIRE.ADDEXPIRATION:
            tmp = [...state.users]
            tmp[tmp.findIndex(item => item._id === action.updatedData._id)] = action.updatedData
            return {
                ...state,
                users: tmp,
            }
        case AUTH_EXPIRE.SETFULLTIME:
            tmp = [...state.users]
            tmp[tmp.findIndex(item => item._id === action.updatedData._id)] = action.updatedData
            return {
                ...state,
                users: tmp,
            }
        case AUTH_CRUD.DELETE:
            tmp = [...state.users]
            tmp.splice(tmp.findIndex(item => item._id === action.id), 1)
            return {
                ...state,
                users: tmp
            }
        case SET_WAITING:
            return {
                ...state,
                isWaiting: action.isWaiting
            }
        case USER_TYPES_GET:
            return {
                ...state,
                userTypes: action.userTypes

            }
        case LOGOUT:
            return {
                ...state,
                user: {}
            }
        case AUTH_RESULT.SUCCESS:
            return {
                ...state,
                user: action.cur_user,
                user_id: action.cur_user_id
            }
        case AUTH_RESULT.CRASH:
            return {
                ...state,
                isValid: action.payload,
                error: action.error
            }
        default:
            return state
    }
}