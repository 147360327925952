import { DISPENSE_CRUD, PROCEDURE_CRUD, LOT_CRUD, TOAST,DISPENSE_DEL_CRUD,PROCEDURE_DEL_CRUD } from './types'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import * as PAGES from '../../constants/pages'

export function doGetWholeProcedure() {
    return (dispatch) => {
        axios.get('/api/procedureController/doGetWholeProcedure')
            .then((res) => {
                dispatch({
                    type: PROCEDURE_CRUD.READ,
                    procedures: res.data
                })
            })
    }
}

// get by logged in user 
export function doGetWholeDoses() {
    return (dispatch) => {
        let user = JSON.parse(localStorage.getItem('uid'))

        axios.get(`/api/patientController/doGetWholeDoses/${user?.userType}/${user?.userName}`)
            .then((res) => {
                if (res.data.success)
                    dispatch({
                        type: LOT_CRUD.READ,
                        doses: res.data.doses
                    })
                else alert(res.data.err)
            })
    }
}

export function doGetWholeDispense() {
    return (dispatch) => {
        let user = JSON.parse(localStorage.getItem('uid'))

        axios.get(`/api/patientController/doGetWholeDispense/${user?.userType}/${user?.userName}`)
            .then((res) => {
                // console.log("iiiiiiiiiiiiiiii", res.data)
                dispatch({
                    type: DISPENSE_CRUD.READ,
                    dispense: res.data
                })
            })
    }
}

export function doAddNewDispense(formData, history) {
    return (dispatch) => {
        let user = JSON.parse(localStorage.getItem('uid'))
        
        axios.post(`/api/patientController/doAddNewDispense/${user?.userType}/${user?.userName}`, formData)
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: DISPENSE_CRUD.CREATE,
                        dispense: res.data.dispense,
                        originalQty: formData.originalQty
                    })
                    let logData = JSON.parse(localStorage.getItem("logData") || "[]")
                    logData.push({
                        'userName': localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken')).userName,
                        'actionShort': 'Created',
                        'action': 'created a dispense',
                        'actionDate': new Date()
                    })
                    localStorage.setItem('logData', JSON.stringify(logData))
                    history.push(`/${PAGES.DISPENSEEDIT}`)
                }
                else alert('error occur')
            })
    }
}

export function doUpdateDispense(formData) {
    return (dispatch) => {
        axios.post('/api/patientController/doUpdateDispense', formData)
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: DISPENSE_CRUD.UPDATE,
                        updatedData: formData,
                    })
                    dispatch({ type: TOAST, flag: true, toastContent: 'Update success' })
                    let logData = JSON.parse(localStorage.getItem("logData") || "[]")
                    logData.push({
                        'userName': localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken')).userName,
                        'actionShort': 'Updated',
                        'action': 'updated an dispense',
                        'actionDate': new Date()
                    })
                    localStorage.setItem('logData', JSON.stringify(logData))
                }
                else alert('error occur')
            })
    }
}

export function doDeleteDispense(id,all_doses) {
    let user = JSON.parse(localStorage.getItem('uid'))
    if(user?.userType!='Practice')
    {
        all_doses=[];
    }
    return (dispatch) => {
        axios.post('/api/patientController/doDeleteDispense', { id: id,all_doses:all_doses,user:user })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: DISPENSE_CRUD.DELETE,
                        id: id,
                    })
                    dispatch({ type: TOAST, flag: true, toastContent: 'Delete success' })
                    window.location.reload();
                    let logData = JSON.parse(localStorage.getItem("logData") || "[]")
                    logData.push({
                        'userName': localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken')).userName,
                        'actionShort': 'Deleted',
                        'action': 'deleted an dispense',
                        'actionDate': new Date()
                    })
                    localStorage.setItem('logData', JSON.stringify(logData))
                }
                else alert('error occur')
            })
    }
}

export function doGetWholeDispenseDel() {
    return (dispatch) => {
        let user = JSON.parse(localStorage.getItem('uid'))

        axios.get(`/api/patientController/doGetWholeDispenseDeleted/${user?.userType}/${user?.userName}`)
            .then((res) => {
                // console.log("iiiiiiiiiiiiiiii", res.data)
                dispatch({
                    type: DISPENSE_DEL_CRUD.READ,
                    dispense: res.data
                })
            })
    }
}

export function doGetWholeProcedureDel() {
    return (dispatch) => {
        axios.get('/api/procedureController/doGetWholeProcedure')
            .then((res) => {
                dispatch({
                    type: PROCEDURE_DEL_CRUD.READ,
                    procedures: res.data
                })
            })
    }
}