import { Box } from '@material-ui/core'
import styled from 'styled-components'

import Facebook from 'src/assets/images/layout/footer/facebook.png'

const Footer = () => {
    return (
        <StyledContainer component='footer'>
            {/* <FirstPart bgcolor='#0e60ad'>
                <Box maxWidth='1440px' width='80%' margin='auto' padding='15px 0' display='flex' justifyContent='flex-start' alignItems='center'>
                    <Box display='flex' flexWrap='wrap' justifyContent='center'>
                        <LinkItem component='a' color='white'>Home</LinkItem>
                        <LinkItem component='a'>About Us</LinkItem>
                        <LinkItem component='a'>Hormone Replacement Therapy</LinkItem>
                        <LinkItem component='a'>Women</LinkItem>
                        <LinkItem component='a'>Men</LinkItem>
                        <LinkItem component='a'>Certified Providers</LinkItem>
                    </Box>
                    <Box display='flex'>
                        <Box borderRadius='160px' border='solid #f99514 2px' padding='5px' bgcolor='#f99514'>
                            <FindButton>FIND A PROVIDER</FindButton>
                        </Box>
                        <Box borderRadius='160px' border='solid #2b9d72 2px' padding='5px' bgcolor='#2b9d72'>
                            <BecomeButton>BECOME A PROVIDER</BecomeButton>
                        </Box>
                    </Box>
                </Box>
            </FirstPart> */}
            <LastPart bgcolor='#002751' color='#0c71c3' padding='15px 0 5px'>
                <Box maxWidth='1440px' width='80%' margin='auto' display='flex' justifyContent='space-between' alignItems='center'>
                    <Box fontSize='12px'>
                        <Box component='strong'>BioSana ID</Box>
                        | 418 N. Loop 1604 West, San Antonio, TX 78232{'\u00a0'}
                        <br />
                        Powered by{'\u00a0'}
                        <Box component='a' href='http://www.ypcmedia.com/' target='_blank'>
                            YPC Media
                        </Box>
                    </Box>
                    <Box fontSize='12px'>
                        Version 11.0.1
                    </Box>
                    {/* <Box>
                        <Box component='img' src={Facebook} width='30px' />
                    </Box> */}
                </Box>
            </LastPart>
        </StyledContainer>
    )
}

const FirstPart = styled(Box)`
    >div {
        flex-direction: column;
        @media (min-width:1050px) {
            flex-direction: row;
        }
        >div:first-of-type >* {
            margin-right: 20px;
        }
        >div:last-of-type {
            margin-top: 16px;
            margin-left: 0;
            flex-direction: column;
            @media (min-width:700px) {
                flex-direction: row;
                >*+* {
                    margin-left: 20px;
                }    
            }
            @media (min-width:1050px) {
                margin-top: 0;
                margin-left: 16px;
                >*+* {
                    margin-left: 20px;
                }
            }
        }
    }
`

const LastPart = styled(Box)`
    a {
        text-decoration: none;
        color: #0c71c3;
        white-space: nowrap;
    }
`

const FindButton = styled(Box)`
    color: white;
    padding: 0px 5px;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
`

const BecomeButton = styled(Box)`
    color: white;
    padding: 0px 5px;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
`

const LinkItem = styled(Box)`
    color: #6bb0d6;
    transition: .3s;
    text-decoration: none;
    opacity: 1;
    font-size: 12px;
    white-space: nowrap;
    &:hover {
        cursor: pointer;
        opacity: .7;
        color: #6bb0d6;
    }
`

const StyledContainer = styled(Box)`
`

export default Footer