import { configureStore } from '@reduxjs/toolkit'
import AuthReducer from '../redux/reducers/AuthReducer'
import InvReducer from '../redux/reducers/InvReducer'
import PelletReducer from '../redux/reducers/PelletReducer'
import PatientReducer from '../redux/reducers/PatientReducer'
import ReportReducer from '../redux/reducers/ReportReducer'
import InvDeletedReducer from '../redux/reducers/InvDeletedReducer'
import PelletDeletedReducer from '../redux/reducers/PelletDeletedReducer'
import PatientDeletedReducer from '../redux/reducers/PatientDeletedReducer'

export default configureStore({
    reducer: {
        AuthReducer: AuthReducer,
        InvReducer: InvReducer,
        PelletReducer: PelletReducer,
        PatientReducer: PatientReducer,
        ReportReducer : ReportReducer,
        InvDeletedReducer: InvDeletedReducer,
        PelletDeletedReducer: PelletDeletedReducer,
        PatientDeletedReducer:PatientDeletedReducer
    }
})