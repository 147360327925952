import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Modal, Fade, Backdrop } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import _ from 'lodash'

import { doUpdateUser } from 'src/redux/actions/AuthAction'

const EditUserModal = ({ open, setOpen, user }) => {
    const [updatedUser, setUpdatedUser] = useState({
        'user_name': '',
        'user_first_name': '',
        'user_last_name': '',
        'password': '',
        'user_type_id': 0,
        'user_type': 'Administrator',
        'permission': 0,
        'biosana_id': '',
        'practice_name': '',
        'practioner_name': '',
        'dea_no': '',
        'old_password':'',
        'old_user_name':''
    })
   
    const dispatch = useDispatch()
    const userTypes = useSelector(state => state.AuthReducer.userTypes)

    useEffect(() => {
        if (user) {
            let prevUser = { ...user }
            prevUser.user_type_id = userTypes.findIndex(each => each.user_type_name === prevUser.user_type)
            prevUser.permission = user.permission ? 1 : 0
            prevUser.old_user_name=user.user_name;
            setUpdatedUser(prevUser)
        }
    }, [user, userTypes])

    const handleUpdateUser = () => {
        //new
        if(updatedUser.old_password == updatedUser.password)
        {
            updatedUser.password='';
        }
        let userTmp = updatedUser
        userTmp.permission = Boolean(userTmp.permission)
        dispatch(doUpdateUser(userTmp))
    }

    return (
        <>
            {open &&
                <Modal open={open} onClose={() => setOpen(false)}
                    BackdropComponent={Overlay}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <StyledContainer bgcolor='white' fontFamily='Poppins' color='white'>
                            <ModalHeader component='header'>
                                <Box component='h4' width='100%' lineHeight='110%' margin='0px' fontWeight='600' fontSize='20px' color='rgb(4,17,29)'>
                                    Update a user
                                </Box>
                            </ModalHeader>
                            <ModalBody component='section'>
                                <Box>
                                    <VerifyHeader>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>Email:</Box>
                                            <Box component='input' fontSize='14px' width='300px'
                                                value={updatedUser.user_name} onChange={(e) => {
                                                    let tmp = { ...updatedUser }
                                                    tmp.user_name = e.target.value
                                                    setUpdatedUser(tmp)
                                                }}
                                            />
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>First Name:</Box>
                                            <Box component='input' fontSize='14px' width='300px'
                                                value={updatedUser.user_first_name} onChange={(e) => {
                                                    let tmp = { ...updatedUser }
                                                    tmp.user_first_name = e.target.value
                                                    setUpdatedUser(tmp)
                                                }}
                                            />
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>Last Name:</Box>
                                            <Box component='input' fontSize='14px' width='300px'
                                                value={updatedUser.user_last_name} onChange={(e) => {
                                                    let tmp = { ...updatedUser }
                                                    tmp.user_last_name = e.target.value
                                                    setUpdatedUser(tmp)
                                                }}
                                            />
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>User Type:</Box>
                                            <ComboBox width='300px'>
                                                <Box component='select' width='100%'
                                                    value={updatedUser.user_type_id} onChange={(e) => {
                                                        let tmp = { ...updatedUser }
                                                        tmp.user_type_id = e.target.value
                                                        tmp.user_type = userTypes[parseInt(e.target.value)].user_type_name
                                                        setUpdatedUser(tmp)
                                                    }}
                                                >
                                                    {_.map(userTypes, (each, i) =>
                                                        <option key={i} value={i}>{each.user_type_name}</option>
                                                    )}
                                                </Box>
                                            </ComboBox>
                                        </Box>

                                        {/*---------------------------------------------------------Begin  AddNewFileds Update sec--------------------------------------------------------------------------------------------------------- */}

                                        {user.userType == "Practice" ?

                                            (

                                                <Box>


                                                    <Box display='flex' alignItems='center' mt='15px'>
                                                        <Box width='230px'>Biosana ID#:</Box>
                                                        <Box component='input' fontSize='14px' width='300px'
                                                            value={updatedUser.biosana_id} onChange={(e) => {
                                                                let tmp = { ...updatedUser }
                                                                tmp.biosana_id = e.target.value
                                                                setUpdatedUser(tmp)
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box display='flex' alignItems='center' mt='15px'>
                                                        <Box width='230px'> Practice Name: </Box>
                                                        <Box component='input' fontSize='14px' width='300px'
                                                            value={updatedUser.practice_name} onChange={(e) => {
                                                                let tmp = { ...updatedUser }
                                                                tmp.practice_name = e.target.value
                                                                setUpdatedUser(tmp)
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box display='flex' alignItems='center' mt='15px'>
                                                        <Box width='230px'>Practioner Name:</Box>
                                                        <Box component='input' fontSize='14px' width='300px'
                                                            value={updatedUser.practioner_name} onChange={(e) => {
                                                                let tmp = { ...updatedUser }
                                                                tmp.practioner_name = e.target.value
                                                                setUpdatedUser(tmp)
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box display='flex' alignItems='center' mt='15px'>
                                                        <Box width='230px'>DEA#:</Box>
                                                        <Box component='input' fontSize='14px' width='300px'
                                                            value={updatedUser.dea_no} onChange={(e) => {
                                                                let tmp = { ...updatedUser }
                                                                tmp.dea_no = e.target.value
                                                                setUpdatedUser(tmp)
                                                            }}
                                                        />
                                                    </Box>



                                                </Box>
                                            )

                                            : null
                                        }

                                        {/*---------------------------------------------------------End  AddNewFileds Update sec--------------------------------------------------------------------------------------------------------- */}


                                        <Box display='flex' alignItems='center' mt='15px' style={{ display: 'none' }}>
                                            <Box width='230px'>Old Password:</Box>
                                            <Box component='input' fontSize='14px' width='300px'
                                                placeholder='Create a old password'  value={updatedUser.old_password}
                                                onChange={(e) => {
                                                    let tmp = { ...updatedUser }
                                                    tmp.old_password = e.target.value
                                                    setUpdatedUser(tmp)
                                                }}
                                            />
                                        </Box>

                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>New Password:</Box>
                                            <Box component='input' fontSize='14px' width='300px'
                                                placeholder='Create a new password'
                                                onChange={(e) => {
                                                    let tmp = { ...updatedUser }
                                                    tmp.password = e.target.value
                                                    setUpdatedUser(tmp)
                                                }}
                                            />
                                        </Box>
                                        <Box display='flex' alignItems='center' mt='15px'>
                                            <Box width='230px'>Permission:</Box>
                                            <ComboBox width='300px'>
                                                <Box component='select' width='100%'
                                                    value={updatedUser.permission} onChange={(e) => {
                                                        let tmp = { ...updatedUser }
                                                        tmp.permission = parseInt(e.target.value)
                                                        setUpdatedUser(tmp)
                                                    }}
                                                >
                                                    <option value='0'>Blocked</option>
                                                    <option value='1'>Allowed</option>
                                                </Box>
                                            </ComboBox>
                                        </Box>
                                    </VerifyHeader>
                                </Box>
                            </ModalBody>
                            <ModalFoot>
                                <Action mt='30px'>
                                    <MyButton variant='contained' color='primary' disabled={
                                        !(updatedUser.user_name !== '' &&
                                            updatedUser.user_first_name !== '' &&
                                            updatedUser.user_last_name !== '')
                                    }
                                        onClick={() => {
                                            handleUpdateUser()
                                            setOpen(false)
                                        }}
                                    >
                                        Save</MyButton>
                                    <MyButton variant='contained' color='secondary' onClick={() => setOpen(false)}>Cancel</MyButton>
                                </Action>
                            </ModalFoot>
                            <Box display='flex' position='absolute' right='24px' top='24px'>
                                <CloseButton component='button' onClick={() => setOpen(false)}>
                                    <CloseOutlined style={{ fontSize: 24 }} />
                                </CloseButton>
                            </Box>
                        </StyledContainer>
                    </Fade>
                </Modal >
            }
        </>
    )
}

const ComboBox = styled(Box)`
    border: 1px solid lightgrey;
    border-radius: 5px;
    &:focus {
        border-color: red;
    }
`

const VerifyHeader = styled(Box)`
    >div {
        @media (max-width: 1040px) {
            align-items: initial;
            flex-direction: column;
            >input {
                width: 100%;
            }
        }
    }
`

const Action = styled(Box)`
    >button+button {
        margin-left: 30px;
    }
`

const MyButton = styled(Button)`
    text-transform: none !important;
`

const Overlay = styled(Backdrop)`
    background-color: rgba(0, 0, 0, 0.8) !important;
`

const CloseButton = styled(Box)`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 100%;
    font-family: inherit;
    border: 0px;
    padding: 0px;
    background: inherit;
    >svg { color: rgb(138, 147, 155); }
    &:hover {
        >svg { color: rgb(112, 122, 131); }
    }
`

const ModalHeader = styled(Box)`
    padding: 24px;
    border-bottom: 1px solid rgb(229, 232, 235);
    >h4 { word-break: break-word; }
`

const ModalBody = styled(Box)`
    padding: 24px;
    height: 100%;
    overflow-y: auto;
    font-weight: 400;
    color: rgb(53, 56, 64);
`

const ModalFoot = styled(Box)`
    padding: 24px;
    text-align: right;
`

const StyledContainer = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: transparent;
    max-height: calc(100% - 50px);
    overflow-y: auto;
    width: 500px;
    @media (max-width: 1000px) {
        width: calc(100% - 32px);
    }
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-corner {
        background: inherit;
    }
    &::-webkit-scrollbar-thumb {
        width: 30px;
        border-radius: 15px;
        background: rgb(33,37,41);
    }
    input {
        outline: none;
        border: 1.5px solid lightgrey;
        border-radius: 5px;
        transition: .3s;
        padding: 5px;
        height: fit-content;
        align-self: center;
        &:focus {
            border-color: #c850c0;
        }
    }
    select {
        border: none;
        outline: none;
        padding: 4px;
        border-radius: 5px;
    }
`

export default EditUserModal