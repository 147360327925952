import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Box } from '@material-ui/core'
import styled from 'styled-components'

import Spinner from 'src/components/Spinners'
import { doResetPassword } from 'src/redux/actions/AuthAction'

const Reset = ({ match }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(doResetPassword(match.params.id, match.params.password, history))
            clearTimeout(timer)
        }, 3000)
    })
    return (
        <StyledContainer textAlign='center' >
            <Box component='h3' mt={5}>{match.params.id}</Box>
            <Box><Spinner /></Box>
            <Box>Reset success</Box>
        </StyledContainer>
    )
}

const StyledContainer = styled(Box)`
`

export default Reset