import { Box } from '@material-ui/core'
import styled from 'styled-components'
import _ from 'lodash'

import Spinner from 'src/components/Spinners'

const InputDrops = ({ contentRef, data, handleCopyNames,practice }) => {
    if(practice !='')
    {
        data=data.filter(u => u.practice_id == practice)
       //console.log(data);
    }
    return (
        <StyledContainer position='absolute' width='100%' top='100%' left='0' height='150px' overflow='auto' bgcolor='white' boxShadow='1px 1px 1px 1px grey'
            ref={contentRef}
        >
            {(data && data.length === 0) ?
                <FilterSpinner>
                    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                        Loading
                        <Spinner />
                    </Box>
                </FilterSpinner>
                :
                _.map(data, (each, i) =>
                    <FilterItem key={i} display='flex' justifyContent='space-between' alignItems='center' padding='10px'
                        onClick={(e) => handleCopyNames(e, each)}
                    >
                        <Box>{each.patient_first_name + ' ' + each.patient_last_name}</Box>
                        <Box color='rgb(150, 150, 150)' fontSize='12px'>{each.dispense_date.slice(0, 10)}</Box>
                    </FilterItem>
                )
            }
        </StyledContainer>
    )
}

const FilterSpinner = styled(Box)`
    >div:last-of-type {
        position: absolute;
        top: calc(50% - 8px);
        left: calc(50% - 8px);
        transform: translate(-50%, -50%);
    }
`

const FilterItem = styled(Box)`
    cursor: pointer;
    transition: .3s;
    &:hover {
        background-color: aliceblue;
    }
`

const StyledContainer = styled(Box)`
`

export default InputDrops