import React, { useState, useRef } from 'react'
import { Box } from '@material-ui/core'
import ReactToPdf from 'react-to-pdf'
import ReactToPrint from 'react-to-print'
import styled from 'styled-components'

import { useOutsideDetector } from 'src/hooks/useOutsideDetector'
import DrugIcon from 'src/assets/images/drug.jpg'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import jwtDecode from 'jwt-decode'
const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [12,20]

}
let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let time = newDate.getHours() + ':' + newDate.getMinutes() + ':' + newDate.getSeconds();
let filenamenew='Report_'+date+'-'+month+'-'+year+' '+time;
const user = localStorage.getItem('jwtToken') && jwtDecode(localStorage.getItem('jwtToken'))

const Exporter = ({ tBodyRef, setPushed,setCStep,totalColumn,oldStep,currentTable,emptyBox ,title,ptitle,currentuser,practiceHere,currentpractice}) => {
    if(currentuser=='Administrator')
    {
        let  prac_sp = currentpractice.split("/");
        filenamenew=title+'_'+currentpractice+'_'+date+'-'+month+'-'+year+' '+time;
        if(currentpractice!='All')
        {
            filenamenew=title+'_'+prac_sp[2]+'_'+date+'-'+month+'-'+year+' '+time;
        }
    }else{
        filenamenew=title+'_'+user?.biosana_id+'_'+date+'-'+month+'-'+year+' '+time;
    }
    
    // const pdfExportComponent = tBodyRef;
    const [open, setOpen] = useState(false)
    const contentRef = useRef()

    useOutsideDetector([contentRef], setOpen)

    return (
        <StyledContainer display='flex' alignItems='center'
            onMouseEnter={() => setOpen(true)}
            active={open ? 1 : 0}
            ref={contentRef}
        >
            <Stripe zIndex='-1' width='5px' right='50%' />
            <Stripe zIndex='-1' width='20px' right='30%' />
            <React.Fragment>
                {/* old */}
                {/* <ReactToPdf targetRef={tBodyRef} filename='Report.pdf' options={options} x={0} y={0} size="A4" wrap="true" scale={1}>
                    {({ toPdf }) => (
                        <ExportButton color='white' fontSize='13px' onClick={() => {
                            setPushed(false)
                            toPdf()
                        }}>Export</ExportButton>
                    )}

                </ReactToPdf> */}
                <ReactToPdf targetRef={tBodyRef} filename={filenamenew} options={options} x={0} y={0} size="A4" wrap="true" scale={1}>
                    {({ toPdf }) => (
                        <ExportButton color='white' fontSize='13px' onClick={() => {
                            //setPushed(false)
                            //console.log(setCStep(totalColumn));
                            // toPdf()
                            const addFooters = doc => {
                                const pageCount = doc.internal.getNumberOfPages()
                              
                                doc.setFont('helvetica', 'italic')
                                doc.setFontSize(8)
                                for (var i = 1; i <= pageCount; i++) {
                                  doc.setPage(i)
                                //   doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
                                //     align: 'center'
                                //   })
                                // doc.text(filenamenew, doc.internal.pageSize.width / 2, 570, {
                                //     align: 'center'
                                //   })
                                doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 570, {
                                    align: 'center'
                                  })

                                  doc.text(date+'-'+month+'-'+year+' '+time, doc.internal.pageSize.width/9 , 570, {
                                        align: 'right'
                                      })
                                }
                              }
                            setPushed(false)
                            setCStep(totalColumn)
                            const input = document.getElementById(currentTable);
                            const input2 = document.getElementById(emptyBox);
                            const input3 = document.getElementById(practiceHere);
                            const pdf = new jsPDF('landscape', 'pt', 'a4');
                            var today = new Date();
                            var newdat = "Date Printed : "+ today;
                            //console.log(practiceHere);
                            //pdf.text(107,68,newdat);
                            // pdf.autoTable({ html: input });
                            // let finalY = pdf.autoTable.previous.finalY;// The y position on the page

                            // pdf.text(20, finalY, "Hello!");
                            // //addFooters(pdf);
                            // pdf.save(filenamenew);
                            // setCStep(oldStep)
                            //setCStep(oldStep);
                            //pdf.text(title, 40, 80);
                            if(input3 !=null)
                            {
                              
                        //         pdf.html(input3, {
                        //             x: 40,
                        //             y : 10,
                        //             margin : [20, 10, 20, 10],
                        //             html2canvas: { scale: 0.7 },
                        //             autoPaging : "text"
                        //   }).then(() => {
                                   
                                    pdf.html(input2, { html2canvas: { scale: 0.8 } }).then(() => {
                                        pdf.autoTable({ html: '#'+currentTable,margin: {right:30,left:30,top:93},didDrawPage : function(data) {
                                            // pdf.setFontSize(12);
                                            // pdf.text(title, 40, 20);
                                            pdf.setFontSize(15);
                                pdf.text(title, 40, 20);
                                pdf.setFontSize(12);
                                pdf.setTextColor(102, 102, 102);
                                pdf.setFont('helvetica', 'italic')
                                pdf.text(input3.innerText, 40, 40);
                                            if(currentuser=='Administrator')
                                            {
                                                pdf.setFontSize(12);
                                                pdf.text(ptitle, 800, 80, {
                                                    align: 'right',
                                                });
        
                                            }
                                            } })
                                       addFooters(pdf);
                                        pdf.save(filenamenew);
                                        setCStep(oldStep)
                                    });
                                    //});

                            }else{
                                pdf.html(input2, { html2canvas: { scale: 0.8 } }).then(() => {
                                    pdf.autoTable({ html: '#'+currentTable,didDrawPage : function(data) {
                                        pdf.setFontSize(12);
                                        pdf.text(title, 40, 20);
                                        if(currentuser=='Administrator')
                                        {
                                            pdf.setFontSize(12);
                                            pdf.text(ptitle, 800, 20, {
                                                align: 'right',
                                            });
    
                                        }
                                        } })
                                   addFooters(pdf);
                                    pdf.save(filenamenew);
                                    setCStep(oldStep)
                                });
                            }
                        }}>Export</ExportButton>
                    )}

                </ReactToPdf>
            </React.Fragment>
            <Box>
                <Box component='img' {...{ src: DrugIcon }} {...{ alt: '' }} width='30px' borderRadius='50%' />
            </Box>
            <PrintButton color='white' fontSize='13px' onClick={() => {
                console.log("sdffsdf");
                const addFooters = doc => {
                    const pageCount = doc.internal.getNumberOfPages()
                  console.log(pageCount);
                    doc.setFont('helvetica', 'italic')
                    doc.setFontSize(8)
                    for (var i = 1; i <= pageCount; i++) {
                      doc.setPage(i)
                  
                    doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 570, {
                        align: 'center'
                      })

                      doc.text(date+'-'+month+'-'+year+' '+time, doc.internal.pageSize.width/9 , 570, {
                            align: 'right'
                          })

                       
                    }
                  }
                setPushed(false)
                setCStep(totalColumn)
                const input = document.getElementById(currentTable);
                const input2 = document.getElementById(emptyBox);
                const input3 = document.getElementById(practiceHere);
                const pdf = new jsPDF('landscape', 'pt', 'a4');
                var today = new Date();
                var newdat = "Date Printed : "+ today;
              
                if(input3 !=null)
                {
                  
                    
                    // pdf.html(input3, {
                    //     x: 40,
                    //     y : 10,
                    //     margin : [20, 10, 20, 10],
                    //     html2canvas: { scale: 0.7 },
                    //     autoPaging : "text"
                    // }).then(() => {
                        var startingPage = pdf.internal.getCurrentPageInfo().pageNumber;
                        pdf.html(input2, { html2canvas: { scale: 1 } }).then(() => {
                            pdf.autoTable({ html: '#'+currentTable,margin: {right:30,left:30,top:93},didDrawPage : function(data) {
                                
                                pdf.setFontSize(15);
                                pdf.text(title, 40, 20);
                                pdf.setFontSize(12);
                                pdf.setTextColor(102, 102, 102);
                                pdf.setFont('helvetica', 'italic')
                                pdf.text(input3.innerText, 40, 40);
                                if(currentuser=='Administrator')
                                {
                                    pdf.setFontSize(12);
                                    pdf.text(ptitle, 800, 80, {
                                        align: 'right',
                                    });

                                }
                                } })
                           addFooters(pdf);
                           pdf.autoPrint();
                           //This is a key for printing
                           pdf.output('dataurlnewwindow');
                            setCStep(oldStep)
                        //});
                        });

                }else{
                    pdf.html(input2, { html2canvas: { scale: 0.8 } }).then(() => {
                        pdf.autoTable({ html: '#'+currentTable,didDrawPage : function(data) {
                            pdf.setFontSize(12);
                            pdf.text(title, 40, 20);
                            if(currentuser=='Administrator')
                            {
                                pdf.setFontSize(12);
                                pdf.text(ptitle, 800, 20, {
                                    align: 'right',
                                });

                            }
                            } })
                       addFooters(pdf);
                       pdf.autoPrint();
                    //This is a key for printing
                    pdf.output('dataurlnewwindow');
                        setCStep(oldStep)
                    });
                }

            }}>Print</PrintButton>
            {/* old */}
            {/* <ReactToPrint
                trigger={() =>
                    <PrintButton color='white' fontSize='13px'>Print</PrintButton>
                }
                content={() => tBodyRef.current}
            /> */}

        </StyledContainer>
    )
}

const Stripe = styled(Box)`
    position: absolute;
    background: rgba(158, 206, 255, .6);
    height: 100px;
    user-select: none;
    pointer-events: none;
    transform: rotate(30deg);
`

const ExportButton = styled(Box)`
    cursor: pointer;
`

const PrintButton = styled(Box)`
    cursor: pointer;
`

const StyledContainer = styled(Box)`
    position: fixed;
    top: 3.5%;
    right: ${({ active }) => active ? 50 : 20}px;
    transform: translate(0px, -50%);
    transition: all .2s ease-in 0s;
    z-index: 2;
    height: 50px;
    border-radius: 30px;
    background: #0e60ad;
    padding: 20px;
    box-shadow: 1px 1px 3px 1px rgba(1, 27, 86, .6);
    cursor: pointer;
    overflow: hidden;
    >div+div {
        margin-left: 10px;
    }
    >div:nth-of-type(3) {
        margin-left: 0;
    }
`

export default Exporter