export const SET_WAITING = 'SET_WAITING'
export const USER_TYPES_GET = 'USER_TYPES_GET'
export const LOGOUT = 'LOGOUT'
export const PATIENT_SEACH = 'PATIENT_SEACH'
export const PATIENT_DEL_SEACH = 'PATIENT_DEL_SEACH'
export const PROCEDURE_SEARCH = 'PROCEDURE_SEARCH'
export const PROCEDURE_DEL_SEARCH = 'PROCEDURE_DEL_SEARCH'
export const AUTH_RESULT = {
    SUCCESS : 'SUCCESS',
    CRASH   : 'CRASH',
}

export const AUTH_CRUD = {
    CREATE  : 'U_CREATE',
    READ    : 'U_READ',
    UPDATE  : 'U_UPDATE',
    DELETE  : 'U_DELETE',
    UPDATE_TIME: 'UPDATE_TIME',
}

export const AUTH_EXPIRE = {
    ADDEXPIRATION  : 'ADD_EXPIRATION',
    SETFULLTIME     : 'SET_FULLTIME',
}

export const PELLET_CRUD = {
    CREATE  : 'T_CREATE',
    READ    : 'T_READ',
    UPDATE  : 'T_UPDATE',
    DELETE  : 'T_DELETE',
}

export const INV_CRUD = {
    CREATE  : 'I_CREATE',
    READ    : 'I_READ',
    UPDATE  : 'I_UPDATE',
    DELETE  : 'I_DELETE',
}

export const DISPENSE_CRUD = {
    CREATE  : 'D_CREATE',
    READ    : 'D_READ',
    UPDATE  : 'D_UPDATE',
    DELETE  : 'D_DELETE',
}

export const PROCEDURE_CRUD = {
    CREATE  : 'P_CREATE',
    READ    : 'P_READ',
    UPDATE  : 'P_UPDATE',
    DELETE  : 'P_DELETE',
}

export const LOT_CRUD = {
    READ: 'READ',
}
export const REPORT_CRUD = {
    // CREATE  : 'I_CREATE',
    READ    : 'R_READ',
    UPDATE  : 'R_UPDATE',
    // DELETE  : 'I_DELETE',
}
export const ORIGINAL_CRUD = {
    READ: 'O_READ',
}

export const INV_DEL_CRUD = {
    CREATE  : 'IVD_CREATE',
    READ    : 'IVD_READ',
    UPDATE  : 'IVD_UPDATE',
    DELETE  : 'IVD_DELETE',
}

export const PELLET_DEL_CRUD = {
    CREATE  : 'PTD_CREATE',
    READ    : 'PTD_READ',
    UPDATE  : 'PTD_UPDATE',
    DELETE  : 'PTD_DELETE',
}

export const DISPENSE_DEL_CRUD = {
    CREATE  : 'DE_CREATE',
    READ    : 'DE_READ',
    UPDATE  : 'DE_UPDATE',
    DELETE  : 'DE_DELETE',
}

export const PROCEDURE_DEL_CRUD = {
    CREATE  : 'PD_CREATE',
    READ    : 'PD_READ',
    UPDATE  : 'PD_UPDATE',
    DELETE  : 'PD_DELETE',
}

export const LOT_DEL_CRUD = {
    READ: 'LD_READ',
}

export const ORIGINAL_DEL_CRUD = {
    READ: 'DO_READ',
}

export const TOAST = 'TOAST'