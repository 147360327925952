import { Box } from '@material-ui/core'
import styled from 'styled-components'
import _ from 'lodash'


const Pagination = ({ page, setPage, totalCount, step,keyword }) => {
    const pages = Array(Math.ceil(totalCount > 0 && totalCount / step)).fill('')
    // console.log(totalCount,step,pages);
    if(keyword !='')
    {
        if(page > pages.length)
        {
            setPage(1);
            //console.log(page,pages);
        }

    }
    const handlePrevNext = (count) => {
        setPage(prev => prev + count)
    }

    return (
        <StyledContainer mt={2} display='flex' justifyContent='center'>
      
            <select maxMenuHeight={3}   menuPlacement="auto"  style={{ maxHeight: 100, display: "flex" ,
           
            height: 39,
            overflowY: "scroll !important",
            width: 45,
            borderRadius: "27%",
            textAlign: "center"
        
        }} onChange={(e) => {setPage(Number(e.target.value)+1);console.log(Number(e.target.value)+1)}}>
            {_.map(pages, (each, i) =>
                    <option value={i}  selected={page === i + 1 ? 1 : 0} >
                    {i + 1}
                    </option>
            )}
            </select>
            
            {/* <PrevButton component='button' disabled={page === 1} onClick={() => handlePrevNext(-1)}>{'<'}</PrevButton>

            {_.map(pages, (each, i) =>
                <PageItem key={i} active={page === i + 1 ? 1 : 0} component='button' onClick={() => setPage(i + 1)}>
                    {i + 1}
                </PageItem>
            )}

            <NextButton component='button' disabled={page === pages.length} onClick={() => handlePrevNext(1)}>{'>'}</NextButton> */}
        </StyledContainer>
    )
}

const PageItem = styled(Box)`
    border: none; outline: none;
    background: ${({ active }) => active ? '#eee' : 'white'};
    border-radius: 50%;
    width: 40px; height: 40px;
`

const PrevButton = styled(Box)`
    border: none; outline: none;
    background: white;
    border-radius: 50%;
    width: 40px; height: 40px;
    &:disabled {
        cursor: not-allowed;
    }
`

const NextButton = styled(Box)`
    border: none; outline: none;
    background: white;
    border-radius: 50%;
    width: 40px; height: 40px;
    &:disabled {
        cursor: not-allowed;
    }
`

const StyledContainer = styled(Box)`
    >button:not(:first-of-type, :last-of-type) {
        transition: .3s;
        &:hover {
            background: #ccc;
        }
    }
`
export default Pagination