import { AUTH_CRUD, AUTH_EXPIRE, AUTH_RESULT, LOGOUT, USER_TYPES_GET, TOAST } from './types'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import * as PAGES from '../../constants/pages'

export function doVerifyEmail(where, history) {
    return (dispatch) => {
        axios.post('/api/authController/doVerifyEmail', { where: where })
            .then((res) => {
                if (res.data.success)
                    history.push(`/${PAGES.LOGIN}`)
            })
    }
}

export function doUpdateUser(formData) {
    return (dispatch) => {
        axios.post('/api/authController/doUpdateUser', formData)
            .then((res) => {
                if (res.data.success)
                    dispatch({
                        type: AUTH_CRUD.UPDATE,
                        result: res.data.success,
                        updatedData: formData,
                    })
                else alert('error occur')
            })
    }
}

export function doGetWholeUsers() {
    return (dispatch) => {
        axios.get('/api/authController/doGetWholeUsers')
            .then((res) => {
                if (res.data.success)
                    dispatch({
                        type: AUTH_CRUD.READ,
                        users: res.data.users
                    })
                else alert('Error occured while loading roles')
            })
    }
}

export function doGetWholeUserTypes() {
    return (dispatch) => {
        axios.get('/api/authController/doGetWholeUserTypes')
            .then((res) => {
                if (res.data.success)
                    dispatch({
                        type: USER_TYPES_GET,
                        userTypes: res.data.userTypes
                    })
                else alert('Error occured while loading roles')
            })
    }
}

export function doRegisterUser(formData) {
    return (dispatch) => {
        axios.post('/api/authController/doRegisterUser', formData)
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: AUTH_CRUD.CREATE,
                        newUser: res.data.newUser,
                        flag: true,
                        toastContent: res.data.error
                    })
                    alert('Please check your email inbox to verification.');
                }
                else
                    dispatch({ type: TOAST, flag: true, toastContent: res.data.error })
                    alert(res.data.error)
            })
    }
}

export function doForgotPassword(formData) {
    return (dispatch) => {
        axios.post('/api/authController/doForgotPassword', formData)
            .then((res) => {
                dispatch({ type: TOAST, flag: true, toastContent: res.data.error })
            })
    }
}

export function doResetPassword(where, newPassword, history) {
    return (dispatch) => {
        axios.post('/api/authController/doResetPassword', { where: where, newPassword: newPassword })
            .then((res) => {
                if (res.data.success)
                    history.push(`/${PAGES.LOGIN}`)
            })
    }
}

export function doDeleteUser(id) {
    return (dispatch) => {
        axios.post('/api/authController/doDeleteUser', { id: id })
            .then((res) => {
                if (res.data.success)
                {
                    dispatch({
                        type: AUTH_CRUD.DELETE,
                        id: id,
                    })
                    window.location.reload();

                }
                else alert('error occur')
            })
    }
}

export function doLoginUser(formData, history) {
    return (dispatch) => {
        axios.post('/api/authController/doLoginUser', formData)
            .then((res) => {
                if (res.data.success) {
                    localStorage.setItem('jwtToken', res.data.token);
                    localStorage.setItem('uid', JSON.stringify(res.data.uid));
                    dispatch({
                        type: AUTH_RESULT.SUCCESS,
                        cur_user: jwtDecode(res.data.token),
                        cur_user_id: res.data.uid
                    })
                    history.push(`/${PAGES.DASHBOARD}`)
                }
                else {
                    dispatch({ type: TOAST, flag: true, toastContent: res.data.error })
                }
            })
    }
}

export function doLogoutUser(history) {
    return (dispatch) => {
        localStorage.removeItem('jwtToken')
        history.push(`/${PAGES.DASHBOARD}`)
        dispatch({ type: LOGOUT })
    }
}

export function doAddExpiration(formdata) {
    return (dispatch) => {
        axios.post('/api/authController/doAddExpiration', formdata)
            .then((res) => {
                if (res.data.success)
                    dispatch({
                        type: AUTH_EXPIRE.ADDEXPIRATION,
                        updatedData: res.data.updatedData,
                    })
                else alert('error occur')
            })
    }
}

export function doSetFullTime(where) {
    return (dispatch) => {
        axios.post('/api/authController/doSetFullTime', { where: where })
            .then((res) => {
                if (res.data.success)
                    dispatch({
                        type: AUTH_EXPIRE.SETFULLTIME,
                        updatedData: res.data.updatedData,
                    })
                else alert('error occur')
            })
    }
}