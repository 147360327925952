import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { EditOutlined, DeleteOutlined } from '@material-ui/icons'

// import Badge from 'src/components/Badges'
// import VerifyIcon from 'src/assets/images/auth/security/2.png'
import { Alert } from '@material-ui/lab'

const UserRow = ({ id, eachInfo, setOpenMsg, setOpenUpdate, setIndex, setJIndex,setCurrentdUser }) => {
    return (
        <StyledContainer component='tr'>
            <td>{id + 1}</td>
            <td>{eachInfo.user_name}</td>
            <td>{eachInfo?.practice_name}</td>
            {/* <td>{eachInfo?.practioner_name}</td> */}
            <td>{eachInfo.user_type}</td>
            <td>{eachInfo.user_first_name}</td>
            <td>{eachInfo.user_last_name}</td>
            <td align='center'>
                <Permission>
                    {eachInfo.permission ?
                        <Alert severity='success'>Allowed</Alert>
                        // <img src={VerifyIcon} alt='' width='20px' height='20px' />
                        :
                        <Alert severity='error'>Blocked</Alert>
                        // <BlockIcon />
                    }
                </Permission>
            </td>
            {/* <td align='center'>
                <BlockButton onClick={() => {
                    let formData = {
                        where: eachInfo._id,
                        value: eachInfo.permission
                    }
                    handleBlock(formData)
                    // setIsBlock(!isBlock)
                }} />
            </td> */}
            <td align='center'>
                <EditButton onClick={() => {
                    setCurrentdUser(eachInfo)
                    setIndex(id)
                    setJIndex(eachInfo._id)
                    setOpenUpdate(true)
                }} />
            </td>
            {/* old */}
            {/* <td align='center'>
                <DeleteButton onClick={() => {
                    setJIndex(eachInfo._id)
                    setOpenMsg(true)
                }} />
            </td> */}
        </StyledContainer>
    )
}

const Permission = styled(Box)`
    >div {
        padding-top: 0px;
        padding-bottom: 0px;
    }
`

// const BlockIcon = styled(BlockOutlined)`
//     fill: red !important;
// `

// const BlockButton = styled(VerifiedUserOutlined)`
//     cursor: pointer;
//     opacity: .5;
//     &:hover {
//         transition: .3s;
//         opacity: 1;
//     }
// `

const EditButton = styled(EditOutlined)`
    cursor: pointer;
    opacity: .5;
    &:hover {
        transition: .3s;
        opacity: 1;
    }
`

const DeleteButton = styled(DeleteOutlined)`
    cursor: pointer;
    opacity: .5;
    &:hover {
        transition: .3s;
        opacity: 1;
    }
`

const StyledContainer = styled(Box)`
`

export default UserRow